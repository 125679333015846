import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import drawer from '../Assets/Images/drawer-icon.png';
import logo2 from '../Assets/Images/logo-top-black.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellConcierge, faWallet } from '@fortawesome/free-solid-svg-icons';
import { userBalance } from '../Redux/GetSlice';
import { useDispatch, useSelector } from 'react-redux'
import { produc } from '../Redux/Helper';
import fav from '../Assets/Images/fav1.png';
import { getNotify } from '../Redux/GetSlice';


export default function TopNav({onOpen,onClose,side}) {

  const dispatch = useDispatch();

  const uid = localStorage?.getItem("user_id");

  useEffect(()=>{
  dispatch(userBalance(uid));
  },[])

  useEffect(()=>{
    dispatch(getNotify(uid))
  },[])

const openSideNav = ()=>{
  if(!side){
   onOpen();
  }
  else{
    onClose();
  }
}

const {totalBalanceResponse} = useSelector((state)=>state.Get);
const {Notifies} = useSelector((state)=>state.Get);

const [notificationData,setNotificationData] = useState(parseInt(Notifies?.data?.unread_counts) > 0 ? parseInt(Notifies?.data?.unread_counts) : 0)


// const token = localStorage.getItem("token") || sessionStorage.getItem("token");


  return (
    <div style={{cursor:"pointer"}}  className="main_header">

      <div draggable="false" style={{display:"flex",height:"30px",alignItems:"center",justifyContent:"center",columnGap:"-5px"}}>
      <div style={{borderRadius:"50px",display:"flex",backgroundColor:"grey",height:"25px",width:"25px"}} onClick={openSideNav} className="right_fav">
        {totalBalanceResponse?.user?.photo == null || "" ?<img  src={fav} alt=""/>:<img style={{height:""}} src={produc(totalBalanceResponse?.user?.photo)} alt=""/>}
        
    </div>
    <img style={{height:"12px"}} src={drawer} alt="" />
      </div>

   
    <Link to='/'>
    <div className="top_logo">
        <img src={logo2} alt=""/>
    </div>
    </Link>
    


    <div style={{display:"flex"}} className="right_head_icons">
      <div style={{display:"flex",flexDirection:"row",justifyContent:"center",gap:-5}}>
      <h6 style={{position: "absolute",
    backgroundColor: "red",
    borderRadius: "50%",
    padding: "1px 4px 1px 4px",
    top: "19px",
    fontSize:10
}} >
       {notificationData}
       </h6>
      <Link   to='/notify'> 
    <i style={{marginRight:"15px"}} >
      <FontAwesomeIcon icon={faBell}/>
   
      </i>
     
      </Link>
      
      
      </div>
   
        <Link to='/wallet'><i >
          <FontAwesomeIcon icon={faWallet}/>
          </i></Link>
    
    </div>
    </div>
  )
}
