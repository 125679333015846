import React, { useEffect } from "react";
import TopNav from "./TopNav";
import Footer4 from "../Components/Footer4";
import offer from "../Assets/Images/offer1.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../Redux/GetSlice";
import moment from "moment";
import { produc } from "../Redux/Helper";

export default function Offer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffers());
  }, []);

  const { Offers } = useSelector((state) => state.Get);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          {/* <div style={{position:"sticky",top:"0"}}>
            <TopNav/>
           </div> */}

          <div style={{ position: "sticky", top: "0" }} className="reg-back">
            <div className="reg__back">
              <Link to="/more">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Offers</p>
          </div>

          <div className="mid_content">
            <div className="offer_main">
              <div className="offer_contents">
                <div className="list_items_type">
                  <div className="main_transi Withdraw offer">
                    {Offers?.length == 0 ? (
                      <h4
                        style={{
                          textAlign: "center",
                          paddingTop: 15,
                          color: "grey",
                        }}
                      >
                        No Offers yet
                      </h4>
                    ) : (
                      ""
                    )}

                    {Array.isArray(Offers) &&
                      Offers?.map((items) => {
                        return (
                          <div className="catego" style={{borderRadius:'10px'}}>
                             <div className="contnt_text_" style={{marginBottom:'10px'}}>
                                <h3>{items?.offer_name} </h3>
                              </div>
                            <div className="off_img">
                              {items?.banner == (null || undefined || "") ? (
                                <img src={offer} alt="" />
                              ) : (
                                <img
                                  style={{ height: 120, objectFit: "contain" }}
                                  src={produc(items?.banner)}
                                  alt=""
                                />
                              )}
                            </div>
                            <div>
                              <div className="contnt_text_">
                                <h4>Description</h4>
                                <p>{items?.offer_desc} </p>
                              </div>
                              <div className="contnt_text_">
                                <h4>Valid upto</h4>
                                <p>
                                  {moment(items?.valid_upto).format(
                                    "Do MMM YY"
                                  )}{" "}
                                </p>
                              </div>
                            </div>
                           
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer4 />
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
