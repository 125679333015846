// src/SwipeButton.js
import React, { useRef, useState, useEffect } from 'react';
import './SwipeButton.css';

const SwipeButton = ({ onSwipeEnd, refresh }) => {
  const buttonRef = useRef(null);
  const sliderRef = useRef(null);
  const [isSwiped, setIsSwiped] = useState(false);

  useEffect(() => {
    // Reset the button position when the refresh state changes
    buttonRef.current.style.transform = 'translateX(0px)';
    setIsSwiped(false);
  }, [refresh]);

  const handleSwipe = (e) => {
    const slider = sliderRef.current;
    const sliderWidth = slider.offsetWidth - 40; // Adjust for the width of the circle button
    const startX = e.touches ? e.touches[0].clientX : e.clientX;

    const onMove = (moveEvent) => {
      const currentX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const diffX = currentX - startX;
      if (diffX > 0 && diffX < sliderWidth) {
        buttonRef.current.style.transform = `translateX(${diffX}px)`;
      }
    };

    const onEnd = (endEvent) => {
      const endX = endEvent.changedTouches ? endEvent.changedTouches[0].clientX : endEvent.clientX;
      const finalDiffX = endX - startX;
      if (finalDiffX > sliderWidth / 2) {
        buttonRef.current.style.transform = `translateX(${sliderWidth}px)`;
        setIsSwiped(true);
        if (onSwipeEnd) {
          onSwipeEnd(true);
        }
      } else {
        buttonRef.current.style.transition = 'transform 0.3s ease';
        buttonRef.current.style.transform = `translateX(0px)`;
        setIsSwiped(false);
        if (onSwipeEnd) {
          onSwipeEnd(false);
        }
        setTimeout(() => {
          buttonRef.current.style.transition = '';
        }, 300);
      }

      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      document.removeEventListener('touchmove', onMove);
      document.removeEventListener('touchend', onEnd);
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', onEnd);
  };

  return (
    <div className="swipe-container">
      <div className="swipe-button" ref={sliderRef}>
        <span className="button-text">Swipe for bet</span>
        <div
          className="circle-button"
          onMouseDown={handleSwipe}
          onTouchStart={handleSwipe}
          ref={buttonRef}
        >
          <span className="circle-arrow">»</span>
        </div>
      </div>
    </div>
  );
};

export default SwipeButton;
