import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { BASE_URL } from "../config";

export const withDrawHistory = createAsyncThunk(
  "/withdraw/history",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}get-withdrawal-history`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);

export const transHistory = createAsyncThunk(
  "/transaction/history",
  async (id) => {
    let res = await axios.post(
      `${BASE_URL}get-wallet-transactions`,
      id
    );
    let resData = res?.data;
    return resData;
  }
);
export const withDraw = createAsyncThunk("/withdraw", async (details) => {
  let res = await axios.post(
    `${BASE_URL}withdraw-request`,
    details
  );
  let resData = res?.data;
  return resData;
});
export const readNotifications = createAsyncThunk("/read-notifications", async (details) => {
  let res = await axios.post(
    `${BASE_URL}read-notifications`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const addMoney = createAsyncThunk("/addmoney", async (details) => {
  let res = await axios.post(
    `${BASE_URL}add-money`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const portfolio = createAsyncThunk("/portfolio", async (details) => {
  let res = await axios.post(
    `${BASE_URL}get-portfolio`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const postBet = createAsyncThunk("/place-bet", async (details) => {
  let res = await axios.post(
    `${BASE_URL}place-bet`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const addBankDet = createAsyncThunk(
  "/add-bank-details",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}add-bank-details`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);

export const addKYCdet = createAsyncThunk(
  "/add-KYC-details",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}add-kyc-details`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);
export const reffCode = createAsyncThunk(
  "/generate-referral-code",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}generate-referral-code`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);

export const cancelBet = createAsyncThunk("/cancel-bet", async (details) => {
  let res = await axios.post(
    `${BASE_URL}cancel-bet`,
    details
  );
  let resData = res?.data;
  return resData;
});
export const cancelExitBet = createAsyncThunk("/exit-cancell", async (details) => {
  let res = await axios.post(
    `${BASE_URL}exit-bet/cancell`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const settings = createAsyncThunk("/save-info", async (details) => {
  let res = await axios.post(
    `${BASE_URL}save-settings`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const orderId = createAsyncThunk("/order-details", async (details) => {
  let res = await axios.post(
    `${BASE_URL}get-orderId`,
    details
  );
  let resData = res?.data;
  return resData;
});
export const recharge = createAsyncThunk("/wallet-recharge", async (details) => {
  let res = await axios.post(
    `${BASE_URL}wallet/recharge`,
    details
  );
  let resData = res?.data;
  return resData;
});
export const rechargeStatus = createAsyncThunk("/wallet-recharge-status", async (details) => {
  let res = await axios.post(
    `${BASE_URL}wallet/recharge-status`,
    details
  );
  let resData = res?.data;
  return resData;
});

export const getPortDetails = createAsyncThunk(   
  "/get-portfolio-details",
  async (details) => {
    let response = await axios.post(
      `${BASE_URL}get-portfolio-details`,details
    );
    let resData = response?.data;
    return resData;
  }
);



export const createOrder = createAsyncThunk(
  "/create-order",
  async (details) => {

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE', // Allow specified HTTP methods
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', // Allow specified headers
      },
    };

    let res = await axios.post(
      "https://safexxsolution.in/api/create-order",
      details,config
    );
    let resData = res?.data;
    return resData;
  }
);

export const pinMatch = createAsyncThunk(
  "/pin-match",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}pin-match`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);

export const getBetSlots = createAsyncThunk(
  "/get-bet-slots",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}bid-qty-available`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);

export const exitBet = createAsyncThunk(
  "/exit-bet",
  async (details) => {
    let res = await axios.post(
      `${BASE_URL}exit-bet`,
      details
    );
    let resData = res?.data;
    return resData;
  }
);


export const PostSlice = createSlice({
  name: "PostSlice",
  initialState: {
    withHistry: [{}],
    withDrawResponse:{},
    transHistories: [{}],
    portFolios: [{}],
    postBets: {},
    lowBalance: false,
    bankDetails: {},
    KYCdetails: {},
    referralDet: {},
    cancelBets: [{}],
    cancelResponse:false,
    settingsinfo: {},
    panLoader: false,
    orderResponse: {},
    rechargeResponse: [],
    rechargeStatusResponse: {},
    createOrderResponse: {},
    betPlaceRes:false,
    pinMatches:[{}],
    betSlotsquantity:0,
    settingLoad:0,
    panLoad:0,
    bankLoad:0,
    pinStatus:false,
    portfolioDetails: {},
    loadingPost:false
  },
  reducers: {
    betRes : (state,action)=>{
      state.betPlaceRes = action;
    },
    cancelExitRes :(state,action)=>{
      state.cancelResponse = false;
    },
    settingLoadto0 :(state,action)=>{
      state.settingLoad = 0;
    },
    panLoadto0 :(state,action)=>{
      state.panLoad = 0;
    },
    bankLoadto0 :(state,action)=>{
      state.bankLoad = 0;
    } ,
    setPinStatusToFalse : (state,action)=>{
      state.pinStatus= false;
    },
    setLoadingPost : (state,action)=>{
      state.loadingPost= action;
    },
    setRechargeResponse : (state,action)=>{
      state.rechargeResponse= [];
    },
    setRechargeStatusResponse : (state,action)=>{
      state.rechargeStatusResponse= {};
    },
    setLowBalance : (state,action)=>{
      state.lowBalance= false;
    }

  },
  extraReducers: (builders) => {
    builders
      .addCase(withDrawHistory.pending, (state, action) => {
        state.loadingPost=true
      })
      .addCase(withDrawHistory.fulfilled, (state, action) => {
        state.loadingPost=false
        state.withHistry = action.payload.data.reverse();
      })
      .addCase(withDrawHistory.rejected, (state, action) => { state.loadingPost=false})
      .addCase(transHistory.pending, (state, action) => {state.loadingPost=true})
      .addCase(transHistory.fulfilled, (state, action) => {
        state.loadingPost=false
        state.transHistories = action.payload.data.reverse()
      })
      .addCase(transHistory.rejected, (state, action) => { state.loadingPost=false})


      .addCase(withDraw.pending, (state, action) => {state.loadingPost=true})

      .addCase(withDraw.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "false") {
          state.withDrawResponse= payload;
          toast.error(payload.msg);
        } else {
          toast.success("Request sent");
          state.withDrawResponse= payload;

        }
      })
      .addCase(withDraw.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(readNotifications.pending, (state, action) => {state.loadingPost=true})

      .addCase(readNotifications.fulfilled, (state, { payload }) => {
        state.loadingPost=false
      })
      .addCase(readNotifications.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(addMoney.pending, (state, action) => {state.loadingPost=true})
      .addCase(addMoney.fulfilled, (state, action) => {
        state.loadingPost=false
        toast.success("Added successfully");
      })
      .addCase(addMoney.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(portfolio.pending, (state, action) => {state.loadingPost=true;
      })
      .addCase(portfolio.fulfilled, (state, action) => {
        state.portFolios = action.payload.data;
        state.loadingPost=false
      })
      .addCase(portfolio.rejected, (state, action) => {
        state.loadingPost=false
      })
      .addCase(postBet.pending, (state, action) => {state.loadingPost=true;
        state.lowBalance = false;
        state.betPlaceRes = false;
      })

      .addCase(postBet.fulfilled, (state, { payload }) => {
        console.log('...........payload..........',payload)
        state.loadingPost=false
        if (payload.status === "success") {
          toast.success("Bet placed successfully");
          state.postBets = payload;
          state.betPlaceRes = true;
          state.lowBalance = false;
        }
        else{
          toast.error(payload?.betHistory)
          state.lowBalance = true;
          state.betPlaceRes = false;

        }
      })
      .addCase(postBet.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(addBankDet.pending, (state, action) => {
        state.bankLoad = 1;
        state.loadingPost=true
      })
      .addCase(addBankDet.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          localStorage.setItem("bank_loader",2);
          toast.success("Requested");
          state.bankDetails = payload.user;
          state.bankLoad = 2;
        }
      })
      .addCase(addBankDet.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
        state.bankLoad = 0;
      })
      .addCase(addKYCdet.pending, (state, action) => {
        state.panLoad = 1
        state.loadingPost=true
      })
      .addCase(addKYCdet.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          localStorage.setItem("pan_loader", 1);
          state.panLoader = true;
          toast.success("Request sent");
          state.KYCdetails = payload.user;
          state.panLoad = 2
        }
      })
      .addCase(addKYCdet.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
        state.panLoad = 0
      })
      .addCase(reffCode.pending, (state, action) => {state.loadingPost=true})
      .addCase(reffCode.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          // toast.success("Added successfully");
          state.referralDet = payload.data;
        }
      })
      .addCase(reffCode.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(cancelBet.pending, (state, action) => {
        state.loadingPost=true
        state.cancelResponse = false;
      })
      .addCase(cancelBet.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          toast.success("Bet cancelled");
          state.cancelResponse = true;
          state.cancelBets = payload.cancelledMatches[1];
        }
        else{
          toast.error("Something went wrong");
        }
      })
      .addCase(cancelBet.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(cancelExitBet.pending, (state, action) => {
        state.loadingPost=true
        state.cancelResponse = false;
      })
      .addCase(cancelExitBet.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          toast.success("Bet cancelled");
          state.cancelResponse = true;
        }
        else{
          toast.error("Something went wrong");
        }
      })
      .addCase(cancelExitBet.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(settings.pending, (state, action) => {
        state.settingLoad = 1;
        state.loadingPost=true
      })
      .addCase(settings.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          toast.success("Saved successfully");
          state.settingsinfo = payload.user;
          state.settingLoad = 2;
        }
        else{
          toast.error("Something went wrong");
          state.settingLoad = 0;
        }
      })
      .addCase(settings.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
        state.settingLoad = 0;

      })
      .addCase(orderId.pending, (state, action) => {state.loadingPost=true})
      .addCase(orderId.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status === "success") {
          state.orderResponse = payload;
        }
        else{
          toast.error("Something went wrong");
        }
      })
      .addCase(orderId.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(recharge.pending, (state, action) => {state.loadingPost=true
        state.rechargeResponse = [];
      })
      .addCase(recharge.fulfilled, (state, { payload }) => {
        state.loadingPost=false
        if (payload.status) {
          state.rechargeResponse = payload;
        }
        else{
          toast.error("Something went wrong");
        }
      })
      .addCase(recharge.rejected, (state, action) => {
        state.loadingPost=false
        toast.error("Something went wrong");
      })
      .addCase(rechargeStatus.pending, (state, action) => {state.rechargeStatusResponse = {};})
      .addCase(rechargeStatus.fulfilled, (state, { payload }) => {
        if (payload.status) {
          state.rechargeStatusResponse = payload;
        }
      })
      .addCase(rechargeStatus.rejected, (state, action) => {
        toast.error("Something went wrong");
      })
      .addCase(createOrder.pending, (state, action) => {state.loadingPost=true})
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        // console.log(payload);
        state.loadingPost=false
        if (payload.status == true) {
          state.createOrderResponse = payload;
        } else {
        }
      })
      .addCase(createOrder.rejected, (state, action) => { state.loadingPost=false})
      .addCase(pinMatch.pending, (state, action) => {state.loadingPost=true})
      .addCase(pinMatch.fulfilled, (state, { payload }) => {
        // console.log(payload);
        state.loadingPost=false
        if (payload.status === "success") {
          if(payload.action == "0"){
            toast.success("Match Unpinned")
          }
          else{
            toast.success("Match Pinned")
          }
          state.pinMatches = payload.pinnedMatch;
          state.pinStatus = true;
        } else {
          toast.error("Something went wrong!")
        }
      })
      .addCase(pinMatch.rejected, (state, action) => { state.loadingPost=false})

      .addCase(getBetSlots.pending, (state, action) => {})
      .addCase(getBetSlots.fulfilled, (state, { payload }) => {
        // console.log(payload);
        state.loadingPost=false
        if (payload.status === "success") {
          state.betSlotsquantity = payload.data.total_qty_available;
        } else {
          toast.error("Something went wrong!")
        }
        
      })
      .addCase(getBetSlots.rejected, (state, action) => { state.loadingPost=false})

      .addCase(getPortDetails.pending, (state, action) => {
        // console.log("Upcoming Pending");
        state.loadingPost=true
      })
      .addCase(getPortDetails.fulfilled, (state, { payload }) => {
        state.portfolioDetails = payload.data;
        state.loadingPost=false
      })
      .addCase(getPortDetails.rejected, (state, action) => {
        // console.log("Rejected");
        state.loadingPost=false
      })
      .addCase(exitBet.pending, (state, action) => {
        // console.log("Upcoming Pending");
        state.loadingPost=true
        state.cancelResponse = false;
      })
      .addCase(exitBet.fulfilled, (state, { payload }) => {
        if(payload.status == "success"){
          toast.success(payload.message);
          state.cancelResponse = true;
          state.loadingPost=false;
        }
       
      })
      .addCase(exitBet.rejected, (state, action) => {
        // console.log("Rejected");
        state.loadingPost=false;
      
      })


  },
});
export const {betRes,cancelExitRes,settingLoadto0,panLoadto0,bankLoadto0,setPinStatusToFalse,setloadingPost,setRechargeResponse,setRechargeStatusResponse,setLowBalance} = PostSlice.actions;
export default PostSlice;
