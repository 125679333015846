import "./App.css";
import style from "./Auth/style.css";
import { useEffect, useState } from "react";
// import bootstyle1 from './Compos/bootstrap.css';
// import bootstyle2 from './Compos/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Screens/Home";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Wallet from "./Screens/Wallet";
import Notify from "./Screens/Notify";
import PLive from "./Screens/PLive";
import More from "./Screens/More";
import PrivacyPol from "./Screens/PrivacyPol";
import TnC from "./Screens/TnC";
import Profile from "./Screens/Profile";
import WithDrawHistry from "./Screens/WithDrawHistry";
import Kyc from "./Screens/Kyc";
import Transactions from "./Screens/Transactions";
import Addmoney from "./Screens/Addmoney";
import TopNav from "./Screens/TopNav";
import SideNav from "./Screens/SideNav";
import { RecaptchaVerifier } from "firebase/auth";
import Footer from "./Components/Footer";
import MatchDetQuest from "./Screens/MatchDetQuest";
import WithdrawReq from "./Screens/WithdrawReq";
import BetPopup from "./Screens/BetPopup";
import Faq from "./Screens/Faq";
import HowToPlay from "./Screens/HowToPlay";
import Offer from "./Screens/Offer";
import ContactUs from "./Screens/ContactUs";
import LiveChat from "./Screens/LiveChat";
import MatchDetQuestLive from "./Screens/MatchDetQuestLive";
import BetPopupLive from "./Screens/BetPopUpLive";
import ReferNEarn from "./Screens/ReferNEarn";
import Settings from "./Screens/Settings";
import HelpNsupport from "./Screens/HelpNsupport";
import VerifyAcc from "./Screens/VerifyAcc";
import Markets from "./Screens/Markets";
import BetpopMarkets from "./Screens/BetpopMarkets";
import AboutUs from "./Screens/AboutUs";
import PrtfloQuesCards from "./Screens/PrtflioQuesCard";
import PCancel from "./Screens/PCancel";
import GlobalLoader from "./Components/GlobalLoader";
// import PliveMatches from "./Screens/PliveMatches";
// import { Crisp } from "crisp-sdk-web";

function App() {

// useEffect(() => {
//   Crisp.configure("75008ad5-f78c-4225-aa49-58be1b983c5e");
// }, [])

  
  function PrivateRoute({ children }) {
    const user_id =
      localStorage.getItem("user_id") || sessionStorage.getItem("user_id");

    return user_id !== null && user_id !== undefined && user_id !== "" ? (
      children
    ) : (
      <>
        <Navigate to="/login" />
      </>
    );
  }

  const PrivateRouteNames = [
    {
      path: "/portfolio",
      Component: <PLive />,
    },
    {
      path: "/wallet",
      Component: <Wallet />,
    },
    {
      path: "/offer",
      Component: <Offer />,
    },
    {
      path: "/profile",
      Component: <Profile />,
    },

    {
      path: "/notify",
      Component: <Notify />,
    },
    {
      path: "/withdrawhistory",
      Component: <WithDrawHistry />,
    },
    {
      path: "/kyc",
      Component: <Kyc />,
    },
    {
      path: "/transaction",
      Component: <Transactions />,
    },
    {
      path: "/addmoney",
      Component: <Addmoney />,
    },
    {
      path: "/withdrawrequest",
      Component: <WithdrawReq />,
    },
    {
      path: "/placebet/:id/:qid",
      Component: <BetPopup />,
    },
    {
      path: "/placebetlive/:id/:qid",
      Component: <BetPopupLive />,
    },
    {
      path: "/placebetmarkets/:id/:qid",
      Component: <BetpopMarkets />,
    },
    {
      path: "/refer",
      Component: <ReferNEarn />,
    },
    {
      path: "/settings",
      Component: <Settings />,
    },
    {
      path: "/portfolioCards",
      Component: <PrtfloQuesCards />,
    },
    {
      path: "/portfolioCancel",
      Component: <PCancel />,
    },
    {
      path: "/verifyAcc",
      Component: <VerifyAcc />,
    },
   
  ];

  const PublicRouteNames = [
    {
      path: "/",
      Component: <Home />,
    },
    {
      path: "/login",
      Component: <Login />,
    },
    {
      path: "/more",
      Component: <More />,
    },

    {
      path: "/register",
      Component: <Register />,
    },

    {
      path: "/privacy",
      Component: <PrivacyPol />,
    },
    {
      path: "/tnc",
      Component: <TnC />,
    },

    {
      path: "/matchdetquestion/:id",
      Component: <MatchDetQuest />,
    },
    {
      path: "/faq",
      Component: <Faq />,
    },
    {
      path: "/htp",
      Component: <HowToPlay />,
    },

    {
      path: "/contact",
      Component: <ContactUs />,
    },
    {
      path: "/livechat",
      Component: <LiveChat />,
    },

    {
      path: "/matchdetquestionlive/:id",
      Component: <MatchDetQuestLive />,
    },
    {
      path: "/helpNsupport",
      Component: <HelpNsupport />,
    },
   
    {
      path: "/markets/:id",
      Component: <Markets />,
    },
    {
      path: "/aboutus",
      Component: <AboutUs />,
    },
  ];

  const user_id =
    localStorage.getItem("user_id") || sessionStorage.getItem("user_id");

  return (
    <>
    <Router>
      <Routes>
        {PublicRouteNames?.map((route, index) => {
          return (
            <Route
              key={index}
              exact
              path={route.path}
              element={route.Component}
            />
          );
        })}
        ;
        {PrivateRouteNames?.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute> {route.Component} </PrivateRoute>}
            />
          );
        })}
      </Routes>
    </Router>
    <GlobalLoader />
    </>
  );
}

export default App;
