import React, { useEffect } from 'react'
import visa from '../Assets/Images/visa.png';
import rupay from '../Assets/Images/rupay.png';
import master from '../Assets/Images/mastercard.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft,faHouseFire,faFaceSmileWink,faMoneyBillTrendUp,faMoneyBillTransfer,faBell, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userBalance } from '../Redux/GetSlice';
import Footer3 from '../Components/Footer3';



export default function Wallet() {

    const dispatch = useDispatch();


    const useid = localStorage.getItem("user_id");

    useEffect(()=>{
        dispatch(userBalance(useid))
    },[]);

    const {Balance} = useSelector((state)=>state.Get);
    const {totalBalanceResponse} = useSelector((state)=>state.Get);



const reloadBalance = ()=>{
    dispatch(userBalance(useid));
}














  return (
    <div>
  <div className="mobile__layout">
        <div className="container auth__container before-reg">
            <form className="style_auth__form__3wv2I" novalidate="">

                <div className="reg-back">
                <Link to='/'> 
                        <div className="reg__back">
                         <i >
                            <FontAwesomeIcon style={{color:"white"}} icon={faArrowLeft}/>
                         </i>
                        </div>
                        </Link> 
                    

                    <p className="wallt">Wallet</p>
                </div>

                <div className="mid_content">
                    <div className="notific">
                        <div className="notify_icon">
                            <i >
                            <FontAwesomeIcon style={{color:"#efef42"}} icon={faBell}/>

                            </i>
                        </div>
                        <p>Know how your balances work</p>
                    </div>

                    <div className="wallet_section">
                        <div style={{display:"flex"}}>
                        <h5>Balance Amount</h5> 
                        <div onClick={reloadBalance} style={{marginLeft:"10px",cursor:"pointer"}}>
                        <FontAwesomeIcon icon={faRefresh}/>

                        </div>

                        </div>
                        <p className="blnc_amnt"><strong>₹{parseInt(totalBalanceResponse?.user?.balance_deposit)+parseInt(totalBalanceResponse?.user?.balance_winning)} </strong></p>

                        <div className="full_contents">
                            <div className="left_items">
                                <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faHouseFire}/>
                                    </i>
                                </div>
                                <div className="cont_infor">
                                    <p className="rupe">₹{totalBalanceResponse?.user?.balance_deposit} </p>
                                    <p>Deposit amount</p>
                                </div>
                            </div>

                            <Link to='/addmoney'>

                            <div style={{backgroundColor:"black",color:"white",width:"108px",height:"27px",textAlign:"center",padding:"4px 0px 0px 0px",borderRadius:"3px",fontSize:"15px"}} className="right_items">
                                Add Money
                                
                            </div>
                            </Link>


                        </div>
                        <div className="full_contents">
                            <div className="left_items">
                                <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faFaceSmileWink}/>
                                    </i>
                                </div>
                                <div className="cont_infor">
                                    <p className="rupe">₹{totalBalanceResponse?.user?.balance_winning} </p>
                                    <p>Winning amount</p>
                                </div>
                            </div>

                            <div className="right_items">
                                <Link to='/withdrawrequest' className="add_money_btn withdraw"  >
                                    Withdraw
                                    </Link>
                            </div>
                        </div>
                        <div className="full_contents">
                            <div className="left_items">
                                <div className="icon">
                                    <i >
                                    <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTrendUp}/>
                                    </i>
                                </div>
                                <div className="cont_infor">
                                    <p className="rupe">₹{totalBalanceResponse?.user?.balance_bonus} </p>
                                    <p>Bonus amount</p>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="list_items_type">
                        <Link to='/withdrawhistory'>
                        <div className="items_listtype">
                            <div className="icon">
                                <i >
                                <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTransfer}/>
                                    
                                </i>
                            </div>
                            <p className="descr">Withdrawal History</p>
                        </div>
                        </Link>
                       
                    </div>

                    <div className="list_items_type">
                    <Link to="/verifyAcc">

                        <div className="ful_con">
                            <div className="items_listtype">
                                <div className="icon">
                                    <i >
                                <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTransfer}/>

                                    </i>
                                </div>
                                <p className="descr">KYC</p>
                            </div>
                            <p>(Verify KYC to withdraw)</p>
                           
                        </div>
                        </Link>

                    </div>

                    <Link to='/transaction'>
                    <div className="list_items_type">
                        <div className="items_listtype">
                            <div className="icon">
                                <i >
                                <FontAwesomeIcon style={{color:"#9d9dff"}} icon={faMoneyBillTransfer}/>
                                    
                                </i>
                            </div>
                            <p className="descr">Transactions</p>
                        </div>
                    </div>
                    </Link>

                   


                    <div className="bottom_logos">
                        <div className="logo_bot">
                            <img src={master} alt=""/>
                        </div>
                        <div className="logo_bot">
                            <img src={rupay} alt=""/>
                        </div>
                        <div className="logo_bot">
                            <img src={visa} alt=""/>
                        </div>
                    </div>
                </div>


            </form>
        </div>
        <div className="Toastify">

        </div>
        <div style={{position:"sticky",bottom:"0"}}>
            <Footer3/>
        </div>
    </div>

    </div>
  )
}
