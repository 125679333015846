import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiInstance from "./Helper";
import toast from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../config";

export const upcoming = createAsyncThunk("/upcoming", async () => {
  let response = await axios.get(`${BASE_URL}get-upcoming-matches`);
  let resData = response?.data;
  return resData;
});

export const category = createAsyncThunk("/category", async () => {
  let response = await axios.get(`${BASE_URL}get-categories`);
  let resData = response?.data;
  return resData;
});

export const market = createAsyncThunk("/markets", async () => {
  let response = await axios.get(`${BASE_URL}get-markets`);
  let resData = response?.data;
  return resData;
});

export const matchQuest = createAsyncThunk("/matchdetquestion", async (id) => {
  let response = await axios.get(`${BASE_URL}get-match-questions/${id}`);
  let resData = response?.data;
  return resData;
});

export const liveMatch = createAsyncThunk("/livematches", async () => {
  let response = await axios.get(`${BASE_URL}get-live-matches`);
  let resData = response?.data;
  return resData;
});
export const getSlots = createAsyncThunk("/get-slots", async (amount) => {
  let response = await axios.get(
    `${BASE_URL}get-bet-available-slots/${amount}`
  );
  let resData = response?.data;
  return resData;
});
export const getyesno = createAsyncThunk("/get-yesno", async (id) => {
  let response = await axios.get(
    `${BASE_URL}get-question-yes-no-count-amount-wise/${id}`
  );
  let resData = response?.data;
  return resData;
});

export const maxMinBet = createAsyncThunk("/get-max/min-bet", async (id) => {
  let response = await axios.get(`${BASE_URL}get-max-and-min-bet-amount/${id}`);
  let resData = response?.data;
  return resData;
});

export const stateCountry = createAsyncThunk(
  "/get-state-by-country",
  async (id) => {
    let response = await axios.get(`${BASE_URL}get-state-by-country/${id}`);
    let resData = response?.data;
    return resData;
  }
);

export const userBalance = createAsyncThunk("/get-user-balance", async (id) => {
  let response = await axios.get(`${BASE_URL}get-user-available-amount/${id}`);
  let resData = response?.data;
  return resData;
});

export const getCountry = createAsyncThunk("/get-country", async () => {
  let response = await axios.get(`${BASE_URL}get-all-country`);
  let resData = response?.data;
  return resData;
});

export const getState = createAsyncThunk("/get-state", async (id) => {
  let response = await axios.get(`${BASE_URL}get-state-by-country/${id}`);
  let resData = response?.data;
  return resData;
});

export const getNotify = createAsyncThunk("/get-notifications", async (useid) => {
  let response = await axios.get(`${BASE_URL}get-notifications/${useid}`);
  let resData = response?.data;
  return resData;
});

export const getOffers = createAsyncThunk("/get-offers", async () => {
  let response = await axios.get(`${BASE_URL}get-offers`);
  let resData = response?.data;
  return resData;
});

export const getAboutUs = createAsyncThunk("/get-about-us", async () => {
  let response = await axios.get(`${BASE_URL}cms/about_us`);
  let resData = response?.data;
  return resData;
});

export const getHowtoplay = createAsyncThunk("/get-how-to-play", async () => {
  let response = await axios.get(`${BASE_URL}cms/how_to_play`);
  let resData = response?.data;
  return resData;
});

export const getReferNearn = createAsyncThunk(
  "/get-refer-and-earn",
  async () => {
    let response = await axios.get(`${BASE_URL}cms/refer_&_earn`);
    let resData = response?.data;
    return resData;
  }
);

export const getReferList = createAsyncThunk("/get-refer-list", async () => {
  let response = await axios.get(`${BASE_URL}cms/refer_list`);
  let resData = response?.data;
  return resData;
});

export const getTnc = createAsyncThunk(
  "/get-terms-and-conditions",
  async () => {
    let response = await axios.get(`${BASE_URL}cms/terms_&_conditions`);
    let resData = response?.data;
    return resData;
  }
);

export const getPrivacypol = createAsyncThunk(
  "/get-privacy-policy",
  async () => {
    let response = await axios.get(`${BASE_URL}cms/privacy_policy`);
    let resData = response?.data;
    return resData;
  }
);

export const getHelpNsupport = createAsyncThunk(
  "/get-help-and-support",
  async () => {
    let response = await axios.get(`${BASE_URL}cms/help_&_support`);
    let resData = response?.data;
    return resData;
  }
);

export const getBanner = createAsyncThunk("/get-banner", async () => {
  let response = await axios.get(`${BASE_URL}get-banner`);
  let resData = response?.data;
  return resData;
});

export const getReferDetails = createAsyncThunk(
  "/get-refer-details",
  async (id) => {
    let response = await axios.get(`${BASE_URL}get-referral/${id}`);
    let resData = response?.data;
    return resData;
  }
);

export const getClosedEvents = createAsyncThunk(
  "/get-closed-events",
  async (id) => {
    let response = await axios.get(
      `${BASE_URL}get-cancel-close-question/${id}`
    );
    let resData = response?.data;
    return resData;
  }
);

export const getRequestStatus = createAsyncThunk(
  "/get-requested-status",
  async (id) => {
    let response = await axios.get(`${BASE_URL}get-user-kyc/${id}`);
    let resData = response?.data;
    return resData;
  }
);

export const getPinnedMatches = createAsyncThunk(
  "/get-pinned-matches",
  async (id) => {
    let response = await axios.get(`${BASE_URL}get-pinned-match/${id}`);
    let resData = response?.data;
    return resData;
  }
);

export const getFaqs = createAsyncThunk("/get-all-faq", async (id) => {
  let response = await axios.get(`${BASE_URL}get-all-faq`);
  let resData = response?.data;
  return resData;
});

export const getContact = createAsyncThunk("/get-contact-us", async () => {
  let response = await axios.get(`${BASE_URL}get-site-settings`);
  let resData = response?.data;
  return resData;
});

export const getQuestByMarket = createAsyncThunk(
  "/get-questions-by-market-id",
  async (id) => {
    let response = await axios.get(`${BASE_URL}get-questions-by-market/${id}`);
    let resData = response?.data;
    return resData;
  }
);

export const getPortfolioMatches = createAsyncThunk(
  "/get-portfolio-matches",
  async (data) => {
    console.log('.....status....data..',data);
    let response = await axios.get(`${BASE_URL}get-portfolio-matches?user_id=${data.id}&status=${data.status}`);
    let resData = response?.data;
    return resData;
  }
);


export const imgBaseURL = "";

export const GetSlice = createSlice({
  name: "GetSlice",
  initialState: {
    upcomingMatch: [{}],
    categories: [{}],
    markets: [{}],
    matchQuestions: [{}],
    marketQuestions: [{}],
    liveMatches: {},
    betSlots: {},
    yesNoCounts: [{}],
    maxMinBets: [{}],
    statesByCountry: [{}],
    totalBalanceResponse: {},
    Balance: 0,
    countries: [{}],
    states: [{}],
    Notifies: [{}],
    Offers: [{}],
    yesNoopt: 0,
    banners: [{}],
    pages: {
      aboutUs: {},
      howToPlay: {},
      referNearn: {},
      referList: {},
      termsAndCons: {},
      privacyPolicy: {},
      helpAndSupport: {},
    },
    refers: {},
    ClosedEvents: [{}],
    requestStatus: {},
    pinnedMatches: [{}],
    faqs: [{}], 
    contactUs: {},
    loadingGet:false,
    portfolioMatches:[],
  },
  reducers: {
    yes: (state, action) => {
      state.yesNoopt = 1;
    },
    no: (state, action) => {
      state.yesNoopt = 2;
    },
    setLoadingGet : (state,action)=>{
      state.loadingGet= action;
    }
  },
  extraReducers: (builders) => {
    builders
      .addCase(upcoming.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(upcoming.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.upcomingMatch = payload.data;
      })
      .addCase(upcoming.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(category.pending, (state, action) => {
        state.loadingGet=true
        // // console.log("Upcoming Pending");
      })
      .addCase(category.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.categories = payload.data;
      })
      .addCase(category.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })

      .addCase(market.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(market.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.markets = payload.data;
      })
      .addCase(market.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(matchQuest.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(matchQuest.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.matchQuestions = payload.data;
      })
      .addCase(matchQuest.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(liveMatch.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(liveMatch.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.liveMatches = payload.data;
      })
      .addCase(liveMatch.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getSlots.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getSlots.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.betSlots = payload;
      })
      .addCase(getSlots.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getyesno.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getyesno.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.yesNoCounts = payload.data;
      })
      .addCase(getyesno.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(maxMinBet.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(maxMinBet.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.maxMinBets = payload.data;
      })
      .addCase(maxMinBet.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(stateCountry.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(stateCountry.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.statesByCountry = payload.state;
      })
      .addCase(stateCountry.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(userBalance.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(userBalance.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.totalBalanceResponse = payload;
        state.Balance = payload.amount_available;
      })
      .addCase(userBalance.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getCountry.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getCountry.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.countries = payload.countries;
      })
      .addCase(getCountry.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getState.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getState.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.states = payload.state;
      })
      .addCase(getState.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getNotify.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getNotify.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.Notifies = payload;
      })
      .addCase(getNotify.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getOffers.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getOffers.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.Offers = payload.offers;
      })
      .addCase(getOffers.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getAboutUs.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getAboutUs.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.aboutUs = payload.page;
      })
      .addCase(getAboutUs.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getHowtoplay.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getHowtoplay.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.howToPlay = payload.page;
      })
      .addCase(getHowtoplay.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getReferNearn.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getReferNearn.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.referNearn = payload.page;
      })
      .addCase(getReferNearn.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getReferList.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getReferList.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.referList = payload.page;
      })
      .addCase(getReferList.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getTnc.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getTnc.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.termsAndCons = payload.page;
      })
      .addCase(getTnc.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getPrivacypol.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getPrivacypol.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.privacyPolicy = payload.page;
      })
      .addCase(getPrivacypol.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getHelpNsupport.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getHelpNsupport.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pages.helpAndSupport = payload.page;
      })
      .addCase(getHelpNsupport.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getBanner.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getBanner.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.banners = payload.page;
      })
      .addCase(getBanner.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getReferDetails.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getReferDetails.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.refers = payload;
      })
      .addCase(getReferDetails.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getClosedEvents.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getClosedEvents.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.ClosedEvents = payload.data;
      })
      .addCase(getClosedEvents.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getRequestStatus.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getRequestStatus.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.requestStatus = payload;
      })
      .addCase(getRequestStatus.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getPinnedMatches.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getPinnedMatches.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.pinnedMatches = payload.pinnedMatch;
      })
      .addCase(getPinnedMatches.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getFaqs.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getFaqs.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.faqs = payload.settings;
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getContact.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getContact.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.contactUs = payload;
        // imgBaseURL = payload.base_url;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getQuestByMarket.pending, (state, action) => {
        state.loadingGet=true
        // console.log("Upcoming Pending");
      })
      .addCase(getQuestByMarket.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.marketQuestions = payload.questions;
      })
      .addCase(getQuestByMarket.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
      .addCase(getPortfolioMatches.pending, (state, action) => {
        state.loadingGet=true
        state.portfolioMatches=[]
        // console.log("Upcoming Pending");
      })
      .addCase(getPortfolioMatches.fulfilled, (state, { payload }) => {
        state.loadingGet=false
        state.portfolioMatches = payload.data;
      })
      .addCase(getPortfolioMatches.rejected, (state, action) => {
        state.loadingGet=false
        // console.log("Rejected");
      })
     
  },
});

export const { yes, no,setLoadingGet } = GetSlice.actions;

export default GetSlice;
