import React, { useEffect, useReducer, useState } from "react";
import caution from "../Assets/Images/caution.png";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faBookBookmark,
  faCheck,
  faCross,
  faEdit,
  faMinus,
  faPlus,
  faShare,
  faSquareShareNodes,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";

import { betRes, postBet } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContact, matchQuest } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import PricesPop from "./PricesPop";
import PriceEnter from "./PriceEnter";
import { getSlots } from "../Redux/GetSlice";
import { getyesno } from "../Redux/GetSlice";
import { userBalance } from "../Redux/GetSlice";
import { maxMinBet } from "../Redux/GetSlice";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import jersey from "../Assets/Images/jerseypng.png";
import { getBetSlots } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import SwipeButton from "../Components/SwipeButton";

export default function BetPopup() {
  let maxOpt1;
  const dispatch = useDispatch();

  const uid = localStorage.getItem("user_id");

  const params = useParams();
  const { betSlotsquantity } = useSelector((state) => state.Post);
  const { lowBalance } = useSelector((state) => state.Post);
  const { matchQuestions } = useSelector((state) => state.Get);
  const { contactUs } = useSelector((state) => state.Get);
  const { postBets } = useSelector((state) => state.Post);
  const { betSlots } = useSelector((state) => state.Get);
  const { yesNoCounts } = useSelector((state) => state.Get);
  const { maxMinBets } = useSelector((state) => state.Get);
  const { betPlaceRes } = useSelector((state) => state.Post);
  const { yesNoopt } = useSelector((state) => state.Get);

  const [swipe, setSwipe] = useState(false);

  const [inp1, setInp1] = useState(5);
  const [inp2, setInp2] = useState(250);
  const [count, setCount] = useState(0);
  const [optionCost, setOptionCost] = useState(yesNoopt);
  const navigate = useNavigate();
  const [bet, setBet] = useState(false);
  const [opt, setOpt] = useState("");
  const [counts, setCounts] = useState(false);

  const [pop, setPop] = useState(false);
  const [enter, setEnter] = useState(false);
  const [acc, setAcc] = useState(false);
  const [recharge, setRecharge] = useState(false);

  const accExpand = () => {
    if (acc) {
      setAcc(false);
    } else {
      setAcc(true);
    }
  };

  useEffect(() => {
    if (maxOpt1 == null) {
      setInp1(5);
    } else {
      if (optionCost == 1) {
        setInp1(parseFloat(maxOpt1));
      } else {
        setInp1(10 - parseFloat(maxOpt1));
      }
    }
  }, [matchQuestions, maxOpt1]);

  const nonenter = () => {
    setEnter(false);
  };
  const pricepop = () => {
    if (inp2 !== 1) {
      setInp2(inp2 - 1);
    }
  };

  const pricepop2 = () => {
    if (inp2 < contactUs?.settings?.quantity_max_limit) {
      setInp2(inp2 + 1);
    } else {
      toast.error("Maximum quantity exceeded", { duration: 5000 });
    }
  };

  const priceunpop = () => {
    setPop(false);
  };



  useEffect(() => {
    dispatch(getContact());
  }, []);

  const AccItems = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            columnGap: "10px",
            height: "50px",
            justifyContent: "center",
            width: "370px",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "45px",
                width: "150px",
              }}
            >
              <div style={{ fontSize: "12px", fontWeight: "800" }}> Price</div>
              <div style={{ fontSize: "12px" }}>
                Qty at <span style={{ color: "#0ac6ff" }}>Opt 1</span>{" "}
              </div>
            </div>
            <hr style={{ marginTop: "5px" }} />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "45px",
                width: "150px",
              }}
            >
              <div style={{ fontSize: "12px", fontWeight: "800" }}> Price</div>
              <div style={{ fontSize: "12px" }}>
                Qty at <span style={{ color: "red" }}>Opt 2</span>{" "}
              </div>
            </div>
            <hr style={{ marginTop: "5px" }} />
          </div>
        </div>
      </>
    );
  };

  const InsuffBalance = () => {
    return (
      <>
        <div className="left_bl_">
          <div className="caution_icon">
            <img src={caution} alt="" />
          </div>
          <div className="suffc_content">
            <p className="insu">Insufficient balance</p>
            <p className="lern">Learn more</p>
          </div>
        </div>
        <div></div>
        <Link
          to="/addmoney"
          style={{
            backgroundColor: "#353535",
            color: "white",
            height: "30px",
            width: "120px",
            borderRadius: "5px",
            padding: "4px 0 0 20px",
          }}
        >
          <div className="right_bl_">Recharge</div>
        </Link>
      </>
    );
  };



  useEffect(() => {
    const formData = new FormData();
    formData.append("selected_option", optionCost);
    formData.append("question_id", params.qid);
    formData.append("selected_amount", inp1);
    dispatch(getBetSlots(formData));
  }, [inp1, optionCost]);

  useEffect(() => {
    dispatch(maxMinBet(params.id));
  }, []);

  useEffect(()=>{
      if (betPlaceRes === true) {
        setTimeout(() => {
          dispatch(betRes(false))
          navigate(`/matchdetquestionlive/${params.id}`);
        }, 1000);
    }
  },[betPlaceRes])


  let time = setTimeout(() => {
    setCount(count + 1);
  }, 1000);

  if (count > 10) {
    clearTimeout(time);
  }

  let timed = setTimeout(() => {
    setCounts(counts + 1);
  }, 500);

  if (counts > 4) {
    clearTimeout(timed);
  }

  useEffect(() => {
    if (yesNoopt === 1) {
      setToggle(true);
    } else {
      setToggle(false);
    }
    setOpt(yesNoopt);
  }, []);

  const placeBet = async (isSwiped) => {
    if (isSwiped) {
      const formData = new FormData();
      formData.append("user_id", uid);
      formData.append("price", inp1);
      formData.append("question_id", params.qid);
      formData.append("quantity", inp2);
      formData.append("selected_option", opt);

      dispatch(postBet(formData));

      dispatch(userBalance(uid));
    }
  };

  const { Balance } = useSelector((state) => state.Get);
  const { totalBalanceResponse } = useSelector((state) => state.Get);

  useEffect(() => {
    dispatch(getyesno(params.id));
  }, []);

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userBalance(uid));
  }, [Balance]);

  const chngevalminus = () => {
    if (inp1 !== 0) {
      setInp1(inp1 - 0.5);
    }
  };
  const chngevalplus = () => {
    if (inp1 !== 10) {
      setInp1(inp1 + 0.5);
    }
  };

  const [toggle, setToggle] = useState(false);

  // const dragResponse = () => {
  //   console.log("dragging");
  // };

  const yesT = () => {
    setToggle(true);
    setOpt(1);
    setOptionCost(1);
  };
  const noT = () => {
    setToggle(false);
    setOpt(2);
    setOptionCost(2);
  };

  const [side, setSide] = useState(false);
  const openCloseSideNav = () => {
    if (side === true) {
      setSide(false);
    } else {
      setSide(true);
      // console.log("Open");
    }
  };

  const closeSideNav = () => {
    setSide(false);
  };

  const setQty = (data) => {
    if (data <= contactUs?.settings?.quantity_max_limit) {
      setInp2(data);
    } else {
      toast.error("Maximum quantity exceeded", { duration: 5000 });
    }
  };

  return (
    <div>
      {side ? (
        <div
          style={{
            zIndex: "9",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            display: "block",
            overflow: "hidden",
          }}
        >
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </div>
      ) : (
        <></>
      )}
      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <TopNav onOpen={openCloseSideNav} />
        </div>
        <div
          style={{ backgroundColor: "white" }}
          className="container auth__container"
        >
          <div
            style={{
              height: "500px",
              boxShadow: "0px -6px 10px -5px",
              zIndex: "0",
              borderRadius: "0px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderBottom: "2px solid white",
            }}
            className="bottom_sheet bottom_to_top"
          >
            <Link
              to={`/matchdetquestionlive/${params.id}`}
              style={{ marginLeft: "80px", marginTop: "20px" }}
            >
              <div
                style={{
                  marginLeft: "17px",
                  width: "30px",
                  height: "30px",
                  backgroundColor: "lightgrey",
                  paddingLeft: "9px",
                  paddingTop: "6px",
                  borderRadius: "50px",
                }}
              >
                <FontAwesomeIcon color="" icon={faArrowLeft} />
              </div>
            </Link>
            <div className="slider_contents">
              <div className="top_dtl_content">
                {matchQuestions.map((items, index) => {
                  if (items?.question_id == params.qid) {
                    return <h5 key={index}>{items?.question_text} </h5>;
                  }
                })}
                <div className="detail_placeholder_img">
                  {matchQuestions.map((items, index) => {
                    if (items?.question_id == params.qid) {
                      return (
                        <div key={index}>
                          {items?.question_image == null ||
                          items?.question_image == undefined ||
                          items?.question_image == "" ? (
                            <img alt="" src={jersey} />
                          ) : (
                            <img src={produc(items?.question_image)} alt="" />
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              <div
                style={{
                  height: "50px",
                  cursor: "pointer",
                  width: "90%",
                  margin: "auto",
                  marginTop: "40px",
                  marginBottom: "50px",
                  display: "flex",
                  borderRadius: "50px",
                  border: "2px solid lightgrey",
                  justifyContent: "space-between",
                }}
              >
                <div onClick={yesT}>
                  {toggle === true ? (
                    <div
                      style={{
                        width: "165px",
                        borderRadius: "50px",
                        height: "46px",
                        backgroundColor: "blue",
                        textAlign: "center",
                        padding: "13px 0 0 0px",
                        color: "white",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;

                            return (
                              <div key={index}>
                                {items?.option1_text} ₹
                                {optionCost == 1 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        width: "165px",
                        height: "50px",
                        padding: "12px 0 0 0",
                      }}
                    >
                      {" "}
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;

                            return (
                              <div key={index}>
                                {items?.option1_text} ₹
                                {optionCost == 1 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}{" "}
                    </div>
                  )}
                </div>

                <div onClick={noT}>
                  {toggle === true ? (
                    <div
                      style={{
                        textAlign: "center",
                        width: "165px",
                        height: "50px",
                        padding: "12px 0 0 0",
                      }}
                    >
                      {" "}
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;

                            return (
                              <div key={index}>
                                {items?.option2_text} ₹
                                {optionCost == 2 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "165px",
                        height: "46px",
                        marginRight: "1px",
                        borderRadius: "50px",
                        backgroundColor: "#ff5953",
                        textAlign: "center",
                        padding: "13px 0 0 0px",
                        color: "white",
                      }}
                    >
                      {Array.isArray(matchQuestions) &&
                        matchQuestions?.map((items, index) => {
                          if (items?.question_id == params?.qid) {
                            maxOpt1 = items?.min_option1_amount;

                            return (
                              <div key={index}>
                                {items?.option2_text} ₹
                                {optionCost == 2 ? inp1 : 10 - inp1}
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
                </div>
              </div>

              <div
                onClick={() => {
                  setPop(false);
                  setEnter(false);
                }}
                className="custom_set"
              >
                <div className="custm_btns">
                  <div className="set_price">Set Price</div>
                </div>
                <i>
                  <FontAwesomeIcon icon={faSquareShareNodes} />
                </i>
              </div>
              <div
                onClick={() => {
                  setPop(false);
                  setEnter(false);
                }}
                className="slider_price"
              >
                <div className="upper_actions_info">
                  <label for="customRange1" className="form-label">
                    Price
                  </label>
                  <div className="right_infor">
                    <div style={{ fontSize: "15px" }} className="price_info">
                      ₹{inp1}{" "}
                    </div>
                    <div className="quant_info">
                      {" "}
                      {betSlotsquantity} Quantity Available
                    </div>
                  </div>
                </div>
                <div className="slider_actions">
                  <div onClick={chngevalminus} className="minus_icon">
                    <i className="fa-solid fa-minus"></i>
                  </div>

                  <div className="chrome">
                    <input
                      className="myinput"
                      min={0.5}
                      max={9.5}
                      step={0.5}
                      value={inp1}
                      onChange={(event) => {
                        if (
                          parseInt(event.target.value) >= 0.5 &&
                          parseInt(event.target.value) <= 9.5
                        )
                          setInp1(parseInt(event.target.value));
                      }}
                      type="range"
                    />
                  </div>

                  <div onClick={chngevalplus} className="plus_icon">
                    <i className="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
              <div className="slider_price">
                <div className="upper_actions_info">
                  <label for="customRange1" className="form-label">
                    Quantity
                  </label>
                  <div
                    style={{ display: "flex", columnGap: "10px" }}
                    className="right_infor"
                  >
                    <div className="price_info">{inp2} </div>
                    <FontAwesomeIcon
                      style={{ height: "17px" }}
                      onClick={() => setEnter(true)}
                      icon={faEdit}
                    />
                  </div>
                </div>
                <div className="slider_actions">
                  <div onClick={pricepop} className="minus_icon">
                    <i>
                      <FontAwesomeIcon icon={faMinus} />
                    </i>
                  </div>
                  <div className="chrome">
                    <input
                      className="myinput"
                      min={1}
                      max={contactUs?.settings?.quantity_max_limit}
                      step={1}
                      type="range"
                      onChange={(event) => {
                        if (
                          parseInt(event.target.value) >= 1 &&
                          parseInt(event.target.value) <=
                            contactUs?.settings?.quantity_max_limit
                        )
                          setInp2(parseInt(event.target.value));
                      }}
                      value={inp2}
                    />
                  </div>
                  <div onClick={pricepop2} className="plus_icon">
                    <i>
                      <FontAwesomeIcon icon={faPlus} />
                    </i>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  setPop(false);
                  setEnter(false);
                }}
                className="btm_cont"
              >
                <div className="you_put">
                  <p className="put_am">₹{inp1 * inp2} </p>
                  <p className="yu-put">You put</p>
                </div>
                <div className="you_get">
                  <p className="get_am">₹{inp2 * 10}</p>
                  <p className="yu-get">You get</p>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "10px" }}>
              {pop && <PricesPop setInp={setInp2} close={priceunpop} />}
              {enter && (
                <PriceEnter
                  close={nonenter}
                  setInpp={(data) => setQty(data)}
                  limit={contactUs?.settings?.quantity_max_limit}
                />
              )}
            </div>
          </div>

          <div
            style={{
              border: "0.1px solid lightgrey",
              width: "371px",
              margin: "auto",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={accExpand}
              style={{
                height: "55px",
                alignItems: "center",
                columnGap: "200px",
                justifyContent: "flex-start",
                display: "flex",
                width: "370px",
                backgroundColor: "white",
                margin: "auto",
                borderRadius: "5px",
                border: "1px solid lightgrey",
                borderBottom:
                  acc === true ? "1.5px solid white" : "1.5px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  columnGap: "30px",
                  marginLeft: "20px",
                }}
              >
                <FontAwesomeIcon color="red" icon={faBookBookmark} />
                <h4>Order</h4>
              </div>

              <FontAwesomeIcon icon={acc === true ? faAngleUp : faAngleDown} />
            </div>
            <div style={{ margin: "auto", marginLeft: "0px" }}>
              {acc && <AccItems />}
            </div>

            {acc && (
              <div style={{ marginTop: "-10px" }}>
                {yesNoCounts?.map((items, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          height: "30px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "center",
                          columnGap: "70px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            height: "30px",
                            width: "120px",
                            justifyContent: "center",
                            columnGap: "75px",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ marginLeft: "10px" }}>
                            {items?.created_bet_amount}{" "}
                          </p>
                          <p>{items?.option1} </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            height: "30px",
                            width: "120px",
                            justifyContent: "flex-start",
                            columnGap: "65px",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ marginLeft: "-15px" }}>
                            {items?.created_bet_amount}{" "}
                          </p>
                          <p>{items?.option2} </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div style={{ backgroundColor: "white" }} className="btm_lw">
            <div className="swipe_actions">
              <div className="insuffc">{lowBalance && <InsuffBalance />}</div>

              <main>
                {betPlaceRes === true ? (
                  <div
                    style={{
                      width: "90%",
                      height: "40px",
                      margin: "auto",
                      borderRadius: "50px",
                      textAlign: "center",
                      fontSize: "20px",
                      paddingTop: "6px",
                      backgroundColor: "green",
                      color: "white",
                    }}
                  >
                    Bet Placed <FontAwesomeIcon icon={faCheck} />
                  </div>
                ) : (
                  // <div onClick={placeBet} style={{width:"90%",height:"40px",margin:"auto",borderRadius:"50px",textAlign:"center",fontSize:"20px",paddingTop:"6px",backgroundColor:"black",color:"white",cursor:"pointer"}}>
                  //   Place Bet
                  // </div>
                  <SwipeButton onSwipeEnd={placeBet} refresh={lowBalance} />
                )}
              </main>
            </div>
            <div className="available_bl">
              Available Balance: ₹
              {parseInt(totalBalanceResponse?.user?.balance_deposit) +
                parseInt(totalBalanceResponse?.user?.balance_winning)}{" "}
            </div>
          </div>
        </div>
      </div>

      <div style={{ position: "sticky", bottom: "0" }}>
        <Footer />
      </div>
    </div>
  );
}
