import { faArrowLeft, faCross, faMinus, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function PricesPop({close,setInp}) {

const set1 =()=>{
setInp(250)
}
const set2 =()=>{
  setInp(500)
}
const set3 =()=>{
  setInp(750)
}







  return (
    <div>
      {/* <div className='modal-wrapper'></div> */}

      <div className='modal-container' style={{height:"130px",margin:"auto",borderRadius:"15px",width:"400px",backgroundColor:"#e2e2e2",columnGap:"20px"}}>
        <div style={{display:"flex"}} >
            <div onClick={set1} style={{height:"40px",cursor:"pointer",width:"80px",marginTop:"10px",marginLeft:"10px",textAlign:"center",paddingTop:"9.5px",backgroundColor:"white",borderRadius:"10px"}}> 250</div>
            <div  onClick={set2}  style={{height:"40px",cursor:"pointer",width:"80px",marginTop:"10px",marginLeft:"10px",textAlign:"center",paddingTop:"9.5px",backgroundColor:"white",borderRadius:"10px"}}>500</div>
            <div  onClick={set3}  style={{height:"40px",cursor:"pointer",width:"80px",marginTop:"10px",marginLeft:"10px",textAlign:"center",paddingTop:"9.5px",backgroundColor:"white",borderRadius:"10px"}}>750</div>
            <div onClick={close} style={{height:"30px",cursor:"pointer",width:"30px",alignSelf:"center",marginLeft:"90px",borderRadius:"50px",backgroundColor:"#999999"}}>
             <FontAwesomeIcon style={{marginLeft:"8.5px",marginTop:"7px"}} icon={faMinus}/>
            </div>
        </div>
     <div onClick={close} style={{cursor:"pointer",height:"35px",width:"94%",borderRadius:"5px",margin:"auto",marginTop:"25px",backgroundColor:"#303030",color:"white",textAlign:"center",paddingTop:"5px"}}>
      Select
     </div>
        </div>
    </div>
  )
}
