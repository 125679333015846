import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PortfCompos from "../Components/PortfCompos";
import TopNav from "./TopNav";
import jersey from "../Assets/Images/jersey.jfif";
import Footer from "../Components/Footer";
import { portfolio } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer2 from "../Components/Footer2";
import SideNav from "./SideNav";
import { cancelBet } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import TopNavWOL from "./TopNavWOL";
import { getClosedEvents } from "../Redux/GetSlice";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import { userBalance } from "../Redux/GetSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import logo4 from "../Assets/Images/fav1.png";
import { io } from "socket.io-client";
import { getPortDetails } from "../Redux/PostSlice";
import PLiveMatches from "./PLiveMatches";
import { getPortfolioMatches } from "../Redux/GetSlice";
import banner from "../Assets/Images/hero_image.avif";
import './../Auth/style.css'

const socket = io.connect(RT_BASE_URL);

export default function PCancel() {
  const [click, setClick] = useState(false);

  const { portFolios } = useSelector((state) => state.Post);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { ClosedEvents } = useSelector((state) => state.Get);
  const { cancelResponse } = useSelector((state) => state.Post);
  const { portfolioDetails } = useSelector((state) => state.Post);
  const { portfolioMatches } = useSelector((state) => state.Get);

  const [portfolioAllMatches, setportfolioAllMatches] = useState([]);

  const dispatch = useDispatch();

  const id = localStorage.getItem("user_id");

  useEffect(() => {
    if (portfolioMatches?.length > 0) {
      setportfolioAllMatches(
        portfolioMatches?.map((item) => {
          return {
            ...item,
            extra_data: item?.extra_data ? JSON.parse(item?.extra_data) : {},
          };
        })
      );
    } else {
      setportfolioAllMatches([]);
    }
  }, [JSON.stringify(portfolioMatches)]);

  useEffect(() => {
    dispatch(userBalance(id));
  }, []);

  const [quesCardId, setQuesCardId] = useState(1);
  const [closeQues, setCloseQues] = useState([{}]);
  const [quests, setQuests] = useState([{}]);

  useEffect(() => {
    setCloseQues(ClosedEvents);
  }, [ClosedEvents]);

  useEffect(() => {
    setQuests(portFolios);
  }, []);

  useEffect(() => {
    socket.on("questionUpdated", (data) => {
      dispatch(getClosedEvents(id));
    });
  }, []);

  const demoApicallforClosedPortfs = () => {
    //This funtion to be changed when api available
  };

  // Running useeffect for particular ques id to call api
  // useEffect(()=>{
  //   dispatch(demoApicallforClosedPortfs(QuesCardid))
  // },[QuesCardid])

  useEffect(() => {
    let data = {
      id: id,
      status: "Completed",
    };
    dispatch(getPortfolioMatches(data));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClosedEvents(id));
  }, []);

  let arr = [0, 1, 2, 3, 4];

  const [side, setSide] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [mID, setMID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [toQuest, setToQuest] = useState(false);

  const toQuestions = (mId, type) => {
    console.log("...type...", type);
    setClick(true);
    setMID(mId);
    setSelectedType(type);
  };
  console.log(".....selectedType....", selectedType);

  const openSideNav = () => {
    setSide(true);
    // console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  // console.log(totalInvestment);
  // console.log(totalReturns);

  const [canceled, setCanceled] = useState(false);
  const [exit, setExit] = useState(false);
  const [exitInfo, setexitInfo] = useState({});
  const [cancelInfo, setcancelInfo] = useState({});
  const [cancelUcheck, setCancelUcheck] = useState(false);
  const [cancelMcheck, setCancelMcheck] = useState(false);
  const [exitMcheck, setExitMcheck] = useState(false);

  const handleConfirm = () => {
    alert("Confirmed!");
    // Add your logic for confirmation here
  };

  useEffect(() => {
    if (cancelResponse) {
      setCanceled(false)
      setExit(false)
    }
  }, [cancelResponse]);

  const cancelPop = (items) => {
    setCanceled(true);
    setcancelInfo(items);
  };

  const exitPop = (items) => {
    setExit(true);
    setexitInfo(items);
  };


  const runCancel = (qid, amount) => {
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    formData.append("amount", amount);
    formData.append("is_paired", 0);
    formData.append("is_unpaired", cancelUcheck ? 1 : 0);
    dispatch(cancelBet(formData));
  };

  const runExit = (qid, amount) => {
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    formData.append("amount", amount);
    formData.append("is_paired", exitMcheck ? 1 : 0);
    formData.append("is_unpaired", 0);
    dispatch(cancelBet(formData));
  };

  const closeInfo = (qid) => {
    setClick(true);
    setToQuest(true);
    setQuesCardId(id);
    const formData = new FormData();
    formData.append("question_id", qid);
    formData.append("user_id", id);
    dispatch(getPortDetails(formData));
  };

  const PortFolioClosedCards = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#00793c",
                  paddingTop: "1px",
                  backgroundColor: "#c5ffdd",
                }}
              >
                Matched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.matched?.question?.question_image == null ||
                items?.matched?.question?.question_image == undefined ||
                items?.matched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.matched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.matched?.question?.question_text} </p>
            </div>
            <>
              {items?.matched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  
                  </div>

                  {items?.matched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest}</div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.matched?.total_invested}
                        {parseInt(items?.matched?.total_returns) > 0 && (
                          <span>Gains ₹{items?.matched?.total_returns}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const PortFolioClosedCards2 = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Unmatched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.unmatched?.question?.question_image == null ||
                items?.unmatched?.question?.question_image == undefined ||
                items?.unmatched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.unmatched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.unmatched?.question?.question_text} </p>
            </div>
            <>
              {items?.unmatched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  </div>

                  {items?.unmatched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest} </div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.unmatched?.total_invested}
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        Unmatched qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexiting = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Exiting
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.exiting?.question?.question_image == null ||
                items?.exiting?.question?.question_image == undefined ||
                items?.exiting?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.exiting?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.exiting?.question?.question_text} </p>
            </div>
            <>
              {items?.exiting?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Selling Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  </div>

                  {items?.exiting?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest} </div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.exiting?.total_invested}
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        exiting qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexited = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Exited
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.exited?.question?.question_image == null ||
                items?.exited?.question?.question_image == undefined ||
                items?.exited?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.exited?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.exited?.question?.question_text} </p>
            </div>
            <>
              {items?.exited?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Selling Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  </div>

                  {items?.exited?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest} </div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.exited?.total_invested}
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        Exited qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsCancel = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div onClick={closeInfo} style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Canceled
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.canceled?.question?.question_image == null ||
                items?.canceled?.question?.question_image == undefined ||
                items?.canceled?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.canceled?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.canceled?.question?.question_text} </p>
            </div>
            <>
              {items?.canceled?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Result</div>
                    </div>
                  </div>

                  {items?.canceled?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest} </div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        <div className="rslt_blo rd">
                          {items?.publish_result}{" "}
                        </div>
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.canceled?.total_invested}
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        canceled qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div className="container auth__container">
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            {id !== "" && id !== undefined && id !== null ? (
              <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
          </div>

          <div style={{ zIndex: "1" }} className="reg-back fixed">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>My Portfolio</p>
          </div>

          <div
            style={{
              height: "50px",
              zIndex: "0",
              position: "sticky",
              top: "11%",
              borderBottom: "1px solid lightgrey",
            }}
            className="upper_tabs"
          >
            <Link to="/portfolio">
              <div className="close_eve">Live Events</div>
            </Link>
            <Link>
              <div className="live_eve">Closed Events</div>
            </Link>
          </div>

          <div style={{ backgroundColor: "lightgray", marginBottom: "10px" }}>
            <PortfCompos
              returns={totalBalanceResponse?.user?.balance_winning}
              invest={totalBalanceResponse?.investment}
            />
          </div>

          {click && !toQuest ? (
            <>
              <div
                onClick={() => setClick(false)}
                style={{
                  marginLeft: 10,
                  backgroundColor: "grey",
                  width: "7%",
                  height: 30,
                  borderRadius: 100,
                  marginTop: 5,
                  marginBottom: 5,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginTop: 7, color: "white" }}
                  icon={faArrowLeft}
                />
              </div>{" "}
            </>
          ) : (
            <></>
          )}

          <div
            style={{
              padding: 5,
              marginTop: -5,
            }}
          >
            {click == true ? (
              <div>
                {toQuest ? (
                  <>
                    <div
                      onClick={() => setToQuest(false)}
                      style={{
                        marginLeft: 10,
                        backgroundColor: "grey",
                        width: "7%",
                        height: 30,
                        borderRadius: 100,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ marginTop: 7, color: "white" }}
                        icon={faArrowLeft}
                      />
                    </div>
                    {portfolioDetails?.matched?.bets?.length > 0 ? (
                      <PortFolioClosedCards items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.unmatched?.bets?.length > 0 ? (
                      <PortFolioClosedCards2 items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.exiting?.bets?.length > 0 ? (
                      <PortFolioLiveCardsexiting items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.exited?.bets?.length > 0 ? (
                      <PortFolioLiveCardsexited items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.canceled?.bets?.length > 0 ? (
                      <PortFolioLiveCardsCancel items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {Array.isArray(ClosedEvents) &&
                      ClosedEvents?.map((items, ind) => {
                          if (
                            items?.match_id == mID &&
                            items?.type == selectedType
                          ) {
                            return (
                              (
                                <div
                                  key={ind}
                                  onClick={() => closeInfo(items?.question_id)}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Link to="/portfolioCancel">
                                    <div className="question_box_">
                                      <div className="trade_rule">
                                        <div className="trade_icon">
                                          <i className="fa-brands fa-sellsy"></i>
                                        </div>
                                        <hr />
                                        <p>{items?.total_trade} Trades</p>
                                        <div>
                                          {items?.question_status ==
                                            "Cancelled" && (
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                width: "90px",
                                                height: "20px",
                                                textAlign: "center",
                                                borderRadius: "50px",
                                                color: "#ff7300",
                                                paddingTop: "1px",
                                                backgroundColor: "#ffdeb3",
                                              }}
                                            >
                                              {items?.question_status}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="imgque">
                                        <div className="ques">
                                          <div className="quest_tex">
                                            {items?.question_text}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              columnGap: 5,
                                              marginTop: 10,
                                            }}
                                            className="descpt"
                                          >
                                            <img
                                              style={{ height: "25px" }}
                                              src={logo4}
                                              alt=""
                                            />
                                            <p className="shrt_desc">
                                              {items?.question_short_desc}{" "}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="quest_img">
                                          {items?.question_image == null ||
                                          items?.question_image == undefined ||
                                          items?.question_image == "" ? (
                                            <img
                                              style={{
                                                borderRadius: 10,
                                                backgroundColor: "grey",
                                              }}
                                              src={jersey}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                borderRadius: 10,
                                                backgroundColor: "lightgrey",
                                              }}
                                              src={produc(
                                                items?.question_image
                                              )}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )
                            );
                          } else if (
                            items?.market_id == mID &&
                            items?.type == selectedType
                          ) {
                            return (
                              console.log("....close.else if......"),
                              (
                                <div
                                  key={ind}
                                  onClick={() => closeInfo(items?.question_id)}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Link to="/portfolioCancel">
                                    <div className="question_box_">
                                      <div className="trade_rule">
                                        <div className="trade_icon">
                                          <i className="fa-brands fa-sellsy"></i>
                                        </div>
                                        <hr />
                                        <p>{items?.total_trade} Trades</p>
                                        <div>
                                          {items?.question_status ==
                                            "Cancelled" && (
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                width: "90px",
                                                height: "20px",
                                                textAlign: "center",
                                                borderRadius: "50px",
                                                color: "#ff7300",
                                                paddingTop: "1px",
                                                backgroundColor: "#ffdeb3",
                                              }}
                                            >
                                              {items?.question_status}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="imgque">
                                        <div className="ques">
                                          <div className="quest_tex">
                                            {items?.question_text}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              columnGap: 5,
                                              marginTop: 10,
                                            }}
                                            className="descpt"
                                          >
                                            <img
                                              style={{ height: "25px" }}
                                              src={logo4}
                                              alt=""
                                            />
                                            <p className="shrt_desc">
                                              {items?.question_short_desc}{" "}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="quest_img">
                                          {items?.question_image == null ||
                                          items?.question_image == undefined ||
                                          items?.question_image == "" ? (
                                            <img
                                              style={{
                                                borderRadius: 10,
                                                backgroundColor: "grey",
                                              }}
                                              src={jersey}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                borderRadius: 10,
                                                backgroundColor: "lightgrey",
                                              }}
                                              src={produc(
                                                items?.question_image
                                              )}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )
                            );
                          }
                      })}
                  </>
                )}
              </div>
            ) : (
              <div onClick={() => setClick(true)} style={{ marginBottom: 15 }}>
                {portfolioAllMatches?.length == 0 ? (
                  <>
                    <h4
                      style={{
                        textAlign: "center",
                        color: "grey",
                        marginTop: 70,
                      }}
                    >
                      No Records for closed..
                    </h4>
                  </>
                ) : (
                  <>
                    {Array.isArray(portfolioAllMatches) &&
                      portfolioAllMatches?.map((items, index) => {
                        let data = items?.extra_data;
                        return (
                          <React.Fragment Key={index}>
                            {items.type === "match" ? (
                              <div
                                onClick={() =>
                                  toQuestions(items?.id, items?.type)
                                }
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                className="upcoming_match_card"
                              >
                                <div></div>
                                <div className="up-headi">
                                  <div className="match_head">
                                    <p>{data.league_name}</p>
                                  </div>
                                </div>

                                <Link
                                // key={ind}
                                >
                                  <div className="match_team_details">
                                    <div className="team_one_details">
                                      <div className="team_one_content">
                                        <div className="team_one_short">
                                          <div className="team_one_logo">
                                            {data.team_1_flag === null ||
                                            data.team_1_flag == undefined ||
                                            data.team_1_flag == "" ? (
                                              <img src={logo4} alt="" />
                                            ) : (
                                              <img
                                                src={produc(data?.team_1_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_one_nme">
                                            {data?.team_1_abbr}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_1_name}{" "}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="fixture">
                                      <p style={{ fontWeight: "500" }}>
                                        <div
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            height: 20,
                                            width: 30,
                                            borderRadius: 10,
                                            paddingTop: 2.5,
                                            margin:'auto'
                                          }}
                                        >
                                          VS
                                        </div>
                                      </p>
                                    </div>

                                    <div className="team_two_details">
                                      <div className="team_two_content">
                                        <div className="team_two_short">
                                          <div className="team_two_logo">
                                            {data.team_2_flag == null ||
                                            data.team_2_flag == undefined ||
                                            data.team_2_flag == "" ? (
                                              <img src={logo4} alt="" />
                                            ) : (
                                              <img
                                                src={produc(data?.team_2_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_two_nme">
                                            {data?.team_2_abbr}{" "}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_2_name}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card_below_content">
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {data?.venue}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div
                                className="market_card"
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                onClick={() =>
                                  toQuestions(items?.id, items?.type)
                                }
                                key={index}
                              >
                                <div
                                  className="market_crd_img"
                                  style={{ backgroundColor: "white" }}
                                >
                                  {data?.market_banner == null ||
                                  data?.market_banner == undefined ||
                                  data?.market_banner == "" ? (
                                    <>
                                      <img
                                        src={banner}
                                        style={{ borderRadius: "5px" }}
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={produc(data?.market_banner)}
                                        alt="banner"
                                      />
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{ borderRadius: "0px" }}
                                  className="content_market_below"
                                >
                                  <div
                                    className="card_below_content"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {items?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer2 />
        </div>
      </div>
    </div>
  );
}
