import React from 'react'

import { Link, useParams } from 'react-router-dom'
import TopNav from './TopNav';
import Footer4 from '../Components/Footer4';


export default function Kyc() {
  return (


<div className="mobile__layout main">
        <div className="container auth__container fixed">


          
            
               

            
            <div className="reg-back">
            <Link to='/wallet'>

                <div className="reg__back">
                    <i className="fa-solid fa-arrow-left"></i>
                    
                </div>
                </Link>

                <p>KYC Verification</p>
            </div>

            <div className="pandetiels">
                <div className="conte_box">
                    <div className="left-co">
                        <p>Submit details as per your PAN card</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-address-card"></i>
                    </div>
                </div>
            </div>

            <div className="mid_content kyc">
                <div className="inn_kyc">
                    <p>Submit PAN Details</p>
                    <form>
                        <div className="mb-3">
                          <input type="text" className="form-control" placeholder="Name"/>
                        </div>
    
                        <div className="mb-3">
                            <input style={{width:"100%",borderRadius:"5px",marginBottom:"15px"}} type="text" className="form-control" placeholder="PAN number (10 digits)"/>
                        </div>
    
                        <div className="mb-3">
                            <input style={{width:"100%",borderRadius:"5px",marginBottom:"20px",paddingLeft:"10px"}} type="date" className="form-control" placeholder="Date of Birth"/>
                        </div>
                        
                        <button type="submit" className="btn submit_btn">Verify</button>
                    </form>
                </div>
            </div>





        <div className="Toastify">

        </div>
        
    </div>
    <div style={{position:"sticky",bottom:"0"}}>
        <Footer4/>

        </div>
    </div>
  )
}
