import React from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader} from 'react-spinners';

const GlobalLoader = () => {
    const loaderStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(128, 128, 128, 0.1)', // Gray background with very low opacity
        backdropFilter: 'blur(10px)', // Background blur effect
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };


    const { loadingPost } = useSelector((state) => state.Post);
    const { loadingGet } = useSelector((state) => state.Get);
    const { loadingAuth } = useSelector((state) => state.Auth);
  return (
    <>
    {loadingPost || loadingGet || loadingAuth ?
    <div style={loaderStyle}>
      <ClipLoader color={'#3498db'} loading={true} size={50} />
    </div>
    :null}
    </>
  );
};

export default GlobalLoader;
