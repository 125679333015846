import React, { useEffect, useState } from "react";
import social from "../Assets/Images/social-media.png";
import Footer3 from "../Components/Footer3";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getReferDetails } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ReactDOM from "react-dom";
import { produc } from "../Redux/Helper";
import user from "../Assets/Images/user.png";
import ipl from "../Assets/Images/ipl.jfif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faCopy,
  faShare,
  faShareNodes,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import SharingOptions from "./SharingOptions/ShareOptions";
import tel from "../Assets/Images/telegram.png";
import twit from "../Assets/Images/twitter.png";
import insta from "../Assets/Images/instagram.png";
import fb from "../Assets/Images/facebook.png";
import { getContact } from "../Redux/GetSlice";
import { getReferNearn } from "../Redux/GetSlice";
import { TelegramShareButton, WhatsappShareButton } from "react-share";

export default function ReferNEarn() {
  
  const dispatch = useDispatch();

  const uid = localStorage.getItem("user_id");

  const [id, setId] = useState(1);
  const [copy, setCopy] = useState(false);
  const [share, setShare] = useState(false);

  if (copy) {
    toast.success("Copied to clipboard");
    setCopy(false);
  }

  useEffect(()=>{
  dispatch(getContact());
  },[])

  useEffect(()=>{
    dispatch(getReferNearn());
    },[])

  const {contactUs} = useSelector((state)=>state.Get);
  const {pages} = useSelector((state)=>state.Get);


  const id1 = () => {
    setId(1);
  };
  const id2 = () => {
    setId(2);
  };
  const id3 = () => {
    setId(3);
  };

  let tempoURL = "https://luxury-brigadeiros-5b57ce.netlify.app/";

  useEffect(() => {
    dispatch(getReferDetails(uid));
  }, []);

  const redirectTo = (url) => {
    window.open(url, "_blank");
  };
  

 

  const SharePopup = ({ url, close }) => {
    const closeddd = () => {
      close(false);
    };

    return (
      <>
        <div>
          <div
            className="modal-contain"
            style={{
              height: "240px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              rowGap: "10px",
              margin: "auto",
              borderRadius: "10px",
              width: "350px",
              backgroundColor: "white",
              columnGap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                columnGap: "160px",
              }}
            >
              <h3 style={{}}>Share link Via</h3>
              <FontAwesomeIcon
                style={{
                  height: "25px",
                  color: "grey",
                  marginTop: "-5px",
                  cursor: "pointer",
                 
                }}
                onClick={closeddd}
                icon={faXmarkCircle}
              />
            </div>

            <SharingOptions url={url} />
            <div style={{display:"flex",marginTop:"-30px",marginBottom:"20px",justifyContent:"center",columnGap:"15px"}}>
             <h5>Facebook</h5>
             <h5>Twitter</h5>
             <h5>Whatsapp</h5>
             <h5>Telegram</h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "20px",
                alignItems: "center",
              }}
            >
              <h3 style={{ paddingLeft: "20px" }}>Or copy the link  </h3>
              <CopyToClipboard text={tempoURL} onCopy={() => setCopy(true)}>
                <button
                  style={{
                    border: "1px solid #007bff",
                    backgroundColor: "#007bff",
                    height: "28px",
                    width: "28px",
                    borderRadius: "6px",
                    cursor:"pointer"
                  }}
                >
                  <i style={{ color: "white" }} className="fa fa-clone"></i>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </>
    );
  };

  const { refers } = useSelector((state) => state.Get);
   const val = document.getElementById("textToCopy")?.value;

  return (
    <div>
      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div className="container auth__container fixed">
          <div style={{ position: "sticky", top: "0" }} className="reg-back">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Refer & Earn</p>
          </div>

          <div className="mid_content Transaction_history add_money">
            <div className="top_ern">
              

              <div className="terms_img">
                {pages?.referNearn?.banner_image == null ||
              pages?.referNearn?.banner_image  == undefined ||
              pages?.referNearn?.banner_image  == "" ? (
                  <img src={ipl} alt="" />
                ) : (
                  <img src={produc(pages?.referNearn?.banner_image )} alt="" />
                )}
              </div>

              <div style={{ marginTop: "10px" }} className="copy-text info">
                <input
                  id="textToCopy"
                  type="text"
                  readonly=""
                  className="text"
                  value={refers?.refer?.referral_code}
                />
                <CopyToClipboard text={val} onCopy={() => setCopy(true)}>
                  <button>
                    <i className="fa fa-clone"></i>
                  </button>
                </CopyToClipboard>
              </div>
              <div style={{ marginTop: "50px" }} className="inn_money">
                <div className="card_main">
                  <ul className="card_list">
                    <li >
                    <WhatsappShareButton style={{paddingTop:"10px"}}
        url={val}
       
        className="share-button linkedin-button"
      >

        
         whatsapp
      </WhatsappShareButton>
                      
                    </li>
                    <li >
                    <TelegramShareButton style={{paddingTop:"10px"}}
        url={val}
     
        className="share-button linkedin-button"
      >
             Telegram
      </TelegramShareButton>
                   
                    </li>
                    <li
                      style={{ width: "150px" }}
                      onClick={() => setShare(true)}
                    >
                      <i>
                        <FontAwesomeIcon icon={faShareNodes} />
                      </i>{" "}
                      Share Via
                    </li>

                   
                  </ul>
                </div>
              </div>
            </div>

            <div
              style={{
                height: "500px",
                position: "fixed",
                zIndex: "9",
                marginLeft: "10px",
              }}
            >
              {share && <SharePopup url={tempoURL} close={setShare} />}
            </div>

            <div className="top_tabe">
              <section className="section">
                <div className="containe">
                  <div className="tab">
                    <div className="tab__list">
                      <div
                        onClick={id1}
                        style={{
                          borderBottom: id === 1 ? "1px solid black" : "",
                          color: id === 1 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        How it works
                      </div>
                      <div
                        onClick={id2}
                        style={{
                          borderBottom: id === 2 ? "1px solid black" : "",
                          color: id === 2 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        My Referrals
                      </div>
                      <div
                        onClick={id3}
                        style={{
                          borderBottom: id === 3 ? "1px solid black" : "",
                          color: id === 3 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        My Earnings
                      </div>
                    </div>

                    <div>
                      {id === 1 ? (
                        <div className="tab__content">
                          <div className="tab__content-item">
                            <div className="main_transi">
                              <div className="total">
                              <div  dangerouslySetInnerHTML={{
                    __html: pages?.referNearn?.html_content,
                  }}>
                
              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : id === 2 ? (
                        <div className="tab__content-item">
                          <div className="main_transi">
                            {Array.isArray(refers?.refer?.user_wise_earning) &&
                              refers?.refer?.user_wise_earning?.map((items) => {
                                return (
                                  <div className="items_listtype">
                                    <div className="icon">
                                      <img src={user} alt="" />
                                    </div>
                                    <p className="descr">{items?.firstname} </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <div className="tab__content-item">
                          <div className="main_transi Withdraw">
                            <div className="main_transi">
                              <div
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "15px",
                                  marginBottom: "20px",
                                }}
                              >
                                Total earnings - ₹{refers?.refer?.total_earning}
                              </div>

                              {Array.isArray(
                                refers?.refer?.user_wise_earning
                              ) &&
                                refers?.refer?.user_wise_earning?.map(
                                  (items) => {
                                    return (
                                      <div className="catego">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                         
                                          <div className="amt">
                                            <p>
                                              <i className="fa-solid fa-indian-rupee-sign"></i>
                                              <span>
                                                {items?.referral_amount}{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="Toastify"></div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}
