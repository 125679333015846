import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer4() {
  return (
    <div>
         <div className="footer">
                <div className="portfolio_content">
                    <Link to='/'>
                    <i className="fa-solid fa-house"></i>
                        <p>Home</p>
                    </Link>
                       

                </div>
                <div className="portfolio_content">
                    <Link to='/portfolio' >
                    <i className="fa-solid fa-briefcase"></i>
                        <p>Portfolio</p>
                    </Link>
                       
                </div>
                <div className="portfolio_content">
                    <Link to='/wallet'>
                    <i className="fa-solid fa-user"></i>
                        <p>Profile</p>
                    </Link>
                       
                </div>

                <div className="home_content">
                    <Link to='/more'>
                    <i className="fa-solid fa-ellipsis"></i>
                        <p>More</p>
                    </Link>
                       
                </div>
            </div>
    </div>
  )
}
