import React, { useEffect } from 'react'
import Footer4 from '../Components/Footer4'
import TopNav from './TopNav'
import tnc from '../Assets/Images/termsconditions.jpg';
import tel from '../Assets/Images/telegram.png';
import insta from '../Assets/Images/instagram.png';
import chat from '../Assets/Images/custm.png';
import { Link } from 'react-router-dom';
import { getContact, getHelpNsupport } from '../Redux/GetSlice';
import { useDispatch, useSelector } from "react-redux";
import { produc } from '../Redux/Helper';



export default function ContactUs() {

    const rediercturl = (url) =>{
    window.open(url, '_blank','noopener,noreferrer');
    }


const dispatch = useDispatch();

const {contactUs} = useSelector((state)=>state?.Get);

useEffect(()=>{
    dispatch(getContact());

},[])

useEffect(()=>{
    dispatch(getHelpNsupport())
   },[])

   const {pages} = useSelector((state)=>state.Get);


  return (
    <div>
         <div className="mobile__layout main">
        <div className="container auth__container">
        {/* <div style={{position:"sticky",top:"0"}}>
            <TopNav/>
           </div> */}

            <div style={{position:"sticky",top:"0"}} className="reg-back">
                <div className="reg__back">
                    <Link to='/more'>
                    <i className="fa-solid fa-arrow-left"></i>
                    
                    </Link>
                </div>
                <p>Contact Us</p>
            </div>

            <div className="mid_content">

                <div className="terms_img">
                {pages?.helpAndSupport?.banner_image == null || undefined || "" ?  <img src={tnc} alt=""/> :<img src={produc(pages?.helpAndSupport?.banner_image)} alt=''/>}
                    
                </div>

                <div className="terms_content">
                    <h5>You May Reach Us On</h5>
                    <div className="summary_contact">
                        <div className="contect_box">
                            <img src={insta} alt=""/>
                            <div style={{cursor:"pointer",paddingLeft:7}} onClick={()=> rediercturl(contactUs?.settings?.instagram_url || 'https://www.instagram.com/opinion365_app?igsh=OXZ6cnBjcm5oOTYx')} >Instagram</div>
                        </div>
                        
                        <div className="contect_box">
                            <img src={tel} alt=""/>
                            <div style={{cursor:"pointer",paddingLeft:7}} onClick={()=> rediercturl(contactUs?.settings?.telegram_url || 'https://t.me/proboapp_prediction')}>Telegram</div>
                        </div>

                        <div className="chat_box">
                            <button  style={{cursor:"pointer"}} onClick={()=>rediercturl(contactUs?.settings?.live_chat_url || 'https://app.crisp.chat/website/75008ad5-f78c-4225-aa49-58be1b983c5e/inbox/')} >Live Chat<img src={chat} alt=""/></button>
                        </div>
                    </div>
                </div>



            </div>

           


        </div>
        <div style={{position:"sticky",bottom:"0"}}>
        <Footer4/>

        </div>
        <div className="Toastify">

        </div>
    </div>
    </div>
  )
}
