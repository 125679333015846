import React, { useEffect } from "react";
import tel from "../Assets/Images/telegram.png";
import twit from "../Assets/Images/twitter.png";
import insta from "../Assets/Images/instagram.png";
import fb from "../Assets/Images/facebook.png";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { userBalance } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../Redux/GetSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function SideNav({ closesidenavbar }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    toast.success("Logged out successfully");
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    dispatch(getContact());
  }, []);

  const { Balance } = useSelector((state) => state.Get);
  const { contactUs } = useSelector((state) => state?.Get);

  const redirectLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div style={{ zIndex: "2" }} className="po_nave">
        <div className="container auth__container profile_popup">
          <div style={{ cursor: "pointer" }} className="reg-back">
            <div className="reg__back">
              <button onClick={closesidenavbar}>
                <i className="fa-solid fa-arrow-left"></i>
              </button>
            </div>
            <p>My Profile</p>
          </div>

          <section className="blnce_sec">
            <div className="blnce_">
              <div className="total_bln">
                <div className="icon_wallet">
                  <i className="fa-solid fa-wallet"></i>
                </div>
                <div className="blnce_amnt">
                  <p>Total Balance</p>
                  <p className="amnt_b">₹ {Balance} </p>
                </div>
              </div>
              <Link to="/addmoney">
                <div style={{ width: "70px" }} className="add_csh_btn">
                  Add Cash
                </div>
              </Link>
            </div>
          </section>

          <section className="categ">
            <div className="content_box_cat lw">
              <Link to="/refer">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div style={{ height: "28px" }} className="cat_icon">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Refer &amp; Earn</div>
                    <div className="new_indic">New</div>
                  </div>
                </div>
              </Link>

              <hr />
              <Link to="/verifyAcc">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div className="cat_icon">
                    <i className="fa-solid fa-certificate"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Verify Account</div>
                  </div>
                </div>
              </Link>
            </div>

            {/* <div className="content_box_cat lw">
                                <div style={{display:"flex",height:"50px",justifyContent:"flex-start",alignItems:"center",columnGap:"10px"}} className="catego">
                                        <div className="cat_icon"><i className="fa-solid fa-turn-up"></i></div>
                                        <div className="content">
                                            <div className="contnt_text_">
                                                Level Up Now
                                            </div>
            
                                        </div>
                                </div>
                            </div> */}

            <div className="content_box_cat lw">
              <Link to="/aboutus">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div className="cat_icon">
                    <i>
                      <FontAwesomeIcon icon={faInfo} />
                    </i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">About Us</div>
                  </div>
                </div>
              </Link>

              <hr />
              <Link to="/helpNsupport">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div className="cat_icon">
                    <i className="fa-solid fa-handshake-angle"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Help &amp; Support</div>
                  </div>
                </div>
              </Link>

              <hr />
              <Link to="/htp">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div className="cat_icon">
                    <i className="fa-solid fa-question"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">How to Play</div>
                  </div>
                </div>
              </Link>

              <hr />
              <Link to="/settings">
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="catego"
                >
                  <div className="cat_icon">
                    <i className="fa-solid fa-gear"></i>
                  </div>
                  <div className="content">
                    <div className="contnt_text_">Settings</div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              onClick={logOut}
              style={{ cursor: "pointer" }}
              className="content_box_cat log_out_"
            >
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  columnGap: "10px",
                }}
                className="catego"
              >
                <div className="cat_icon">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </div>
                <div className="content">
                  <div className="contnt_text_">Log out</div>
                </div>
              </div>
            </div>
          </section>

          <section className="logo__btm">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => redirectLink(contactUs?.settings?.facebook_url|| 'https://www.facebook.com/profile.php?id=100094106919428&mibextid=ZbWKwL')}
              className="logo_img_"
            >
              <img src={fb} alt="" />
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => redirectLink(contactUs?.settings?.instagram_url || 'https://www.instagram.com/opinion365_app?igsh=OXZ6cnBjcm5oOTYx')}
              className="logo_img_"
            >
              <img src={insta} alt="" />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => redirectLink(contactUs?.settings?.telegram_url || 'https://t.me/proboapp_prediction')}
              className="logo_img_"
            >
              <img src={tel} alt="" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
