import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bgpic from "./../Assets/Images/hero-img-reg.jpg";
import promo from "./../Assets/Images/promo-logo.png";
import OtpInput from "react-otp-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Firebase/Setup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { regauth } from "../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import TopNavWOL from "../Screens/TopNavWOL";
import { logRestonull } from "../Redux/AuthSlice";
import Loader from "../Components/Loader";
import { checkAccount, reg_otp, register, varify_reg_otp } from "../Service/apiService";

export default function Register() {
  const [userNum, setUserNum] = useState("");
  const [rotp, setRotp] = useState("");

  const [otp, setOtp] = useState(false);
  const [errors, seterrors] = useState(" ");
  const [fberror, setFberror] = useState("");
  const [sec, setSec] = useState(35);
  const [time, setTime] = useState(false);
  const [conResult, setConResult] = useState({});
  const [msg, setMsg] = useState("");
  const [referral, setReferral] = useState("");
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const [hideSms, setHideSms] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);

  const dispatch = useDispatch();

  // const regres = localStorage.getItem("isRegistered");
  const { regResponse } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(logRestonull());
  }, []);


  const onSignInSubmit = async () => {
    if (userNum === "" || userNum.trim().length === 0) {
      // console.log("..........1st...........");
      setOtp(false);
      seterrors("Phone number is required");

      // console.log(otp);
    }
    setIsloading(true);
    setInputDisable(true);
    checkAccount({ phone: userNum })
      .then(async (res) => {
        setIsloading(false);
        if (res?.data?.is_account_exist == false) {
          if (errors === "") {
            RegOtp()
          }
        }
        else if(res?.data?.is_account_exist == true && res?.data?.account_status == false){
          toast.error("Account deactivated. Please contact the admin.");
        }
        else {
          setInputDisable(false);
          toast.error("Account Already Exist, Please Login");
          navigate("/login");
        }
      })
      .catch((err) => {
        setIsloading(false);
        setInputDisable(false);
        console.log("..........err........", err);
      });
  };

  const RegOtp = () =>{
    setLoading(true);
    reg_otp({ mobile: userNum }).then((res)=>{
      console.error('.......res.......',res);
      setLoading(false);
      if(res?.status == true){
        toast.success(res?.message);
        setOtp(true);
        setTime(true);
        setSec(35);
      }else{
        toast.error('Something went wrong. Try again later');
      }
      setInputDisable(false);
    }).catch((error)=>{
      console.error('.......error.......',error);
      toast.error('Something went wrong. Try again later');
     setInputDisable(false);
     setLoading(false);
    })
  }

  const resendOtp = async () => {
    RegOtp()
  };

  const validation = (num) => {
    const regExp = new RegExp("^[6-9][0-9]{9}$");
    if (num === "" || num.length === 0) {
      seterrors("Phone number is required");
      // console.log(errors);
      // console.log("----1r------");
    } else if (!regExp.test(num.trim())) {
      seterrors("Invalid number");
      // console.log(errors);
      // console.log("----2r------");
    } else {
      seterrors("");
      // console.log("----3r------");
    }
  };

  if (time && sec > 0) {
    setTimeout(() => {
      setSec(sec - 1);
    }, 1000);
  }

  // const spec = new RegExp("[!@#%^&*()_+-=[]{}|;':",./<>?~`]")

  const handleChange = (e) => {
    let a = e.target.value;
    validation(a);
    setUserNum(a);

    if (a.trim().length === 11) {
      setUserNum(userNum);
    }
  };
  const navigate = useNavigate();


  const tokenSet = async (data) => {

    setLoading(true);


    const formData = new FormData();
    formData.append("phone", userNum);
    formData.append("referral_code", referral);

    try {
      varify_reg_otp({ mobile: userNum , otp:rotp}).then((res)=>{
        console.error('.......res.......',res);
        if(res?.status == true){
          register({phone:userNum,referral_code:referral}).then((res)=>{
            console.log('........res......',res)
            if(res?.status == 'success'){
              toast.success(res?.message)
              navigate("/login");
            }else{
              toast.error('Something went wrong. Try again later');
            }
          }).catch((err)=>{
            console.log('........err......',err)
            toast.error('Something went wrong. Try again later');
          })
        }else{
          toast.error(res?.messages?.otp);
        }
        setLoading(false);
      }).catch((error)=>{
        setLoading(false);
      })

    } catch (error) {
      console.error(error);
      // setFberror("Please verify the reCAPTCHA");
      setLoading(false);
    }
  };

  // if (regres == "newUser") {
  //   toast.success("Registered successfully");

  //   navigate("/login");
  //   //  window.location.reload();
  // } else if (regres == "oldUser") {
  //   toast.success("Already registered, Please login");
  //   navigate("/login");
  // }

  // if (regres === "false") {
  //   toast.error("Something went wrong! Please try again");
  //   //  window.location.reload();
  // }

  return (
    <div>
       <Loader loading={isloading}/>
      <div style={{ minHeight: "100vh" }} className="mobile__layout">
        <div className="container auth__container before-reg">
          {/* <form className="style_auth__form__3wv2I" > */}
          <div style={{ position: "sticky", top: "0", zIndex: 10 }}>
            <TopNavWOL />
          </div>

          <div className="reg-back">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Registration</p>
          </div>

          <div className="container">
            <div className="hero_sec_reg">
              <img src={bgpic} alt="" />
            </div>
          </div>

          <div className="promo__box">
            <div className="inputWithIcon">
              <input
                onChange={(e) => setReferral(e.target.value)}
                value={referral}
                type="text"
                placeholder="Promo code (If you have any)"
              />
              <div className="promo_logo">
                <img src={promo} alt="" />
              </div>
            </div>
          </div>

          <div className="sect_mob">
            <div className="mobile_num_input">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    //   justifyContent: "space-between",
                    alignItems: "center",
                    height: "32px",
                    width: "80px",
                    border: "1px solid lightgrey",
                    marginTop: "8px",
                    borderRadius: "3px",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png"
                    style={{
                      height: "20px",
                      width: "28px",
                      padding: "1px 0px 1px 5px",
                    }}
                    alt=""
                  />
                  <p style={{ padding: "0px 0px 0px 7px" }}>+91</p>
                </div>
                <input
                  maxLength={10}
                  style={{
                    width: "390px",
                    height: "32px",
                    marginTop: "8px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                  }}
                  value={userNum}
                  disabled={inputDisable}
                  onChange={handleChange}
                  type="number"
                  placeholder="Phone number"
                />
              </div>

              <h5 style={{ fontWeight: "500", color: "red" }}>{errors}</h5>

              <p
                style={{ marginTop: "30px", marginBottom: "20px" }}
                className="code_sent"
              >
                We will send you a code to the above phone number. Please enter
                the code after you receive it
              </p>


              {otp ? (
                <>
                  <div className="mobile__layout">
                    <div
                      style={{ minHeight: "60vh" }}
                      className="container auth__container"
                    >
                      {/* <form className="style_auth__form__3wv2I"> */}
                      <div className="style_form__1P9Yy">
                        <div></div>
                        <div className="style_title__large__NPREl">
                          Verify phone
                        </div>
    
                        <div className="style_form__fields__5NhNp">
                          <div className="form__group">
                            <div className="form__field__input__wrapper">
                              <div className="form__field__input">
                                {/* <form action="#"> */}
                                <div className="input-field">
                                  <OtpInput
                                    value={rotp}
                                    isInputNum ={true}
                                    onChange={setRotp}
                                    numInputs={6}
                                    containerStyle={{ marginLeft: "35px" }}
                                    renderSeparator={<span></span>}
                                    inputStyle={{
                                      width: "35px",
                                      marginRight: "10px",
                                    }}
                                    renderInput={(props) => (
                                      <input {...props}  type="number"/>
                                    )}
                                  />
                                </div>
                                <h5 style={{ color: "red", fontWeight: "400" }}>
                                  {fberror}
                                </h5>
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="style_form__tnc__2ZWri">
                          {sec === 0 ? (
                            <>
                              <button
                                style={{
                                  border: "1px solid #e8eeff",
                                  backgroundColor: "#e8eeff",
                                  fontSize: "15px",
                                  color: "blue",
                                }}
                                onClick={resendOtp}
                              >
                                Resend OTP
                              </button>
                            </>
                          ) : (
                            <>
                              Resend OTP in{" "}
                              <span className="style_link__1wrGI style_otp__link__3zNu1">
                                {sec} s
                              </span>
                            </>
                          )}
                        </div>
                        <div></div>
                      </div>

                      <div
                        style={{ marginTop: "-50px" }}
                        className="style_form__actions__1s5C4 style_form__actions__otp__2_1mn"
                      >
                        {loading === true ? (
                          <button disabled="true" className="btn btn__primary">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              Registering...{" "}
                            </div>
                          </button>
                        ) : (
                          <button
                            onClick={()=>tokenSet()}
                            className="btn btn__primary"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              Verify{" "}
                            </div>
                          </button>
                        )}
                      </div>
                      {/* </form> */}
                    </div>
                    <div className="Toastify"></div>
                  </div>
                </>
              ) : null}

              {otp == true || hideSms == true ? (
                <></>
              ) : (
                <>
                  {" "}
                  <button
                    onClick={onSignInSubmit}
                    className="btn btn__primary"
                    style={{ transform: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Send SMS{" "}
                    </div>
                  </button>
                </>
              )}

            </div>
          </div>

          <div className="style_form__tnc__2ZWri style_form__tnc__login__36I5q">
            By continuing, you accept that you are 18+ years of age &amp; agree
            to the{" "}
            <Link to="/tnc">
              <span className="style_link__1wrGI">Terms and Conditions</span>
            </Link>
          </div>
          {/* </form> */}
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
