import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import banner from "../Assets/Images/hero_image.avif";
import cardimg from "../Assets/Images/chipcardimage.jpg";
import fav1 from "../Assets/Images/fav1.png";
import pinimg from "../Assets/Images/pin-outlined.png";
import ipl from "../Assets/Images/ipl.jfif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import jersey from "../Assets/Images/jerseypng.png";
import { cancelExitRes, setPinStatusToFalse } from "../Redux/PostSlice";
import fav from "../Assets/Images/fav1.png";
import marketImg from "../Assets/Images/marketImg.png";
import { io } from "socket.io-client";
import logo4 from "../Assets/Images/fav1.png";
import { getPortDetails } from "../Redux/PostSlice";

import MarketCard from "../Components/MarketCard";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import {
  getPortfolioMatches,
  liveMatch,
  market,
  upcoming,
} from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { category } from "../Redux/GetSlice";
import Footer from "../Components/Footer";
import TopNavWOL from "./TopNavWOL";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import moment from "moment";
import pinnedd from "../Assets/Images/filledPin.png";
import toast from "react-hot-toast";
import { current } from "@reduxjs/toolkit";
import { getBanner } from "../Redux/GetSlice";
import { getPinnedMatches } from "../Redux/GetSlice";
import { betRes, cancelBet, pinMatch, portfolio } from "../Redux/PostSlice";
import CountdownTimer from "./Countdown/Countdown";
import Categories from "./Categories";
import { settingLoadto0 } from "../Redux/PostSlice";
import { panLoadto0 } from "../Redux/PostSlice";
import { bankLoadto0 } from "../Redux/PostSlice";
import './../Auth/style.css'


const socket = io.connect(RT_BASE_URL);

export default function Home() {
  const { upcomingMatch } = useSelector((state) => state.Get);
  const { liveMatches } = useSelector((state) => state.Get);
  const { pinnedMatches } = useSelector((state) => state.Get);
  const { portFolios } = useSelector((state) => state.Post);
  const { markets } = useSelector((state) => state.Get);
  const { banners } = useSelector((state) => state.Get);
  const { categories } = useSelector((state) => state.Get);
  const { pinStatus } = useSelector((state) => state.Post);
  const { portfolioMatches } = useSelector((state) => state.Get);

  const [portfolioAllMatches, setportfolioAllMatches] = useState([]);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(0);
  const [active, setActive] = useState(0);
  const [checking, setChecking] = useState("");
  const [upMatch, setUpMatch] = useState([{}]);
  const [liveMatchs, setLiveMatchs] = useState([{}]);
  const [marketMatch, setMarketMatch] = useState([{}]);


  useEffect(() => {
    if(portfolioMatches?.length>0){
      setportfolioAllMatches(
        portfolioMatches?.map((item) => {
          return { ...item, extra_data: item?.extra_data? JSON.parse(item?.extra_data) :{}};
        })
      );
    }  else{
      setportfolioAllMatches([])
    }
  }, [JSON.stringify(portfolioMatches)]);

  useEffect(() => {
    setUpMatch(upcomingMatch);
  }, [upcomingMatch]);

  useEffect(() => {
    setLiveMatchs(liveMatches);
  }, [liveMatches]);

  useEffect(() => {
    setMarketMatch(markets);
  }, [markets]);

  useEffect(() => {
    socket.on("newMatchPublished", (data) => {
      dispatch(upcoming());
      dispatch(liveMatch());
      dispatch(market());
    });
  }, []);

  useEffect(() => {
    socket.on("matchUpdated", (data) => {
      dispatch(upcoming());
      dispatch(liveMatch());
      dispatch(market());
    });
  }, []);

  const user_id = localStorage.getItem("user_id");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(betRes());
    dispatch(cancelExitRes());
    // console.log("changing betRes to false-------------");
  }, []);

  let time = setTimeout(() => {
    setLoad(load + 1);
  }, 1000);

  if (load === 15) {
    clearTimeout(time);
  }

  useEffect(() => {
    dispatch(upcoming());
  }, [dispatch]);

  useEffect(() => {
    if (user_id !== undefined || user_id !== null) {
      dispatch(getPinnedMatches(user_id));
    }
  }, []);

  useEffect(() => {
    dispatch(settingLoadto0());
  }, []);

  useEffect(() => {
    dispatch(panLoadto0());
  }, []);

  useEffect(() => {
    dispatch(bankLoadto0());
  }, []);

  useEffect(() => {
    let data = {
      id: user_id,
      status: "Live",
    };
    dispatch(getPortfolioMatches(data));
  }, [dispatch]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (upcomingMatch != undefined || upcomingMatch != []) {
  //     let data = [];
  //     for (let i = 0; i <= upcomingMatch?.length; i++) {
  //       upcomingMatch[i].pinned = false;
  //       data.push(upcomingMatch[i]);
  //       console.log("====================================");
  //       console.log(upcomingMatch[i]);
  //       console.log("====================================");
  //     }
  //   }
  // }, [upcomingMatch]);

  useEffect(() => {
    dispatch(category());
  }, [dispatch]);

  useEffect(() => {
    dispatch(liveMatch());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBanner());
  }, [dispatch]);

  // let uppin = upcomingMatch.map((items)=>{
  //     items.pinned = "false";
  //  })

  // +91 98742 59359

  // console.log("upcomingMatch--------------", upcomingMatch);

  const isMatchIdPresentInArrayB = (matchId) => {
    return pinnedMatches?.some((objB) => objB.match_id === matchId);
  };

  if (pinStatus) {
    dispatch(getPinnedMatches(user_id));
    dispatch(setPinStatusToFalse());
  }

  const pincard = (indd) => {
    // isMatchIdPresentInArrayB(indd)?

    const formData = new FormData();
    formData.append("match_id", indd);
    formData.append("user_id", user_id);
    formData.append("action", isMatchIdPresentInArrayB(indd) ? 0 : 1);

    if (user_id) {
      dispatch(pinMatch(formData));
    } else {
      toast.error("Login to pin this match");
    }

    // dispatch(getPinnedMatches(user_id));

    setCount(count + 1);
  };

  const myMatch = () => {
    setId(5);
    setActive(0);
  };

  useEffect(() => {
    dispatch(market());
  }, []);

  const activeChange = (ind, catId) => {
    setActive(ind);
    if (ind == 0) {
      setId(1);
    } else {
      setId(4);
    }
    if (catId >= 2) {
      setId(4);
    }
  };

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    arrows: false,
  };

  // const pinarr = JSON.parse(localStorage.getItem("pinnedArr"));

  const [idmq, setIdmq] = useState(1);

  const [id, setId] = useState(1);
  const [chf, setChf] = useState(1);

  const setUp = () => {
    setId(1);
    setActive(0);
  };
  const setLive = () => {
    setId(2);
    setActive(0);
  };
  const setMymat = () => {
    setId(4);
  };
  const setMar = () => {
    setId(3);
    setActive(100);
  };

  // const pinArr = localStorage.getItem("newIdArr");
  //  console.log(pinArr,"pin==-=-===-=-=-=-=-=");

  const [side, setSide] = useState(false);
  const openCloseSideNav = () => {
    if (side === true) {
      setSide(false);
    } else {
      setSide(true);
      console.log("Open");
    }
  };

  const closeSideNav = () => {
    setSide(false);
  };

  const redirectBanner = (url) => {
    window.open(url, "_blank");
  };

  // console.log(arr, "pin arrays");

  const ShowArr = ({ items, count }) => {
    return (
      <div
        style={{
          height: "50px",
        }}
        className="chip_card first"
      >
        <Link
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          to={
            items?.match_type == "Upcoming"
              ? `/matchdetquestion/${items?.match_id}`
              : `/matchdetquestionlive/${items?.match_id}`
          }
        >
          <div style={{ marginRight: "10px" }} className="chip_logo">
            <img src={fav1} alt="" />
          </div>
          <div className="team_dtls">
            <span className="team_one_chip">{items?.team_1_abbr} </span>
            <span
              style={{ marginLeft: "5px", marginRight: "5px" }}
              className="vs"
            >
              vs
            </span>
            <span className="team_two_chip">{items?.team_2_abbr} </span>
          </div>
          <span style={{ marginLeft: "10px" }} className="live_indicator">
            {items?.match_type}
          </span>
        </Link>
      </div>
    );
  };

  // console.log(banners, "banners+++++++++++++++++++++++++");

  return (
    <>
      {side ? (
        <div
          style={{
            zIndex: "1",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            display: "block",
            overflow: "hidden",
          }}
        >
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </div>
      ) : (
        <></>
      )}
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            minHeight: "100vh",
            overflowY: side === true ? "hidden" : "",
            display: "block",
          }}
          className="mobile__layout main"
        >
          <div className="container auth__container">
            <div
              style={{ position: "sticky", top: "0", zIndex: "1", left: "0" }}
            >
              {user_id !== null && user_id !== undefined && user_id !== "" ? (
                <div>
                  <TopNav onOpen={openCloseSideNav} />
                </div>
              ) : (
                <>
                  <TopNavWOL />
                </>
              )}
            </div>

            <div
              style={{ display: "flex", marginBottom: "10px" }}
              className="tabs_up"
            >
              {Array.isArray(categories) &&
                categories.map((items, index) => {
                  if (items?.category_id === "1") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          columnGap: "10px",
                        }}
                        key={index}
                      >
                        <div
                          className={
                            active === index ? "tab_active" : "tab_inactive"
                          }
                          onClick={() =>
                            activeChange(index, items?.category_id)
                          }
                        >
                          {items?.category_image ==
                          (null || undefined || "") ? (
                            <img style={{ width: "25px" ,height:'25px'}} src={fav} alt="" />
                          ) : (
                            <img src={produc(items?.category_image)} alt="" style={{ width: "25px" ,height:'25px'}}/>
                          )}

                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {items?.category_name}
                          </Link>
                        </div>
                      </div>
                    );
                  }
                })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "10px",
                }}
              >
                <div
                  className={id === 3 ? "tab_active" : "tab_inactive"}
                  onClick={setMar}
                >
                  <img src={marketImg} alt="" style={{ width: "25px" ,height:'25px'}}/>

                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Markets
                  </Link>
                </div>
              </div>

              {Array.isArray(categories) &&
                categories.map((items, index) => {
                  if (items?.category_id >= 2) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          columnGap: "10px",
                        }}
                        key={index}
                      >
                        <div
                          className={
                            active === index ? "tab_active" : "tab_inactive"
                          }
                          onClick={() => activeChange(index)}
                        >
                          {items?.category_image ==
                          (null || undefined || "") ? (
                            <img style={{ width: "25px" ,height:'25px'}} src={fav} alt="" />
                          ) : (
                            <img src={produc(items?.category_image)} alt="" style={{ width: "25px" ,height:'25px'}}/>
                          )}
                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {items?.category_name}
                          </Link>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div style={{ borderRadius: "8px", width: "100%", zIndex: "0" }}>
              <Swiper
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                infinite={true}
                navigation={true}
                modules={[Autoplay]}
              >
                {banners?.map((items, index) => {
                  return (
                    <SwiperSlide>
                      <div key={index} style={{ height: "150px " }}>
                        <img
                          onClick={() => redirectBanner(items?.banner_url)}
                          style={{
                            width: "93%",
                            height: "150px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          src={produc(items?.banner_image)}
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
                {banners?.length === 0 ? (
                  <SwiperSlide>
                    <img
                      style={{
                        width: "93%",
                        height: "150px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      src={banner}
                      alt=""
                    />
                  </SwiperSlide>
                ) : null}
              </Swiper>
            </div>

            {/* <div className="hero_img">
              <img src={banner} alt="" />
            </div> */}

            <div className="live_data_row">
              <div onClick={setUp} style={{ cursor: "pointer" }}>
                <span
                  className={
                    id === 1
                      ? "upco data_row_active"
                      : "live_mtch data_row_inactive"
                  }
                >
                  Upcoming
                </span>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={setLive}
                className={
                  id === 2
                    ? "upco data_row_active ab"
                    : "live_mtch data_row_inactive"
                }
              >
                Live matches
                <span className="live_dot"></span>
              </div>

              <div
                onClick={myMatch}
                style={{ cursor: "pointer" }}
                className={
                  id === 5 ? "mark data_row_active" : "mark data_row_inactive"
                }
              >
                My matches
              </div>
            </div>

            <div className="chip_card_row">
              {!user_id ? (
                <></>
              ) : pinnedMatches?.length === 1 ? (
                <>
                  <ShowArr items={pinnedMatches?.[0]} count={count} />
                </>
              ) : pinnedMatches?.length >= 2 ? (
                <>
                  <ShowArr items={pinnedMatches?.[0]} count={count} />
                  <ShowArr items={pinnedMatches?.[1]} count={count} />
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="live_mtch_">
              <div className="left">
                <span className="lve">Live</span>
                <span
                  style={{
                    marginLeft: 5,
                    border: "1px solid lightgrey",
                    marginBottom: 5,
                  }}
                  className="nmb"
                >
                  {liveMatchs.length}{" "}
                </span>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={setLive}
                className="view_all"
              >
                <span style={{ marginRight: 5 }} className="view_a">
                  View all
                </span>
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>

            <div className="upcoming_matches">
              <div className="title-bef-crd">
                <h3>
                  {id === 1
                    ? "Upcoming Matches"
                    : id === 2
                    ? "Live Matches"
                    : id === 3
                    ? "Markets"
                    : id === 5
                    ? "My matches"
                    : "Others"}
                </h3>
              </div>
            </div>

            {id === 1 ? (
              <>
                <div></div>
                <div style={{ marginBottom: 15 }}>
                  {Array.isArray(upMatch) &&
                    upMatch?.map((items, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "97%",
                            margin: "auto",
                            marginTop: "10px",
                          }}
                          className="upcoming_match_card"
                        >
                          <div></div>
                          <div className="up-headi">
                            <div className="match_head">
                              <p>{items.league_name}</p>
                            </div>
                            {pinnedMatches ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => pincard(items?.match_id)}
                                className="pin_img"
                              >
                                <img
                                  src={
                                    isMatchIdPresentInArrayB(items?.match_id)
                                      ? pinnedd
                                      : pinimg
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <Link
                            // key={ind}
                            to={`/matchdetquestion/${items?.match_id}`}
                          >
                            <div className="match_team_details">
                              <div className="team_one_details">
                                <div className="team_one_content">
                                  <div className="team_one_short">
                                    <div className="team_one_logo">
                                      {items.team_1_flag === null ||
                                      items.team_1_flag == undefined ||
                                      items.team_1_flag == "" ? (
                                        <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                      ) : (
                                        <img
                                          src={produc(items?.team_1_flag)}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <p className="team_one_nme">
                                      {items?.team_1_abbr}
                                    </p>
                                  </div>
                                  <p className="tem_nme">
                                    {items?.team_1_name}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="fixture">
                                <p style={{ fontWeight: "500",margin:'auto'}}>
                                  {moment(items?.match_date).format(
                                    "Do MMM YY"
                                  )}
                                  <br />
                                  {moment(items?.match_date).format("h:mm a")}
                                  <br />
                                  <div style={{ color: "red" }}>
                                    <CountdownTimer
                                      targetDate={items?.match_date}
                                    />
                                  </div>
                                </p>
                              </div>

                              <div className="team_two_details">
                                <div className="team_two_content">
                                  <div className="team_two_short">
                                    <div className="team_two_logo">
                                      {items.team_2_flag == null ||
                                      items.team_2_flag == undefined ||
                                      items.team_2_flag == "" ? (
                                        <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                      ) : (
                                        <img
                                          src={produc(items?.team_2_flag)}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <p className="team_two_nme">
                                      {items?.team_2_abbr}{" "}
                                    </p>
                                  </div>
                                  <p className="tem_nme">
                                    {items?.team_2_name}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="card_below_content">
                              <div className="below_content">
                                <span className="percent-details">
                                  {items?.venue}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : id === 2 ? (
              <>
                <div style={{ marginBottom: 15 }}>
                  {Array.isArray(liveMatchs) &&
                    liveMatchs?.map((items, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              width: "97%",
                              margin: "auto",
                              marginTop: "10px",
                            }}
                            className="upcoming_match_card"
                          >
                            <div className="up-headi">
                              <div className="match_head">
                              <p>{items.league_name}</p>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => pincard(items?.match_id)}
                                className="pin_img"
                              >
                                <img
                                  src={
                                    isMatchIdPresentInArrayB(items?.match_id)
                                      ? pinnedd
                                      : pinimg
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <Link
                              to={`/matchdetquestionlive/${items?.match_id}`}
                            >
                              <div
                                onClick={() => setIdmq(items?.match_id)}
                                className="match_team_details"
                              >
                                <div className="team_one_details">
                                  <div className="team_one_content">
                                    <div className="team_one_short">
                                      <div className="team_one_logo">
                                        {items.team_1_flag == null ||
                                        items.team_1_flag == undefined ||
                                        items.team_1_flag == "" ? (
                                          <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                        ) : (
                                          <img
                                            src={produc(items?.team_1_flag)}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <p className="team_one_nme">
                                        {items?.team_1_abbr}
                                      </p>
                                    </div>
                                    <p className="tem_nme">
                                      {items?.team_1_name}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="fixture" style={{margin:'auto'}}>
                                  {/* <p style={{fontWeight:"500"}}>
                              {moment(items.match_date).format("h:mm:ss a")}
          
                              </p> */}
                                  <div
                                    style={{
                                      height: "20px",
                                      width: "45px",
                                      border: "1px solid red",
                                      borderRadius: "3px",
                                      backgroundColor: "red",
                                      color: "white",
                                      fontSize: "13px",
                                      paddingTop: "1px",
                                      margin:'auto'
                                    }}
                                  >
                                    Live
                                  </div>
                                </div>

                                <div className="team_two_details">
                                  <div className="team_two_content">
                                    <div className="team_two_short">
                                      <div className="team_two_logo">
                                        {items.team_2_flag == null ||
                                        items.team_2_flag == undefined ||
                                        items.team_2_flag == "" ? (
                                          <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                        ) : (
                                          <img
                                            src={produc(items?.team_2_flag)}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <p className="team_two_nme">
                                        {items?.team_2_abbr}{" "}
                                      </p>
                                    </div>
                                    <p className="tem_nme">
                                      {items?.team_2_name}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="card_below_content">
                                <div className="below_content">
                                  <span className="percent-details">
                                    {items?.venue}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : id === 3 ? (
              <>
                <section className="market_cards_stack">
                  {Array.isArray(marketMatch) &&
                    marketMatch.map((items, index) => {
                      return (
                        <div className="market_card" key={index}>
                          <div className="market_crd_img" style={{backgroundColor:'white'}}>
                            {items?.market_banner == null ||
                            items?.market_banner == undefined ||
                            items?.market_banner == "" ? (
                              <>
                                <img
                                  src={banner}
                                  style={{ borderRadius: "5px" }}
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={produc(items?.market_banner)}
                                  alt="banner"
                                />
                              </>
                            )}
                          </div>
                          <div
                            style={{ borderRadius: "0px" }}
                            className="content_market_below"
                          >
                            <h6>{items?.market_name}</h6>
                            <Link to={`/markets/${items?.market_id}`}>
                              <button
                                style={{
                                  height: "30px",
                                  width: "80px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                  border: "1px solid grey",
                                  backgroundColor: "#424242",
                                  color: "white",
                                }}
                              >
                                Click here
                              </button>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </section>
              </>
            ) : id === 4 ? (
              <div>
                <Categories />
              </div>
            ) : (
              <div style={{ marginBottom: 10 }}>
                {portfolioAllMatches?.length == 0 ? (
                  <>
                    <h4
                      style={{
                        textAlign: "center",
                        color: "grey",
                        marginTop: 50,
                      }}
                    >
                      No Bets placed yet..
                    </h4>
                  </>
                ) : (
                  <>
                    {Array.isArray(portfolioAllMatches) &&
                      portfolioAllMatches?.map((items, index) => {
                        let data = items?.extra_data;
                        return (
                          <React.Fragment Key={index}>
                            {items.type === "match" ? (
                              <div
                                onClick={() => navigate("/portfolio")}
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                className="upcoming_match_card"
                              >
                                <div></div>
                                <div className="up-headi">
                                  <div className="match_head">
                                  <p>{data?.league_name}</p>
                                  </div>
                                </div>

                                <Link
                                // key={ind}
                                >
                                  <div className="match_team_details">
                                    <div className="team_one_details">
                                      <div className="team_one_content">
                                        <div className="team_one_short">
                                          <div className="team_one_logo">
                                            {data.team_1_flag === null ||
                                            data.team_1_flag == undefined ||
                                            data.team_1_flag == "" ? (
                                              <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                            ) : (
                                              <img
                                                src={produc(data?.team_1_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_one_nme">
                                            {data?.team_1_abbr}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_1_name}{" "}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="fixture" style={{margin:'auto'}}>
                                      <p style={{ fontWeight: "500" }}>
                                        <div
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            height: 20,
                                            width: 30,
                                            borderRadius: 10,
                                            paddingTop: 2.5,
                                            margin:'auto'
                                          }}
                                        >
                                          VS
                                        </div>
                                      </p>
                                    </div>

                                    <div className="team_two_details">
                                      <div className="team_two_content">
                                        <div className="team_two_short">
                                          <div className="team_two_logo">
                                            {data.team_2_flag == null ||
                                            data.team_2_flag == undefined ||
                                            data.team_2_flag == "" ? (
                                              <img src={fav1} alt="" style={{objectFit:'contain'}}/>
                                            ) : (
                                              <img
                                                src={produc(data?.team_2_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_two_nme">
                                            {data?.team_2_abbr}{" "}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_2_name}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card_below_content">
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {data?.venue}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div
                                className="market_card"
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                key={index}
                              >
                                <div className="market_crd_img" style={{backgroundColor:'white'}}>
                                  {data?.market_banner == null ||
                                  data?.market_banner == undefined ||
                                  data?.market_banner == "" ? (
                                    <>
                                      <img
                                        src={banner}
                                        style={{ borderRadius: "5px" }}
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={produc(data?.market_banner)}
                                        alt="banner"
                                      />
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{ borderRadius: "0px" }}
                                  className="content_market_below"
                                >
                                  <div
                                    className="card_below_content"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {items?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="Toastify"></div>
        </div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer />
        </div>
      </div>
    </>
  );
}
