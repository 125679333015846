import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import offer from "../Assets/Images/offer.png";
import bell from "../Assets/Images/bellicon.png";
import TopNav from "./TopNav";
import { getNotify } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { readNotifications } from "../Redux/PostSlice";

export default function Notify() {
  const dispatch = useDispatch();

  const useid = localStorage.getItem("user_id");

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", useid);
    dispatch(getNotify(useid));
    setTimeout(() => {
        dispatch(readNotifications(formData));
    }, 60000);
  }, []);

  const { Notifies } = useSelector((state) => state.Get);

  const [notificationData,setNotificationData] = useState(Notifies?.data?.notifications)

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          {/* <div style={{position:"sticky",top:"0"}}>
            <TopNav/>
           </div> */}

          <div
            style={{ position: "sticky", top: "0" }}
            className="reg-back fixed"
          >
            <div className="reg__back">
              <Link to="/">
                {" "}
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>Notifications</p>
          </div>

          <div className="mid_content">
            {notificationData?.length < 1 ? (
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "grey",
                  paddingTop: "20px",
                }}
              >
                No notifications yet
              </div>
            ) : (
              <></>
            )}

            {Array.isArray(notificationData) &&
              notificationData?.map((items,index) => {
                  return (
                    <div
                    key={index}
                      style={{
                        marginBottom: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="conte_box notifi"
                    >
                      <div className="left-co">
                        <div style={{ width: 30 }} className="icon_s notif">
                          <img
                            style={{ height: 25, width: 25 }}
                            src={bell}
                            alt=""
                          />
                        </div>
                        <div style={{ width: "85%" }} className="notif_cont">
                          <h4>{items?.notification_title} </h4>
                          <p style={{ marginTop: 5 }}>
                            {items?.notification_desc}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: 100,
                          alignSelf: "baseline",
                          textAlign: "end",
                        }}
                        className="right-co tme"
                      >
                        <span>
                          {" "}
                          {moment(items?.created_at).format("llll")}{" "}
                        </span>
                      </div>
                    </div>
                  );
              })}
          </div>
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
