import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PortfCompos from "../Components/PortfCompos";
import TopNav from "./TopNav";
import jersey from "../Assets/Images/jersey.jfif";
import Footer from "../Components/Footer";
import { cancelExitBet, portfolio } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";

import Footer2 from "../Components/Footer2";
import SideNav from "./SideNav";
import { cancelBet } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import TopNavWOL from "./TopNavWOL";
import { getClosedEvents } from "../Redux/GetSlice";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import { userBalance } from "../Redux/GetSlice";
import { exitBet } from "../Redux/PostSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/Images/hero_image.avif";
import {
  faArrowLeft,
  faL,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo4 from "../Assets/Images/fav1.png";
import { io } from "socket.io-client";
import { getPortDetails } from "../Redux/PostSlice";
import PLiveMatches from "./PLiveMatches";
import { getPortfolioMatches } from "../Redux/GetSlice";
import './../Auth/style.css'

const socket = io.connect(RT_BASE_URL);

export default function PLive() {
  const { portFolios } = useSelector((state) => state.Post);
  const { portfolioDetails } = useSelector((state) => state.Post);
  const { portfolioMatches } = useSelector((state) => state.Get);

  const [portfolioAllMatches, setportfolioAllMatches] = useState([]);
  const [click, setClick] = useState(false);
  const [quests, setQuests] = useState([{}]);
  const [range, setRange] = useState(5);
  const [exit_quantity, setExit_quantity] = useState('');
  const [toQuest, setToQuest] = useState(false);
  const [mID, setMID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const params = useParams();

  useEffect(() => {
    if (portfolioMatches?.length > 0) {
      setportfolioAllMatches(
        portfolioMatches?.map((item) => {
          return {
            ...item,
            extra_data: item?.extra_data ? JSON.parse(item?.extra_data) : {},
          };
        })
      );
    }
    else {
      setportfolioAllMatches([])
    }
  }, [JSON.stringify(portfolioMatches)]);

  useEffect(() => {
    setQuests(portFolios);
  }, []);

  useEffect(() => {
    socket.on("questionUpdated", (data) => {
      let socketData = JSON.parse(data);
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(portfolio(formData));
    });
  }, []);

  const dispatch = useDispatch();

  const id = localStorage.getItem("user_id");

  const formData = new FormData();
  formData.append("user_id", id);

  useEffect(() => {
    dispatch(portfolio(formData));
  }, []);

  useEffect(() => {
    let data = {
      id: id,
      status: "Live",
    };
    dispatch(getPortfolioMatches(data));
  }, []);

  const toQuestions = (mId, type) => {
    setClick(true);
    setMID(mId);
    setSelectedType(type);
  };

  const userID = localStorage.getItem("user_id");

  const [side, setSide] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [quesCardId, setQuesCardId] = useState(1);

  const openSideNav = () => {
    setSide(true);
    // console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  const { cancelResponse } = useSelector((state) => state.Post);

  const [canceled, setCanceled] = useState(false);
  const [canceleExit, setCancelExit] = useState(false);
  const [exit, setExit] = useState(false);
  const [exitInfo, setexitInfo] = useState({});
  const [cancelInfo, setcancelInfo] = useState({});
  const [cancelUcheck, setCancelUcheck] = useState(null);
  const [orderID, setOrderID] = useState("");

  const [cancelMcheck, setCancelMcheck] = useState(null);
  const [exitMcheck, setExitMcheck] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    if (cancelResponse) {
      setCanceled(false)
      setCancelExit(false)
      setExit(false)
    }
  }, [cancelResponse]);

  const handleConfirm = () => {
    alert("Confirmed!");
    // Add your logic for confirmation here
  };

  const chngevalminus = () => {
    if (range !== 0.5) {
      setRange(parseFloat(range) - 0.5);
    }
  };
  const chngevalplus = () => {
    if (range !== 9.5) {
      setRange(parseFloat(range) + 0.5);
    }
  };

  const cancelPop = () => {
    setCanceled(true);
  };
  const cancelExitingPop = () => {
    setCancelExit(true);
  };

  const rangeChange = (event) => {
    setRange(parseFloat(event.target.value));
  };

  const exitPop = (items) => {
    setExit(true);
    setexitInfo(items);
  };



  const betInfos = (id) => {
    setToQuest(true);
    setClick(true);
    setQuesCardId(id);
    const formData = new FormData();
    formData.append("question_id", id);
    formData.append("user_id", userID);
    dispatch(getPortDetails(formData));
  };

  const runCancel = () => {
    const formData = new FormData();
    formData.append("order_id", orderID);
    dispatch(cancelBet(formData));
  };
  const runCancelExit = () => {
    console.log('.....exit order_id....',orderID);
    const formData = new FormData();
    formData.append("order_id", orderID);
    dispatch(cancelExitBet(formData));
  };

  const runExit = () => {
    const formData = new FormData();
    formData.append("order_id", orderID);
    formData.append("qty_exit_requested", exit_quantity);
    formData.append("amount", range);
    dispatch(exitBet(formData));
  };


  const CancelPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };
    return (
      <div
        className="modal-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: "10px",
          margin: "auto",
          borderRadius: "15px",
          width: "350px",
          backgroundColor: "white",
          columnGap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            columnGap: "160px",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <FontAwesomeIcon
            style={{
              height: "25px",
              color: "grey",
              marginTop: "15px",
              marginLeft: "300px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
            onClick={closeddd}
            icon={faXmarkCircle}
          />
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              fontWeight: 600,
              marginBottom: 15,
            }}
          >
            <div>Check</div>
            <div>Buy price</div>
            <div>Invest</div>
            <div>Quantity</div>
          </div>

          <div
            style={{
              overflow:
                portfolioDetails?.unmatched?.bets?.length >= 5
                  ? "scroll"
                  : "hidden",
              height: 150,
            }}
          >
            {items?.unmatched?.bets?.map((elems, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    columnGap: 25,
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  <input
                    checked={ind == cancelUcheck ? true : false}
                    onChange={() => {
                      setCancelUcheck(ind);
                      setOrderID(elems?.order_id);
                      console.log(orderID, "orderID-----------=======inside");
                    }}
                    style={{ border: "2px solid black" }}
                    type="checkbox"
                  />
                  <div>₹{elems?.buy_price} </div>
                  <div>₹{elems?.invest} </div>
                  <div>{elems?.quantity} </div>
                </div>
              );
            })}
          </div>
        </div>

        <button
          onClick={() => runCancel()}
          disabled={orderID == "" ? true : false}
          style={{
            marginTop: "10px",
            marginBottom: 20,
            width: "90%",
            height: "35px",
            border: "1px solid black",
            borderRadius: "6px",
            textAlign: "center",
            paddingTop: "2px",
            fontSize: "20px",
            marginLeft: "20px",
            backgroundColor: "black",
            color: "white",
            cursor: "pointer",
            letterSpacing: "2px",
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  const CancelExitPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };
    return (
      <div
        className="modal-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: "10px",
          margin: "auto",
          borderRadius: "15px",
          width: "350px",
          backgroundColor: "white",
          columnGap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            columnGap: "160px",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <FontAwesomeIcon
            style={{
              height: "25px",
              color: "grey",
              marginTop: "15px",
              marginLeft: "300px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
            onClick={closeddd}
            icon={faXmarkCircle}
          />
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              fontWeight: 600,
              marginBottom: 15,
            }}
          >
            <div>Check</div>
            <div>Buy price</div>
            <div>Invest</div>
            <div>Quantity</div>
          </div>

          <div
            style={{
              overflow:
                portfolioDetails?.exiting?.bets?.length >= 5
                  ? "scroll"
                  : "hidden",
              height: 150,
            }}
          >
            { portfolioDetails?.exiting?.bets?.map((elems, ind) => {
              return (
                <div
                key={ind}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    columnGap: 25,
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  <input
                    checked={ind == cancelUcheck ? true : false}
                    onChange={() => {
                      setCancelUcheck(ind);
                      setOrderID(elems?.order_id);
                      console.log(orderID, "orderID-----------=======inside");
                    }}
                    style={{ border: "2px solid black" }}
                    type="checkbox"
                  />
                  <div>₹{elems?.buy_price} </div>
                  <div>₹{elems?.invest} </div>
                  <div>{elems?.quantity} </div>
                </div>
              );
            })}
          </div>
        </div>

        <button
          onClick={() => runCancelExit()}
          disabled={orderID == "" ? true : false}
          style={{
            marginTop: "10px",
            marginBottom: 20,
            width: "90%",
            height: "35px",
            border: "1px solid black",
            borderRadius: "6px",
            textAlign: "center",
            paddingTop: "2px",
            fontSize: "20px",
            marginLeft: "20px",
            backgroundColor: "black",
            color: "white",
            cursor: "pointer",
            letterSpacing: "2px",
          }}
        >
          Cancel Exit Request
        </button>
      </div>
    );
  };

  const ExitPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };
    return (
      <div
        className="modal-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: "10px",
          margin: "auto",
          borderRadius: "15px",
          width: "350px",
          backgroundColor: "white",
          columnGap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            columnGap: "160px",
          }}
        >
          <FontAwesomeIcon
            style={{
              height: "25px",
              color: "grey",
              marginLeft: "300px",
              cursor: "pointer",
              marginTop: "15px",
              marginBottom: "10px",
            }}
            onClick={closeddd}
            icon={faXmarkCircle}
          />
        </div>


        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              fontWeight: 600,
              marginBottom: 15,
            }}
          >
            <div>Check</div>
            <div>Buy price</div>
            <div>Invest</div>
            <div>Quantity</div>
          </div>

          <div
            style={{
              overflow:
                portfolioDetails?.matched?.bets?.length >= 5
                  ? "scroll"
                  : "hidden",
              height: 150,
            }}
          >
            {items?.matched?.bets?.map((elems, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    columnGap: 25,
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  <input
                    checked={ind == exitMcheck ? true : false}
                    onChange={() => {
                      setExitMcheck(ind);
                      setOrderID(elems?.order_id);
                      setRange(elems?.buy_price);
                      setExit_quantity(elems?.quantity);
                      console.log(orderID, "orderID-----------=======inside");
                    }}
                    style={{ border: "2px solid black" }}
                    type="checkbox"
                  />
                  <div>₹{elems?.buy_price} </div>
                  <div>₹{elems?.invest} </div>
                  <div>{elems?.quantity} </div>
                </div>
              );
            })}
          </div>

          {exitMcheck != null ?
          <div style={{ marginLeft: 15 }} >

            <div style={{ display: "flex", columnGap: 25 }} >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 10 }} >
                <div onClick={chngevalminus} className="minus_icon">
                  <i className="fa-solid fa-minus"></i>
                </div>
                <input
                  style={{ width: 200 }}

                  id="slider"
                  min={0.5}
                  max={9.5}
                  step={0.5}
                  value={parseFloat(range)}
                  onChange={(e) => {
                    setRange(parseFloat(e.target.value))
                  }}
                  type="range"
                />
                <div onClick={chngevalplus} className="plus_icon">
                  <i className="fa-solid fa-plus"></i>
                </div>

              </div>



              <h4>
                {parseFloat(range)}
              </h4>

            </div>
          </div>
          : null
        }

          {exitMcheck != null ?
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                fontWeight: 600,
                marginBottom: 15,
              }}>
              <div>Exiting Amount</div>
              <div>
                {(range) * parseInt(exit_quantity)}
              </div>
            </div>
            : null}
        </div>
        <button
          onClick={() => runExit()}
          // disabled={exitMcheck ? false : true}
          style={{
            marginTop: "0px",
            width: "90%",
            height: "35px",
            border: "1px solid black",
            borderRadius: "6px",
            textAlign: "center",
            paddingTop: "2px",
            fontSize: "20px",
            marginLeft: "20px",
            backgroundColor: "black",
            color: "white",
            cursor: "pointer",
            letterSpacing: "2px",
            marginBottom: 20,
          }}
        >
          Exit
        </button>
      </div>
    );
  };

  const PortFolioLiveCards = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div 
     onClick={betInfos}
        style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#00793c",
                  paddingTop: "1px",
                  backgroundColor: "#c5ffdd",
                }}
              >
                Matched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.matched?.question?.question_image == null ||
                  items?.matched?.question?.question_image == undefined ||
                  items?.matched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.matched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.matched?.question?.question_text} </p>
            </div>
            <>
              {items?.matched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.matched?.bets?.map((items, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{items?.choose} </div>
                        <div className="rslt_blo">₹{items?.invest}</div>
                        <div className="rslt_blo">₹{items?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {items?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">Option 2</div>
                          <div className="rslt_blo">₹999</div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.matched?.total_invested}
                        {/* <span>
                            {items?.bet_status == "Unmatched"
                              ? ""
                              : "Gains ₹" + items?.gains}{" "}
                          </span> */}
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={() => exitPop(items)}
                        style={{
                          height: "27px",
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        Exit
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const PortFolioLiveCards2 = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div 
     onClick={betInfos}
        style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Unmatched
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.unmatched?.question?.question_image == null ||
                  items?.unmatched?.question?.question_image == undefined ||
                  items?.unmatched?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.unmatched?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.unmatched?.question?.question_text} </p>
            </div>
            <>
              {items?.unmatched?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.unmatched?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo">₹{elems?.invest} </div>
                        <div className="rslt_blo">₹{elems?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.unmatched?.total_invested}
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={() => cancelPop()}
                        style={{
                          height: "27px",
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                  <div>
                    {items?.total_unmatched > 0 ? (
                      <div style={{ fontSize: "13px", color: "#ff7300" }}>
                        Unmatched qty : {items?.total_unmatched}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexiting = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div 
        // onClick={betInfos}
         style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Existing
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.exiting?.question?.question_image == null ||
                  items?.exiting?.question?.question_image == undefined ||
                  items?.exiting?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.exiting?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.exiting?.question?.question_text} </p>
            </div>
            <>
              {items?.exiting?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Selling Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.exiting?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo">₹{elems?.invest} </div>
                        <div className="rslt_blo">₹{elems?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.exiting?.total_invested}
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => cancelExitingPop()}
                        style={{
                          height: "27px",
                          width: "70px",
                          backgroundColor: "grey",
                          cursor: "pointer",
                          color: "white",
                          borderRadius: "4px",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsexited = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div 
     onClick={betInfos}
        style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Exited
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.exited?.question?.question_image == null ||
                  items?.exited?.question?.question_image == undefined ||
                  items?.exited?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.exited?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.exited?.question?.question_text} </p>
            </div>
            <>
              {items?.exited?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Selling Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.exited?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo">₹{elems?.invest} </div>
                        <div className="rslt_blo">₹{elems?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.exited?.total_invested}
                      </div>
                    </div>

                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const PortFolioLiveCardsCancel = ({ items }) => {
    return (
      <div style={{ marginTop: 0 }}>
        <div 
     onClick={betInfos}
        style={{ marginBottom: "10px" }}>
          <div
            style={{
              width: "97%",
              borderRadius: "5px",
              margin: "auto",
            }}
            className="rslt__crd"
          >
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  fontSize: "13px",
                  width: "90px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "#ff7300",
                  paddingTop: "1px",
                  backgroundColor: "#ffdeb3",
                }}
              >
                Cancel
              </div>
            </div>
            <div className="top__dt">
              <div className="plcehlder">
                {items?.canceled?.question?.question_image == null ||
                  items?.canceled?.question?.question_image == undefined ||
                  items?.canceled?.question?.question_image == "" ? (
                  <img src={jersey} alt="img" />
                ) : (
                  <img
                    src={produc(items?.canceled?.question?.question_image)}
                    alt=""
                  />
                )}
              </div>
              <p>{items?.canceled?.question?.question_text} </p>
            </div>
            <>
              {items?.canceled?.bets?.length == 0 ? (
                <h5 style={{ paddingTop: 10, textAlign: "center" }}>
                  No Records
                </h5>
              ) : (
                <div className="fl-det">
                  <div className="lower_dtls">
                    <div className="box">
                      <div className="hd_blo">Choose</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Invest</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Buy Price</div>
                    </div>
                    <div className="box">
                      <div className="hd_blo">Quantity</div>
                    </div>
                    {/* <div className="box">
                        <div className="hd_blo">Result</div>
                      </div>
                      <div className="box">
                        <div className="hd_blo">Returns</div>
                      </div> */}
                  </div>

                  {items?.canceled?.bets?.map((elems, index) => {
                    return (
                      <div key={index} className="lower_dtls">
                        <div className="rslt_blo">{elems?.choose} </div>
                        <div className="rslt_blo">₹{elems?.invest} </div>
                        <div className="rslt_blo">₹{elems?.buy_price} </div>
                        <div style={{ marginTop: "5px" }} className="rslt_blo">
                          {elems?.quantity}
                        </div>
                        {/* <div className="rslt_blo rd">{items?.publish_result} </div>
                          <div className="rslt_blo">₹{items?.returns} </div> */}
                      </div>
                    );
                  })}

                  <div className="lw_cnc">
                    <div className="unmatched_qty">
                      <div className="icon_">
                        <i className="fa-solid fa-money-check"></i>
                      </div>
                      <div className="context_">
                        Invested ₹{items?.canceled?.total_invested}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div className="container auth__container">
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            {id !== "" && id !== undefined && id !== null ? (
              <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >
              {canceled && (
                <CancelPopup items={portfolioDetails} close={setCanceled} />
              )}
            </div>
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >
              {exit && <ExitPopup items={portfolioDetails} close={setExit} />}
            </div>
            <div
              style={{
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)'
              }}
            >
              {canceleExit && <CancelExitPopup items={portfolioDetails} close={setCancelExit} />}
            </div>
          </div>

          <div style={{ zIndex: "1" }} className="reg-back fixed">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>My Portfolio</p>
          </div>

          <div
            style={{
              height: "50px",
              zIndex: "0",
              position: "sticky",
              top: "11%",
              borderBottom: "1px solid lightgrey",
            }}
            className="upper_tabs"
          >
            <Link>
              <div className="live_eve">Live Events</div>
            </Link>
            <Link to="/portfolioCancel">
              <div className="close_eve">Closed Events</div>
            </Link>
          </div>

          {/* {click === true ? (
            <div style={{ backgroundColor: "lightgray", marginBottom: "10px" }}>
              <PortfCompos
                returns={totalBalanceResponse?.user?.balance_winning}
                invest={totalBalanceResponse?.investment}
              />
            </div>
          ) : (
            <></>
          )} */}

          {click && !toQuest ? (
            <>
              <div
                onClick={() => setClick(false)}
                style={{
                  marginLeft: 10,
                  backgroundColor: "grey",
                  width: "7%",
                  height: 30,
                  borderRadius: 100,
                  marginTop: 5,
                  marginBottom: 5,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginTop: 7, color: "white" }}
                  icon={faArrowLeft}
                />
              </div>{" "}
            </>
          ) : (
            <></>
          )}

          <div>
            {click == true ? (
              <div style={{}}>
                {toQuest ? (
                  <>
                    <div
                      onClick={() => setToQuest(false)}
                      style={{
                        marginLeft: 10,
                        backgroundColor: "grey",
                        width: "7%",
                        height: 30,
                        borderRadius: 100,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ marginTop: 7, color: "white" }}
                        icon={faArrowLeft}
                      />
                    </div>
                    {portfolioDetails?.matched?.bets?.length > 0 ? (
                      <PortFolioLiveCards items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.unmatched?.bets?.length > 0 ? (
                      <PortFolioLiveCards2 items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.exiting?.bets?.length > 0 ? (
                      <PortFolioLiveCardsexiting items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.exited?.bets?.length > 0 ? (
                      <PortFolioLiveCardsexited items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                    {portfolioDetails?.canceled?.bets?.length > 0 ? (
                      <PortFolioLiveCardsCancel items={portfolioDetails} />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <div style={{ marginTop: -10 }}>
                    {Array.isArray(portFolios) &&
                      portFolios?.map((items, ind) => {
                        if (
                          items?.match_id == mID &&
                          items?.type == selectedType
                        ) {
                          return (
                            <div
                              onClick={() => betInfos(items?.question_id)}
                              key={ind}
                              style={{ padding: 10, marginBottom: -20 }}
                            >
                              <Link to="/portfolio">
                                <div className="question_box_">
                                  <div className="trade_rule">
                                    <div className="trade_icon">
                                      <i className="fa-brands fa-sellsy"></i>
                                    </div>
                                    <hr />
                                    <p>{items?.total_trade} Trades</p>
                                  </div>
                                  <div className="imgque">
                                    <div className="ques">
                                      <div className="quest_tex">
                                        {items?.question_text}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: 5,
                                          marginTop: 10,
                                        }}
                                        className="descpt"
                                      >
                                        <img
                                          style={{ height: "25px" }}
                                          src={logo4}
                                          alt=""
                                        />
                                        <p className="shrt_desc">
                                          {items?.question_short_desc}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="quest_img">
                                      {items?.question_image == null ||
                                        items?.question_image == undefined ||
                                        items?.question_image == "" ? (
                                        <img
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "grey",
                                          }}
                                          src={jersey}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "lightgrey",
                                          }}
                                          src={produc(items?.question_image)}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                        else if (items?.market_id == mID &&
                          items?.type == selectedType) {
                          return (
                            <div
                              onClick={() => betInfos(items?.question_id)}
                              key={ind}
                              style={{ padding: 10, marginBottom: -20 }}
                            >
                              <Link to="/portfolio">
                                <div className="question_box_">
                                  <div className="trade_rule">
                                    <div className="trade_icon">
                                      <i className="fa-brands fa-sellsy"></i>
                                    </div>
                                    <hr />
                                    <p>{items?.total_trade} Trades</p>
                                  </div>
                                  <div className="imgque">
                                    <div className="ques">
                                      <div className="quest_tex">
                                        {items?.question_text}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: 5,
                                          marginTop: 10,
                                        }}
                                        className="descpt"
                                      >
                                        <img
                                          style={{ height: "25px" }}
                                          src={logo4}
                                          alt=""
                                        />
                                        <p className="shrt_desc">
                                          {items?.question_short_desc}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="quest_img">
                                      {items?.question_image == null ||
                                        items?.question_image == undefined ||
                                        items?.question_image == "" ? (
                                        <img
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "grey",
                                          }}
                                          src={jersey}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "lightgrey",
                                          }}
                                          src={produc(items?.question_image)}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ marginBottom: 10 }}>
                {portfolioAllMatches?.length == 0 ? (
                  <>
                    <h4
                      style={{
                        textAlign: "center",
                        color: "grey",
                        marginTop: 100,
                      }}
                    >
                      No Bets placed yet..
                    </h4>
                  </>
                ) : (
                  <>
                    {Array.isArray(portfolioAllMatches) &&
                      portfolioAllMatches?.map((items, index) => {
                        let data = items?.extra_data;
                        return (
                          <React.Fragment Key={index}>
                            {items.type === "match" ? (
                              <div
                                onClick={() =>
                                  toQuestions(items?.id, items.type)
                                }
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                className="upcoming_match_card"
                              >
                                <div></div>
                                <div className="up-headi">
                                  <div className="match_head">
                                    <p>{data.league_name}</p>
                                  </div>
                                </div>

                                <Link
                                // key={ind}
                                >
                                  <div className="match_team_details">
                                    <div className="team_one_details">
                                      <div className="team_one_content">
                                        <div className="team_one_short">
                                          <div className="team_one_logo">
                                            {data.team_1_flag === null ||
                                              data.team_1_flag == undefined ||
                                              data.team_1_flag == "" ? (
                                              <img src={logo4} alt="" />
                                            ) : (
                                              <img
                                                src={produc(data?.team_1_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_one_nme">
                                            {data?.team_1_abbr}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_1_name}{" "}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="fixture">
                                      <p style={{ fontWeight: "500" }}>
                                        <div
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            height: 20,
                                            width: 30,
                                            borderRadius: 10,
                                            paddingTop: 2.5,
                                            margin:'auto'
                                          }}
                                        >
                                          VS
                                        </div>
                                      </p>
                                    </div>

                                    <div className="team_two_details">
                                      <div className="team_two_content">
                                        <div className="team_two_short">
                                          <div className="team_two_logo">
                                            {data.team_2_flag == null ||
                                              data.team_2_flag == undefined ||
                                              data.team_2_flag == "" ? (
                                              <img src={logo4} alt="" />
                                            ) : (
                                              <img
                                                src={produc(data?.team_2_flag)}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <p className="team_two_nme">
                                            {data?.team_2_abbr}{" "}
                                          </p>
                                        </div>
                                        <p className="tem_nme">
                                          {data?.team_2_name}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card_below_content">
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {data?.venue}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div
                                className="market_card"
                                onClick={() =>
                                  toQuestions(items?.id, items.type)
                                }
                                style={{
                                  width: "97%",
                                  margin: "auto",
                                  marginTop: "10px",
                                }}
                                key={index}
                              >
                                <div className="market_crd_img" style={{ backgroundColor: 'white' }}>
                                  {data?.market_banner == null ||
                                    data?.market_banner == undefined ||
                                    data?.market_banner == "" ? (
                                    <>
                                      <img
                                        src={banner}
                                        style={{ borderRadius: "5px" }}
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={produc(data?.market_banner)}
                                        alt="banner"
                                      />
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{ borderRadius: "0px" }}
                                  className="content_market_below"
                                >
                                  <div
                                    className="card_below_content"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="below_content">
                                      <span className="percent-details">
                                        {items?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer2 />
        </div>
      </div>
    </div>
  );
}
