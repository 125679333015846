import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addMoney,
  rechargeStatus,
  setRechargeResponse,
  setRechargeStatusResponse,
} from "../Redux/PostSlice";
import { recharge } from "../Redux/PostSlice";
import { createOrder } from "../Redux/PostSlice";
import { userBalance } from "../Redux/GetSlice";
import { Navigate } from "react-router-dom";
import { TextField } from "@mui/material";
import QRCode from "react-qr-code";
import GPay from "./../Assets/Images/gpay.png";
import Paytm from "./../Assets/Images/paytm.png";
import PhonePe from "./../Assets/Images/phonepe.png";
import BharatPe from "./../Assets/Images/bharatpe.png";
import AmazonPay from "./../Assets/Images/amazonpay.png";

export default function Addmoney() {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  const { orderResponse } = useSelector((state) => state.Post);
  const { rechargeResponse } = useSelector((state) => state.Post);
  const { rechargeStatusResponse } = useSelector((state) => state.Post);
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { createOrderResponse } = useSelector((state) => state.Post);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(countdown);
        dispatch(setRechargeResponse());
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addAmount = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  useEffect(() => {
    if (rechargeResponse?.status) {
      const twoSecondInterval = setInterval(() => {
        const formData = new FormData();
        formData.append("order_id", rechargeResponse?.data?.order_id);
        dispatch(rechargeStatus(formData));
        if (
          rechargeStatusResponse?.data?.payment_status == "success" &&
          rechargeResponse?.data?.order_id ==
            rechargeStatusResponse?.data?.order_id
        ) {
          toast.success("Payment Done Successfully");
          dispatch(setRechargeResponse());
          dispatch(setRechargeStatusResponse());
          navigate("/wallet");
        }
      }, 2000);

      return () => clearInterval(twoSecondInterval);
    }
  }, [
    JSON.stringify(rechargeStatusResponse),
    JSON.stringify(rechargeResponse),
  ]);

  const uid = localStorage.getItem("user_id");

  const withdReq = () => {
    const amnt = amount;
    const formData = new FormData();
    formData.append("user_id", uid);
    formData.append("amount", amnt);
    setLoading(true);

    if (amount !== "" && amount !== undefined) {
      setError("");
      dispatch(recharge(formData));
      setMinutes(5);
      setSeconds(0);
    } else {
      setError("Please enter an amount");
    }
  };

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("customer_mobile", totalBalanceResponse?.user?.phone);
  //   formData.append("user_token", orderResponse?.user_token);
  //   formData.append("order_id", orderResponse?.orderDetails?.order_id);
  //   formData.append("amount", orderResponse?.orderDetails?.amount);
  //   formData.append("redirect_url", orderResponse?.redirect_url);
  //   dispatch(createOrder(formData));
  // }, [orderResponse]);

  // console.log(createOrderResponse,"link+++++++++++++++++");
  //
  useEffect(() => {
    // console.log("redirecting++++++++++++++++++++++");
    // console.log(
    //   createOrderResponse?.result?.payment_url,
    //   "dfhdh================"
    // );
    if (createOrderResponse?.result?.payment_url !== undefined || null) {
      window.location.href = createOrderResponse?.result?.payment_url;
    }
  }, [createOrderResponse]);

  const InfoPopup = () => {
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <p style={{ fontSize: "20px" }}>
            Scan QR code using BHIM or your preferred UPI app
          </p>
        </div>
        <div style={{ justifyContent: "space-evenly" }}>
          <img
            src={GPay}
            alt="GPay"
            style={{ height: "45px", width: "50px", marginRight: "10px" }}
          />
          <img
            src={Paytm}
            alt="Paytm"
            style={{ height: "45px", width: "50px", marginRight: "10px" }}
          />
          <img
            src={PhonePe}
            alt="PhonePe"
            style={{ height: "35px", width: "50px", marginRight: "10px" }}
          />
          <img
            src={BharatPe}
            alt="BharatPe"
            style={{ height: "35px", width: "50px", marginRight: "10px" }}
          />
          <img
            src={AmazonPay}
            alt="Amazon Pay"
            style={{ height: "35px", width: "50px", marginRight: "10px" }}
          />
        </div>
        <div style={{ marginTop: "40px", marginBottom: "10px" }}>
          <QRCode size={256} value={rechargeResponse?.data?.upi_link} />
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <p style={{ fontSize: "18px" }}>Valid For</p>
            <p style={{ fontSize: "18px" }}>
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </p>
          </div>
          <a
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "center",
              textDecoration:'none',
              width:'80%',
              margin:'auto'
            }}
            href={rechargeResponse?.data?.upi_link}
            // href="upi://pay?cu=INR&pa=paytmqr2810050501011c7hdlw291fz@paytm&pn=Paytm Merchant&am=100&mam=100&tr=B8oEjIoSjp1716276512&tn=UPI Payment"
          >
          <div
            style={{
              textAlign: "center",
              backgroundColor: "black",
              borderRadius: "18px",
              textDecoration:'none', 
              width:'80%',
              margin:'auto'
            }}
          >
            <p style={{ fontSize: "20px", color: "white", padding: "10px",lineHeight:'30px' }}>
              Pay With UPI
              <span>
                <img
                  src={BharatPe}
                  alt="BharatPe"
                  style={{ height: "26px", width: "40px", marginLeft: "10px" }}
                />
              </span>
            </p>
          </div>
        </a>
          </div>
        {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <p style={{ fontSize: "20px" }}>QR code expired. Please try again.</p>
        </div> */}
      </div>
    );
  };

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container fixed">
          <div className="reg-back">
            <div
              className="reg__back"
              onClick={() => {
                dispatch(setRechargeResponse());
                navigate("/wallet");
              }}
            >
              {/* <Link to="/wallet"> */}
              <i className="fa-solid fa-arrow-left"></i>
              {/* </Link> */}
            </div>
            <p>add money</p>
          </div>

          <div>
            {rechargeResponse?.status ? (
              InfoPopup()
            ) : (
              <div className="mid_content add_money">
                <div className="inn_money">
                  <div className="mb-3">
                    <label className="form-label">Amount</label>
                    <TextField
                      style={{
                        width: "100%",
                        color: "lightgrey",
                        margin: "auto",
                      }}
                      fullWidth
                      placeholder="Enter the amount"
                      onChange={(e) => setAmount(e.target.value)}
                      type="text"
                      className="form-control"
                      value={amount}
                    />
                    <div
                      style={{
                        height: "50px",
                        fontSize: "15px",
                        color: "red",
                        paddingLeft: "5px",
                      }}
                    >
                      {error}
                    </div>
                  </div>

                  <ul>
                    <li onClick={(e) => addAmount(e)} value={250}>
                      250
                    </li>
                    <li onClick={(e) => addAmount(e)} value={500}>
                      500
                    </li>
                    <li onClick={(e) => addAmount(e)} value={750}>
                      750
                    </li>
                  </ul>

                  <button onClick={withdReq} className="btn submit_btn">
                    Add Money
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="Toastify"></div>
      </div>
    </div>
  );
}
