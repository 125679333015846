import React from 'react'
import jersey from '../Assets/Images/jersey.jfif';


export default function PortfCompos({invest,returns}) {
  return (
    <div>
        <div style={{width:"97%", borderRadius:"5px",margin:"auto",marginTop:"10px"}} className="top_trade_port">
                    <div className="trde">
                        <div className="trde_icon__">
                            <i className="fa-solid fa-building-columns"></i>
                        </div>
                        <p>Trade Portfolio</p>
                    </div>
                    <div className="full__context">
                        <div className="left_context">
                            <div className="context_amount">
                                ₹ {invest == null || invest == "" ? " 0.00":invest}
                            </div>
                            <p>Investment</p>
                        </div>
                        <div className="right_context">
                            <div className="return_amont">
                                ₹ {returns == null ||returns== ""? " 0.00":returns}
                            </div>
                            <p>Returns</p>
                        </div>
                    </div>
                </div>

            
    </div>
  )
}
