import React, { useEffect, useState } from "react";
import { matchQuest } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import jersey from "../Assets/Images/jerseypng.png";
import logo4 from "../Assets/Images/fav1.png";
import { betRes, postBet } from "../Redux/PostSlice";
import Footer from "../Components/Footer";
import { upcoming } from "../Redux/GetSlice";
import moment from "moment";
import { getyesno } from "../Redux/GetSlice";
import { useNavigate } from "react-router-dom";
import { yes, no } from "../Redux/GetSlice";
import { io } from "socket.io-client";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faMinus,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { RT_BASE_URL, produc } from "../Redux/Helper";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { BASE_APP_URL } from "../config";
const socket = io.connect(RT_BASE_URL);

export default function MatchDetQuest() {
  const { matchQuestions } = useSelector((state) => state.Get);
  const { upcomingMatch } = useSelector((state) => state.Get);
  const { yesNoCounts } = useSelector((state) => state.Get);

  const params = useParams();
  // console.log("idddddddddddddd",params.id);

  const [queId, setQuiId] = useState("");
  const [info, setInfo] = useState(false);
  const [popinfo, setPopinfo] = useState({});
  const [copy, setCopy] = useState(false);
  const [realQuest, setRealQuest] = useState([{}]);
  const [checking, setChecking] = useState("");

  if (copy) {
    toast.success("Copied to clipboard");
    setCopy(false);
  }

  const uid = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setYes = (mId, qId, opt) => {
    dispatch(yes());
    navigate(`/placebet/${mId}/${qId}`);
  };

  const setNo = (mId, qId, opt) => {
    dispatch(no());

    navigate(`/placebet/${mId}/${qId}`);
  };
  const infoPop = (items, ind) => {
    console.log(items,"items--------- out");
    setInfo(true);
    setPopinfo(items);
  };

  useEffect(() => {
    setRealQuest(matchQuestions);
  }, [matchQuestions]);
//   console.log(realQuest,"realquest data--------- out");

  useEffect(() => {
    socket.on("newQuestionPublished", (data) => {
      // console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Upcoming" &&
        socketData?.match?.match_id == params.id
      ) {
     
       
        dispatch(matchQuest(params.id));
        // console.log(realQuest,"realquest data---------");
        
       
        setChecking(socketData?.match?.match_type);
        // console.log("Getting new questions=========---------");
      }
    });
  }, []);

  useEffect(() => {
    socket.on("questionDeleted", (data) => {
      // console.log(data, "question data ----------");
      let socketData = JSON.parse(data);
      if (
        socketData?.match?.match_type == "Upcoming" &&
        socketData?.match?.match_id == params.id
      ) {
     
       
        dispatch(matchQuest(params.id));
        // console.log(realQuest,"realquest data---------");
        
       
        setChecking(socketData?.match?.match_type);
        // console.log("Getting new questions=========---------");
      }
    });
  }, []);

  // console.log(realQuest,"realinfo---------------");

  // onClick={(e)=> placeBet(items.question_id,items.match_id,e.target.value)}
  useEffect(() => {
    dispatch(upcoming());
  }, []);

  useEffect(() => {
    dispatch(matchQuest(params.id));
  }, []);

  useEffect(() => {
    dispatch(betRes(false))
  }, [dispatch]);

  let time = setTimeout(() => {
    setQuiId(queId + 1);
  }, 1000);

  if (queId > 2) {
    clearTimeout(time);
  }




  const link = (qid)=>{
    let newLink = BASE_APP_URL + `placebet/${params.id}` + `/${qid}`
return newLink
  } 
    

  const sot = (url) => {
    window.open(url, "_blank");
  };

  const InfoPopup = ({ items, close }) => {
    const closeddd = () => {
      close(false);
    };

    return (
      <>
        <div
          style={{
            maxWidth: "420px",
            margin: "auto",
            boxShadow: "1px 1px 100px 10px",
          }}
          className="style_container__IcEZT"
        >
          <div style={{ display: "flex" }}>
            <h3>About the event</h3>
            <FontAwesomeIcon
              color="grey"
              onClick={closeddd}
              style={{
                marginLeft: "210px",
                height: "25px",
                cursor: "pointer",
                marginTop: "0px",
              }}
              icon={faXmarkCircle}
            />
          </div>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <p>Id</p>
                  <h4>{items?.question_id} </h4>
                </td>
                <td>
                  <p>Share</p>
                  <div
                    style={{ border: "0px solid black" }}
                    className="copy-text info"
                  >
                    <input type="text" readonly="" className="text" value={link(items?.question_id)} />
                    <CopyToClipboard text={link(items?.question_id)} onCopy={() => setCopy(true)}>
                      <button
                        style={{
                          border: "1px solid #007bff",
                          backgroundColor: "#007bff",
                          height: "28px",
                          width: "28px",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <i style={{ color: "white" }} className="fa fa-clone"></i>
                      </button>
                    </CopyToClipboard>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Started at</p>
                  <h4>
                    {moment(items?.created_at).format("Do MMM YY, hh : mm a")}
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Accordion
              style={{ backgroundColor: "white", cursor: "pointer" }}
              allowZeroExpanded
            >
              <AccordionItem>
                <AccordionItemHeading
                  style={{ height: "60px", padding: "17px 0 0 10px" }}
                >
                  <AccordionItemButton
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>
                      Overview
                      <p style={{ fontWeight: "300", paddingTop: "5px" }}>
                        Information about event
                      </p>
                    </h4>

                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faAngleDown}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel
                  style={{ height: "130px", backgroundColor: "#f3f3f3" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      padding: "10px 10px 0 10px",
                      borderTop: "1px solid lightgrey",
                    }}
                  >
                    {items?.question_long_desc}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <hr style={{ border: "0.5px solid lightgrey" }} />
          </div>
          <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Accordion
              style={{ backgroundColor: "white", cursor: "pointer" }}
              allowZeroExpanded
            >
              <AccordionItem>
                <AccordionItemHeading
                  style={{ height: "60px", padding: "17px 0 0 10px" }}
                >
                  <AccordionItemButton
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>
                      Source of truth
                      <p style={{ fontWeight: "300", paddingTop: "5px" }}>
                        Information about source of truth
                      </p>
                    </h4>

                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faAngleDown}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel style={{height:"130px",backgroundColor:"#f3f3f3"}}>
                  
                                        <p style={{fontSize:"14px",padding:"10px 10px 0 10px",borderTop:"1px solid lightgrey"}}>
                                           To know more, <span style={{color:"blue"}} onClick={()=>sot(items?.source_of_truth)} >click here</span>  
                                        </p>
                                    </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <hr style={{ border: "0.5px solid lightgrey" }} />
          </div>
          <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Accordion
              style={{ backgroundColor: "white", cursor: "pointer" }}
              allowZeroExpanded
            >
              <AccordionItem>
                <AccordionItemHeading
                  style={{ height: "60px", padding: "17px 0 0 10px" }}
                >
                  <AccordionItemButton
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>
                      Rules
                      <p style={{ fontWeight: "300", paddingTop: "5px" }}>
                        Terms and conditions
                      </p>
                    </h4>

                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faAngleDown}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel
                  style={{ height: "130px", backgroundColor: "#f3f3f3" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      padding: "10px 10px 0 10px",
                      borderTop: "1px solid lightgrey",
                    }}
                  >
                    {items?.rules}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <hr style={{ border: "0.5px solid lightgrey" }} />
          </div>
        </div>
      </>
    );
  };

  // setQuestions([...matchQuestions,matchQuestions])

  // console.log(matchQuestions);

  const redirectLink = matchQuestions[0]?.source_of_truth;

  const redirect = (data) => {
    window.open(data, "_blank");
  };

  return (
    <div>
      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div className="container auth__container">
          <div onClick={() => setInfo(false)} className="reg-back fixed_up">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <div className="reg__back matches_select">
              <div className="dropdown">
                {Array.isArray(upcomingMatch) &&
                  upcomingMatch?.map((items) => {
                    if (items?.match_id === params?.id) {
                      return (
                        <button
                          style={{ height: "30px", width: "85px" }}
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {items?.team_1_abbr} vs {items?.team_2_abbr}
                        </button>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          {Array.isArray(upcomingMatch) &&
            upcomingMatch.map((items) => {
              if (items?.match_id === params.id) {
                return (
                  <section
                    onClick={() => setInfo(false)}
                    className="middle_content"
                  >
                    <Link>
                      <div className="upcoming_match_card qu">
                        <div className="up-headi">
                          <div className="match_head detils_head">
                            <p>{items?.league_name}</p>
                          </div>
                        </div>

                        <div className="top_dt_mtch">
                          <div className="frst_team_img">
                            {items?.team_1_flag == null ||
                            items?.team_1_flag == undefined ||
                            items?.team_1_flag == "" ? (
                              <img src={logo4} alt="" />
                            ) : (
                              <img src={produc(items?.team_1_flag)} alt="" />
                            )}

                            <p>{items?.team_1_name} </p>
                          </div>

                          <div className="fix_">
                            Starts on{" "}
                            {moment(items?.match_date).format("Do MMM YY")}
                            <p className="time_fixed">
                              {moment(items?.match_date).format("h:mm a")}
                            </p>
                          </div>
                          <div className="scnd_team_img">
                            {items?.team_2_flag == null ||
                            items?.team_2_flag == undefined ||
                            items?.team_2_flag == "" ? (
                              <img src={logo4} alt="" />
                            ) : (
                              <img src={produc(items?.team_2_flag)} alt="" />
                            )}
                            <p>{items?.team_2_name} </p>
                          </div>
                        </div>

                        <div className="card_below_content">
                          <div className="below_content lst">
                            <p>For Full Score And Commentary.</p>
                            <div onClick={()=>redirect(items?.source_of_truth)} className="tm_scnd">
                              Click Here
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </section>
                );
              }
            })}

          <section className="ques">
            {realQuest?.length == 0 ? (
              <>
                <h3 style={{ textAlign: "center", color: "grey" }}>
                  No questions for this match!
                </h3>
              </>
            ) : (
              <></>
            )}
            {Array.isArray(realQuest) && realQuest?.map((items, ind) => {
              // console.log("items running",items);
              return (
                <div className="question_box_">
                 
                  <div className="trade_rule">
                    <div className="trade_icon">
                      <i className="fa-brands fa-sellsy"></i>
                    </div>
                    <hr />
                    <p>{items?.answer_count} Trades</p>
                  </div>
                  <div className="imgque">
                    <div className="ques">
                      <div className="quest_tex">{items?.question_text}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 5,
                          marginTop: 10,
                        }}
                        className="descpt"
                      >
                        <img style={{ height: "25px" }} src={logo4} alt="" />
                        <p className="shrt_desc">{items?.question_short_desc} </p>
                      </div>
                    </div>

                    <div className="quest_img">
                      {items?.question_image == null ||
                      items?.question_image == undefined ||
                      items?.question_image == "" ? (
                        <img src={jersey} alt="" />
                      ) : (
                        <img src={produc(items?.question_image)} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="yes_no_action">
                    <button
                      onClick={() =>
                        setYes(
                          items?.match_id,
                          items?.question_id,
                          items?.option1_text
                        )
                      }
                      value={items?.option1_text}
                      style={{
                        fontWeight: "800",
                        width: "150px",
                        height: "35px",
                        fontSize: "11px",
                      }}
                      className="yes_btn"
                    >
                      {items.option1_text}{" "}
                      {items?.min_option1_amount == null
                        ? "₹5"
                        : "₹" + parseFloat(items?.min_option1_amount)}{" "}
                    </button>

                    <button
                      onClick={() =>
                        setNo(
                          items?.match_id,
                          items?.question_id,
                          items?.option2_text
                        )
                      }
                      value={items?.option2_text}
                      style={{
                        fontWeight: "800",
                        width: "150px",
                        height: "35px",
                        fontSize: "11px",
                      }}
                      className="no_btn"
                    >
                      {items.option2_text}{" "}
                      {items?.min_option1_amount == null
                        ? "₹5"
                        : "₹" + (10 - parseFloat(items?.min_option1_amount))}
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary tool"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <div onClick={() => infoPop(items, ind)} className="qu_ic">
                        <i className="fa-solid fa-info"></i>
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </section>

          <section className="tbl"></section>
        </div>
      </div>
      <div style={{ position: "sticky", bottom: "0" }}>
        <div>{info && <InfoPopup items={popinfo} close={setInfo} />}</div>
        {info == true ? <></> : <Footer />}
      </div>

      <div className="Toastify"></div>
    </div>
  );
}
