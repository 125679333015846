import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import "./Share.css";

import tel from "../../Assets/Images/telegram.png";
import twit from "../../Assets/Images/twitter.png";
import insta from "../../Assets/Images/instagram.png";
import fb from "../../Assets/Images/facebook.png";
import whatsapp from '../../Assets/Images/whatsapppp.png';

const SharingOptions = ({ url }) => {
  return (
    <div
      style={{ borderRadius: "10px", display: "flex", height: "90px" }}
      className="sharing-options-container"
    >
      <FacebookShareButton url={url} className="share-button facebook-button">
        <img style={{ height: "50px", width: "50px" }} src={fb} alt="" />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        
        className="share-button twitter-button"
      >
           <img style={{ height: "50px", width: "50px" }} src={twit} alt="" />
      </TwitterShareButton>
      <WhatsappShareButton
        url={url}
       
        className="share-button linkedin-button"
      >
          <img style={{ height: "55px", width: "55px" }} src={whatsapp} alt="" />
      </WhatsappShareButton>

      <TelegramShareButton
        url={url}
     
        className="share-button linkedin-button"
      >
          <img style={{ height: "50px", width: "50px" }} src={tel} alt="" />
      </TelegramShareButton>
    </div>
  );
};

export default SharingOptions;
