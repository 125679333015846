import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import bgpic from "./../Assets/Images/hero-img-reg.jpg";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Firebase/Setup";
import toast from "react-hot-toast";
import { logauth,setLoadingAuth } from "../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import TopNavWOLLOG from "../Screens/TopNavWOLLOG";
import { checkAccount, login_otp, varify_login_otp } from "../Service/apiService";
import Loader from "../Components/Loader";

// import { getAuth } from "firebase/auth";

export default function Login() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [rotp, setRotp] = useState("");
  const [otp, setOtp] = useState(false);
  const [userNum, setUserNum] = useState("");
  const [errors, seterrors] = useState(" ");
  const [fberror, setFberror] = useState("");
  const [sec, setSec] = useState(35);
  const [time, setTime] = useState(false);
  const [conResult, setConResult] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const [hideSms, setHideSms] = useState(false);
  const [isloading, setIsloading] = useState(false);

  // console.log("errors", errors);

  auth.languageCode = "en-GB";


  const LoginOtp = () =>{
    setLoading(true);
    login_otp({ mobile: userNum }).then((res)=>{
      console.error('.......res.......',res);
      setLoading(false);
      if(res?.status == true){
        toast.success(res?.message);
        setOtp(true);
        setTime(true);
        setSec(35);
      }else{
        toast.error(res?.message);
      }
    }).catch((error)=>{
      setLoading(false);
      console.error('.......error.......',error);
      toast.error('Something went wrong. Try again later');
    })
  }



  const onSignInSubmit = async () => {
    
    if (userNum === "" || userNum.trim().length === 0) {
      // console.log("..........1st...........");
      setOtp(false);
      seterrors("Phone number is required");

      // console.log(otp);
    }
    setIsloading(true)
    checkAccount({ phone: userNum })
      .then(async (res) => {
        setIsloading(false)
        console.log("..........res........", res);
        if(res?.data?.is_account_exist == true && res?.data?.account_status == true){
          if (errors === "") {
            LoginOtp()
          }
        }
        else if(res?.data?.is_account_exist == true && res?.data?.account_status == false){
          toast.error("Account deactivated. Please contact the admin.");
        }
        else{
          toast.error("No Account Exist");
          navigate("/register");
        }
      })
      .catch((err) => {
        setIsloading(false)
        console.log("..........err........", err);
      });
  };

  const resendOtp = async () => {
    LoginOtp()
  };

  const validation = (num) => {
    const regExp = new RegExp("^[6-9][0-9]{9}$");
    if (num === "" || num.length === 0) {
      seterrors("Phone number is required");
    } else if (!regExp.test(num.trim())) {
      seterrors("Invalid number");
    } else {
      seterrors("");
    }
  };

  if (time && sec > 0) {
    setTimeout(() => {
      setSec(sec - 1);
    }, 1000);
  }

  const handleChange = (e) => {
    let a = e.target.value;
    validation(a);
    setUserNum(a);

    if (a.trim().length === 11) {
      setUserNum(userNum);
    }
  };


  const userId = localStorage.getItem("user_id");

  const { logResponse } = useSelector((state) => state.Auth);
  const { isLogin } = useSelector((state) => state.Auth);
  // console.log("logres", logResponse);

  const regres = localStorage.getItem("isRegistered");

  const tokenSet = async () => {
    setLoading(true);
    const devId = "1234567890";
    const devToken = "device_token";

    const formData = new FormData();
    formData.append("phone", userNum);
    formData.append("device_id", devId);
    formData.append("device_token", devToken);

    try {
      varify_login_otp({ mobile: userNum , otp:rotp}).then((res)=>{
        console.log('.......res.......',res);
        if(res?.status == true){
          dispatch(logauth(formData));
        }else{
          toast.error(res?.messages?.otp);
        }
        setLoading(false);
      }).catch((error)=>{
        console.log('.......error.......',error);
        setLoading(false);
      })

    } catch (error) {
      // console.error(error);
      setLoading(false);
    }
  };
  // console.log(logResponse, "logres---");

  if (userId !== undefined && userId !== "" && userId !== null) {
    toast.success("Logged in successfully");

    navigate("/");
  }
  if (logResponse == false) {
    navigate("/register");

    //window.location.reload();
  }

  // const {isLogin} = useSelector((state)=>state.Auth);

  return (
    <>
    <Loader loading={isloading}/>
      <div style={{ minHeight: "100vh" }} className="mobile__layout">
        <div style={{ position: "sticky", top: "0", zIndex: 10 }}>
          <TopNavWOLLOG />
        </div>

        <div className="reg-back">
          <div className="reg__back">
            <Link to="/">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </div>
          <p>Log in</p>
        </div>

        <div className="container">
          <div className="hero_sec_reg">
            <img src={bgpic} alt="" />
          </div>
        </div>

        <div className="sect_mob">
          <div className="mobile_num_input">
            {/* <select name="" id="">
                        <option value=""></option>


                      </select> */}
            {/* <input type="text" id="mobile_code" className="form-control" placeholder="Phone Number" name="name"  /> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "32px",
                  width: "80px",
                  border: "1px solid lightgrey",
                  marginTop: "8px",
                  borderRadius: "3px",
                }}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png"
                  style={{
                    height: "20px",
                    width: "28px",
                    padding: "1px 0px 1px 5px",
                  }}
                  alt=""
                />

                <p style={{ padding: "0px 4px 0px 0px" }}>+91</p>
              </div>
              <input
                style={{
                  width: "390px",
                  height: "32px",
                  marginTop: "8px",
                  border: "1px solid lightgrey",
                  paddingLeft: "10px",
                }}
                value={userNum}
                onChange={handleChange}
                type="tel"
                placeholder="Phone number"
              />
            </div>

            <h5 style={{ fontWeight: "400", color: "red" }}>{errors}</h5>

            <p
              style={{ marginTop: "30px", marginBottom: "20px" }}
              className="code_sent"
            >
              We will send you a code to the above phone number. Please enter
              the code after you receive it
            </p>

            {otp ? (
              <>
                <div
                  style={{ backgroundColor: "#e5edff" }}
                  className="mobile__layout"
                >
                  <div
                    style={{ minHeight: "60vh" }}
                    className="container auth__container"
                  >
                    <div className="style_form__1P9Yy">
                      <div></div>
                      <div className="style_title__large__NPREl">
                        Verify phone
                      </div>
                      <div className="style_title__small__3ZhHV">{msg}</div>
                      <div className="style_form__fields__5NhNp">
                        <div className="form__group">
                          <div className="form__field__input__wrapper">
                            <div className="form__field__input">
                              <div className="input-field">
                                {/* <input minLength={1} type="number" />
                                          <input minLength={1}  type="number" />
                                          <input minLength={1}  type="number" />
                                          <input minLength={1}  type="number" />
                                          <input minLength={1}  type="number" />
                                          <input  minLength={1} type="number" /> */}

                                <OtpInput
                                  value={rotp}
                                  onChange={setRotp}
                                  numInputs={6}
                                  containerStyle={{ marginLeft: "35px" }}
                                  renderSeparator={<span></span>}
                                  inputStyle={{
                                    width: "35px",
                                    marginRight: "10px",
                                  }}
                                  renderInput={(props) => <input {...props} type="number"/>}
                                />
                              </div>
                              <h5 style={{ color: "red", fontWeight: "400" }}>
                                {fberror}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="style_form__tnc__2ZWri">
                        {sec === 0 ? (
                          <>
                            <button
                              style={{
                                border: "1px solid #e8eeff",
                                backgroundColor: "#e8eeff",
                                fontSize: "15px",
                                color: "blue",
                              }}
                              onClick={resendOtp}
                            >
                              Resend OTP
                            </button>
                          </>
                        ) : (
                          <>
                            Resend OTP in{" "}
                            <span className="style_link__1wrGI style_otp__link__3zNu1">
                              {sec} s
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "-50px" }}
                      className="style_form__actions__1s5C4 style_form__actions__otp__2_1mn"
                    >
                      {loading === true ? (
                        <button disabled="true" className="btn btn__primary">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            Logging in...{" "}
                          </div>
                        </button>
                      ) : (
                        <button onClick={()=>tokenSet()} className="btn btn__primary">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            Verify{" "}
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="Toastify"></div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="style_form__actions__1s5C4">
              {otp == true || hideSms == true ? (
                <></>
              ) : (
                <>
                  <button
                    id="sign-in-button"
                    onClick={onSignInSubmit}
                    className="btn btn__primary"
                    style={{ transform: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Send SMS{" "}
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="style_form__tnc__2ZWri style_form__tnc__login__36I5q">
          By continuing, you accept that you are 18+ years of age &amp; agree to
          the
          <Link to="/tnc">
            <span className="style_link__1wrGI"> Terms and Conditions</span>
          </Link>
        </div>
      </div>
      <div className="Toastify"></div>
    </>
  );
}
