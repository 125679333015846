import React, { useEffect, useId, useState } from "react";
import { Link, useParams } from "react-router-dom";
import wallet from "../Assets/Images/wallet.png";
import TopNav from "./TopNav";
import { useDispatch, useSelector } from "react-redux";
import { transHistory } from "../Redux/PostSlice";
import moment from "moment";
import { userBalance } from "../Redux/GetSlice";
import Footer3 from "../Components/Footer3";
import { withDrawHistory } from "../Redux/PostSlice";

export default function Transactions() {
  const dispatch = useDispatch();

  const [id, setId] = useState(2);

  const id1 = () => {
    setId(1);
  };
  const id2 = () => {
    setId(2);
  };
  const id3 = () => {
    setId(3);
  };
  // const useid = 1
  const useid = localStorage.getItem("user_id");

  const formData = new FormData();
  formData.append("user_id", useid);

  useEffect(() => {
    dispatch(withDrawHistory(formData));
  }, []);

  useEffect(() => {
    dispatch(transHistory(formData));
  }, []);

  const { transHistories } = useSelector((state) => state.Post);
  const { withHistry } = useSelector((state) => state.Post);

  useEffect(() => {
    dispatch(userBalance(useid));
  }, []);

  const { Balance } = useSelector((state) => state.Get);
  // setTimeout(() => {
  //     try {

  //         transHistories.map((items)=>{
  //             items.action === "Withdraw"? debitArr.push(parseFloat(items.amount)):creditArr.push(parseFloat(items.amount))
  //             return(
  //                 <></>
  //             )

  //         })
  //         console.log(debit);

  //       let totalCre = creditArr.reduce((a,b)=>{
  //                 return a+b;
  //       });
  //       let totalDeb = debitArr.reduce((a,b)=>{
  //         return a+b;
  //       })
  //         setAmounts(totalCre - totalDeb);
  //     } catch (error) {
  //         console.log(error);

  //     }
  //     console.log("running time");

  // }, 10000);

  return (
    <div>
      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div
          style={{ backgroundColor: "#f5f5f5" }}
          className="container auth__container"
        >
          {/* <div style={{position:"sticky",top:"0"}}>
<TopNav/>

</div> */}

          <div className="reg-back">
            <Link to="/wallet">
              <div className="reg__back">
                <i className="fa-solid fa-arrow-left"></i>
              </div>
            </Link>

            <p>Transaction history</p>
          </div>

          <div
            className="mid_content Transaction_history add_money"
            style={{ height: "auto" }}
          >
            <div className="top_tabe">
              <section className="section">
                <div className="containe">
                  <div className="tab">
                    <div className="tab__list">
                      {/* <div
                        onClick={id1}
                        style={{
                          borderBottom: id === 1 ? "1px solid black" : "",
                          color: id === 1 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        Account
                      </div> */}

                      <div
                        onClick={id2}
                        style={{
                          borderBottom: id === 2 ? "1px solid black" : "",
                          color: id === 2 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        Account
                      </div>

                      <div
                        onClick={id3}
                        style={{
                          borderBottom: id === 3 ? "1px solid black" : "",
                          color: id === 3 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        Withdraw
                      </div>
                    </div>

                    <div className="tab__content">
                      {id === 1 ? (
                        <>
                          <div>
                            <div className="main_transi">
                              <div className="total">
                                <h5>
                                  <i className="fa-solid fa-indian-rupee-sign"></i>
                                  {Balance}{" "}
                                </h5>
                                <p>Total balance</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {id === 2 ? (
                        <div key={1}>
                          <h4 style={{ color: "grey", textAlign: "center" }}>
                            {transHistories?.length == 0
                              ? "No transactions yet"
                              : ""}
                          </h4>
                          {Array.isArray(transHistories) &&
                            transHistories?.map((items) => {
                              return (
                                <div key={1}>
                                  <div className="main_transi catego_border">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "0px",
                                      }}
                                      className="catego"
                                    >
                                      <div className="cat_icon" style={{width:'80%'}}>
                                        <div
                                          className={
                                            items?.action == "Deposit" ||
                                            items?.action == "Bonus Received"
                                              ? "boxs"
                                              : "boxs ltr"
                                          }
                                        >
                                          <i className="fa-solid fa-arrow-up"></i>
                                        </div>
                                        <div className="content">
                                          <div className="contnt_text_">
                                            <h4>{items?.desp} </h4>
                                            <p>
                                              {moment(items?.created_at).format(
                                                "MMM Do YY"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="amt"  style={{width:'20%'}}>
                                        <p>
                                          <i className="fa-solid fa-indian-rupee-sign"></i>
                                          <span>{items?.amount} </span>
                                        </p>
                                        <h6
                                          style={{
                                            color:
                                              items?.status == "Completed"
                                                ? "green"
                                                : items?.status == "Pending"
                                                ? "orange"
                                                : "red",
                                          }}
                                        >
                                          {items?.status}{" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {id === 3 ? (
                        <>
                          <h4 style={{ color: "grey", textAlign: "center" }}>
                            {withHistry?.length == 0 ? "No withdraws yet" : ""}
                          </h4>
                          {Array.isArray(withHistry) &&
                            withHistry?.map((items) => {
                              return (
                                <div className="main_transi Withdraw">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    className="catego"
                                  >
                                    <div className="cat_icon">
                                      <div className="boxs">
                                        <img src={wallet} alt="" />
                                      </div>
                                      <div className="content">
                                        <div className="contnt_text_">
                                          <h4>{items?.action} </h4>
                                          <p>
                                            {moment(items?.created_at).format(
                                              "MMM Do YY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="amt">
                                      <p>
                                        <i className="fa-solid fa-indian-rupee-sign"></i>
                                        <span>{items?.requested_amount} </span>
                                      </p>
                                      <h6
                                        style={{
                                          color:
                                            items?.status == "Completed"
                                              ? "green"
                                              : items?.status == "Pending"
                                              ? "orange"
                                              : "red",
                                        }}
                                      >
                                        {items?.status}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              );

                              return <></>;
                            })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="Toastify"></div>
        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer3 />
        </div>
      </div>
    </div>
  );
}
