import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../config";


export const logauth = createAsyncThunk("/login", async (num) => {
  let response = await axios.post(`${BASE_URL}login`,num);
  let resData = response?.data;
  return resData;
});

export const regauth = createAsyncThunk(
  "/v1/user/registration",
  async (num) => {
    let response = await axios.post(`${BASE_URL}registration`, num);
    let resData = response?.data;
    return resData;
  }
);

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    regResponse: {},
    logResponse: {
      
    },
    isLogin: false,
    isRegistered:false,
    loadingAuth:false
  },
  reducers: {
    logRestonull : (state,action)=>{
      state.logResponse = true;
    },
    setLoadingAuth : (state,action)=>{
      state.loadingAuth= action;
    }
  },
  extraReducers: (builders) => {
    builders
      .addCase(regauth.pending, (state, action) => {
        state.loadingAuth= true;
        // console.log("Pending Registration");
        // toast.loading("Wait a bit",{duration:1000});
      })
      .addCase(regauth.fulfilled, (state, { payload }) => {
        state.loadingAuth= false;
        state.regResponse = payload;
      })
      .addCase(regauth.rejected, (state, action) => {
        // console.log("Registration rejected");
        state.loadingAuth= false;
        toast.error("Something went wrong");
      })
      .addCase(logauth.pending, (state, action) => {
        state.loadingAuth= true;
        // console.log("Pending Login");
        //  toast.loading("Wait a bit",{duration:1000});
      })
      .addCase(logauth.fulfilled, (state, { payload }) => {
        state.loadingAuth= false;
        state.logResponse = payload?.is_success;

         if(payload.is_success=== true){
           localStorage.setItem("user_id",payload.user?.user_id);
           state.isLogin = true;
          //  console.log('====================================')
          //  console.log("Loginnnnnnnnnnn")
          //  console.log('====================================')
         }
         else{
            toast.error("This number is not registered!");
            

         }

      })
      .addCase(logauth.rejected, (state, action) => {
        state.loadingAuth= false;
        state.isLogin = false;
        // console.log("Login rejected");
       toast.error("This number is not registered!");
      });
  },
});
export const {logRestonull,setLoadingAuth} = AuthSlice.actions;

export default AuthSlice;
