import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PortfCompos from "../Components/PortfCompos";
import TopNav from "./TopNav";
import jersey from "../Assets/Images/jersey.jfif";
import Footer from "../Components/Footer";
import { portfolio } from "../Redux/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer2 from "../Components/Footer2";
import SideNav from "./SideNav";
import { cancelBet } from "../Redux/PostSlice";
import toast from "react-hot-toast";
import TopNavWOL from "./TopNavWOL";
import { getClosedEvents } from "../Redux/GetSlice";
import { produc } from "../Redux/Helper";
import { userBalance } from "../Redux/GetSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import logo4 from "../Assets/Images/fav1.png";

export default function PrtfloQuesCards() {
  const [click, setClick] = useState(false);

  const dispatch = useDispatch();

  const id = localStorage.getItem("user_id");

 

  const { portFolios } = useSelector((state) => state.Post);
  const { totalBalanceResponse } = useSelector((state) => state.Get);



  const clickLive = () => {
    setClick(false);
  };
  const clickClose = () => {
    setClick(true);
  };

 

  const [side, setSide] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);

  const openSideNav = () => {
    setSide(true);
    // console.log("Open");
  };
  const closeSideNav = () => {
    setSide(false);
  };

  useEffect(() => {
    if (portFolios.length > 0) {
      let a = portFolios
        ?.map((item) => parseFloat(item.amount_invested))
        ?.reduce((acc, curr) => acc + curr)
        .toFixed(2);
      setTotalInvestment(a);
    }

    if (portFolios.length > 0) {
      let a = portFolios
        ?.map((item) => parseFloat(item.amount_earned))
        ?.reduce((acc, curr) => acc + curr)
        .toFixed(2);
      setTotalReturns(a);
    }
  }, [portFolios]);

  // console.log(totalInvestment);
  // console.log(totalReturns);

  const cancell = localStorage.getItem("cancelArr");

  const cancel = async (qId, amnt, ind) => {
    var cancel = JSON.parse(localStorage.getItem("cancelArr") || "[]");

    if (!cancel?.includes(ind)) {
      cancel.unshift(ind);
    }

    localStorage.setItem("cancelArr", JSON.stringify(cancel));

    const formData = new FormData();
    formData.append("question_id", qId);
    formData.append("user_id", id);
    formData.append("amount", amnt);

    // console.log(qId);
    // console.log(amnt);

    dispatch(cancelBet(formData));
  };

  const { ClosedEvents } = useSelector((state) => state.Get);
  const { cancelResponse } = useSelector((state) => state.Post);

  const [canceled, setCanceled] = useState(false);
  const [exit, setExit] = useState(false);
  const [exitInfo, setexitInfo] = useState({});
  const [cancelInfo, setcancelInfo] = useState({});
  const [cancelUcheck, setCancelUcheck] = useState(false);
  const [cancelMcheck, setCancelMcheck] = useState(false);
  const [exitMcheck, setExitMcheck] = useState(false);

  const handleConfirm = () => {
    alert("Confirmed!");
    // Add your logic for confirmation here
  };

  const cancelPop = (items) => {
    setCanceled(true);
    setcancelInfo(items);
  };

  const exitPop = (items) => {
    setExit(true);
    setexitInfo(items);
  };



  return (
    <div>
      {side ? (
        <>
          <SideNav closesidenavbar={closeSideNav} />
          {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>
      ) : (
        <></>
      )}

      <div style={{ minHeight: "100vh" }} className="mobile__layout main">
        <div className="container auth__container">
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            {id !== "" && id !== undefined && id !== null ? (
              <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />
            ) : (
              <TopNavWOL />
            )}
          </div>

          <div style={{ zIndex: "1" }} className="reg-back fixed">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>My Portfolio</p>
          </div>

          <div
            style={{
              height: "50px",
              zIndex: "0",
              position: "sticky",
              top: "11%",
              borderBottom: "1px solid lightgrey",
            }}
            className="upper_tabs"
          >
            <Link>
              <div
                className={click ? "close_eve" : "live_eve"}
                onClick={clickLive}
              >
                Live Events
              </div>
            </Link>
            <Link>
              <div
                className={click ? "live_eve" : "close_eve"}
                onClick={clickClose}
              >
                Closed Events
              </div>
            </Link>
          </div>

          {click === true ? (
            <div style={{ backgroundColor: "lightgray", marginBottom: "10px" }}>
              <PortfCompos
                returns={totalBalanceResponse?.user?.balance_winning}
                invest={totalBalanceResponse?.investment}
              />
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
            
              padding: 10,
            }}
          >
            {click === false ? (
              <div style={{ width: "100%" }}>
              
               
                  {portFolios?.length == 0 ?<h4 style={{ textAlign: "center",paddingTop:20}}>No bet placed yet</h4> : ""}
              
                {Array.isArray(portFolios) &&
                  portFolios?.map((items, ind) => {
                    return (
                      <div key={ind} style={{ marginBottom: "10px" }}>
                        <Link to='/portfolio'>
                          <div className="question_box_">
                            <div className="trade_rule">
                              <div className="trade_icon">
                                <i className="fa-brands fa-sellsy"></i>
                              </div>
                              <hr />
                              <p>{items?.answer_count} Trades</p>
                            </div>
                            <div className="imgque">
                              <div className="ques">
                                <div className="quest_tex">
                                  {items?.question_text}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: 5,
                                    marginTop: 10,
                                  }}
                                  className="descpt"
                                >
                                  <img
                                    style={{ height: "25px" }}
                                    src={logo4}
                                    alt=""
                                  />
                                  <p className="shrt_desc">
                                    {items?.question_short_desc}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="quest_img">
                                {items?.question_image == null ||
                                items?.question_image == undefined ||
                                items?.question_image == "" ? (
                                  <img
                                    style={{ borderRadius: 10,backgroundColor:"grey" }}
                                    src={jersey}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={{ borderRadius: 10,backgroundColor:"lightgrey" }}
                                    src={produc(items?.question_image)}
                                    alt=""
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div style={{marginTop:-15}} >
                {Array.isArray(ClosedEvents) &&
                  ClosedEvents?.map((items, ind) => {
                    return (
                      <div
                        key={ind}
                        style={{
                          marginBottom: "10px",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            borderRadius: "5px",
                            margin: "auto",
                            marginTop: "10px",
                          }}
                          className="rslt__crd"
                        >
                          <div className="top__dt">
                            <div className="plcehlder">
                              {items?.question_image == null ||
                              items?.question_image == undefined ||
                              items?.question_image == "" ? (
                                <img src={jersey} alt="img" />
                              ) : (
                                <img
                                  src={produc(items?.question_image)}
                                  alt=""
                                />
                              )}
                            </div>
                            <p>{items?.question_text} </p>
                          </div>
                          <div className="fl-det">
                            <div className="lower_dtls">
                              <div className="box">
                                <div className="hd_blo">Choose</div>
                                <div className="rslt_blo">
                                  {items?.selected_option}
                                </div>
                              </div>
                              <div className="box">
                                <div className="hd_blo">Invest</div>
                                <div className="rslt_blo">₹{items?.invest}</div>
                              </div>
                              <div className="box">
                                <div className="hd_blo">Buy Price</div>
                                <div className="rslt_blo">₹{items?.buy_price}</div>
                              </div>
                              <div className="box">
                                <div className="hd_blo">Quantity</div>
                                <div
                                  style={{ marginTop: "5px" }}
                                  className="rslt_blo"
                                >
                                  {items?.total_count}
                                </div>
                              </div>
                              <div className="box">
                                <div className="hd_blo">Correct answer</div>
                                <div className="rslt_blo rd">
                                  {items?.publish_result}
                                </div>
                              </div>
                              <div className="box">
                                <div className="hd_blo">Returns</div>
                                <div className="rslt_blo">₹{items?.gains}</div>
                              </div>
                            </div>
                            <div className="lw_cnc">
                              <div className="unmatched_qty">
                                <div className="icon_">
                                  <i className="fa-solid fa-money-check"></i>
                                </div>
                                <div className="context_">
                                  Invested ₹{items?.amount_invested}{" "}
                                  <span>Gains ₹{items?.gains}</span>{" "}
                                </div>
                              </div>

                              <div>
                                <h3>Closed</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}{" "}
              </div>
            )}
          </div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer2 />
        </div>
      </div>
    </div>
  );
}
