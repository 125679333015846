import axios from "axios";


let mainUrl = "https://ehostingguru.com/stage/opinion365/backend";
export const baseURL = mainUrl;
let apiInstance = axios.create({ baseURL });

apiInstance.interceptors.request.use(
  async function (config) {
    config.headers["X-API-Key"] = "{{token}}";
    config.headers["Access-Control-Allow-Origin"] = true;
    // console.log("Running interceptor");

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// export const RT_BASE_URL = "https://cricket-realtime.onrender.com";
export const RT_BASE_URL = "https://cricket-realtime-xu1k.onrender.com";


export const produc = (media) => {
  // return `https://dev.ehostingguru.com/option365/writable/${media}`; 
  // return `https://test.ehostingguru.com/option365/writable/${media}`; 
  // return `https://uat.ehostingguru.com/opinion365/writable/${media}`; 
  return `https://unicorn-tradexbet.in/unicorn/writable/${media}`; 
};

export default apiInstance;
