import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer3 from "../Components/Footer3";
import Footer from "../Components/Footer";
import { useForm } from "react-hook-form";
import { addBankDet } from "../Redux/PostSlice";
import { addKYCdet } from "../Redux/PostSlice";
import { userBalance } from "../Redux/GetSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross, faEdit, faX } from "@fortawesome/free-solid-svg-icons";
import { produc } from "../Redux/Helper";
import { bankNames } from "../config";
import { getRequestStatus } from "../Redux/GetSlice";
import NewDatePicker from "./Datepicker/Datepicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";


export default function VerifyAcc() {
  const { totalBalanceResponse } = useSelector((state) => state.Get);
  const { panLoader } = useSelector((state) => state.Post);
  const { panLoad } = useSelector((state) => state.Post);
  const { bankLoad } = useSelector((state) => state.Post);

  const [accHolName, setaccHolName] = useState(totalBalanceResponse?.user?.bank_account_name == ("" || null) ? "" : totalBalanceResponse?.user?.bank_account_name);

  const [accNum, setaccNum] = useState(totalBalanceResponse?.user?.bank_account_number == ("" || null) ? "" : totalBalanceResponse?.user?.bank_account_number);

  const [accIFSC, setaccIFSC] = useState(totalBalanceResponse?.user?.bank_account_ifsc == ("" || null) ? "" : totalBalanceResponse?.user?.bank_account_ifsc);

  const [accBrName, setaccBrName] = useState(totalBalanceResponse?.user?.branch_number == ("" || null) ? "" : totalBalanceResponse?.user?.branch_number);

  const [upiID, setupiID] = useState(totalBalanceResponse?.user?.upi_id == ("" || null) ? "" : totalBalanceResponse?.user?.upi_id);


  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [panNumber, setPanNumber] = useState(totalBalanceResponse?.kyc?.pan_number == ("" || null) ? "" : totalBalanceResponse?.kyc?.pan_number);
  const [error, setError] = useState("");
  const [pLoad, setPLoad] = useState(false);
  const [bLoad, setBLoad] = useState(false);
  const [fullName, setFullName] = useState(totalBalanceResponse?.user?.firstname == "" || totalBalanceResponse?.user?.firstname == null ? "" : totalBalanceResponse?.user?.firstname + " " + totalBalanceResponse?.user?.lastname)

  const navigate = useNavigate();

  useEffect(() => {
    if (panLoad == 1) {
      setPLoad(true);
    }
    else if (panLoad == 0) {
      setPLoad(false);
    }
  }, [panLoad]);

  useEffect(() => {
    if (bankLoad == 1) {
      setBLoad(true);
    }
    else if (bankLoad == 0) {
      setBLoad(false);
    }
  }, [bankLoad]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const validatePan = (pan) => {
    // PAN format regex
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // Check if PAN matches the regex
    if (panRegex.test(pan)) {
      setIsValid(true);
      setError("");
    } else {
      setIsValid(false);
      setError("Please enter valid PAN number");
    }
  };

  const [id, setId] = useState(1);
  const [bank, setBank] = useState(totalBalanceResponse?.user?.bank_name == (null || undefined || "") ? "" : totalBalanceResponse?.user?.bank_name);
  const [img, setImg] = useState("");
  const [reject, setReject] = useState(false)
  const [bankReject, setBankReject] = useState(false);

  const id1 = () => {
    setId(1);
  };
  const id2 = () => {
    setId(2);
  };
  const id3 = () => {
    setId(3);
  };

  const [dobTime, setDobTime] = useState(totalBalanceResponse?.user?.dob == null || totalBalanceResponse?.user?.dob == "0000-00-00" ? new Date() : `${moment(new Date(totalBalanceResponse?.user?.dob)).format("YYYY-MM-DD")}`);

  const [date, setDate] = useState('');

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  // console.log(dayjs(dobTime).format("YYYY MM DD"),"Dob==============");
  const handleSetupDateChange = (selectedDate) => {
    setDobTime(selectedDate?.format("YYYY-MM-DD"));
  };

  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(userBalance(uid));
  }, []);

  const setbankname = (e) => {
    // console.log(".......bank name.....", e.target.value);
    setBank(e.target.value);
  };
  // console.log(bank);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [panLoader]);


  const { requestStatus } = useSelector((state) => state.Get);


  useEffect(() => {
    dispatch(getRequestStatus(uid))
  }, [])

  const handleInputChange = (e) => {
    const input = e.target.value.toUpperCase(); // Convert to uppercase
    setPanNumber(input);
    validatePan(input);
  };

  const addBankdetails = (data) => {
    const { account_number, holder_name, IFSC_code, branch_number, UPI_id } =
      data;
    const formData = new FormData();
    formData.append("bank_name", bank);
    formData.append("bank_account_number", accNum);
    formData.append("bank_account_ifsc", accIFSC);
    formData.append("bank_account_name", accHolName);
    formData.append("branch_number", accBrName);
    formData.append("upi_id", upiID);
    formData.append("user_id", uid);

    dispatch(addBankDet(formData));
  };

  // console.log(img,"img-------------------------");

  const submitKYC = (data) => {
    const formData = new FormData();
    const { name, } = data;
    formData.append("full_name", fullName);
    formData.append("pan_number", panNumber);
    formData.append("dob", `${dobTime}`);
    formData.append("pan_pic", img);
    formData.append("user_id", uid);

    setLoading(true);
    dispatch(addKYCdet(formData));
    localStorage.setItem("rejectLoad", 1);
  };

  const loader = localStorage.getItem("pan_loader");
  const bankloader = localStorage.getItem("bank_loader");
  // console.log(loader);

  return (
    <div>
      <div className="mobile__layout main">
        <div className="container auth__container">
          <div className="reg-back">
            <div className="reg__back">
              <Link to="/">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </div>
            <p>KYC Verification</p>
          </div>

          <div className="mid_content kyc">
            <div className="top_tabe">
              <section className="section">
                <div className="containe">
                  <div className="tab">
                    <div className="tab__list">
                      <div
                        onClick={id1}
                        style={{
                          borderBottom: id === 1 ? "1px solid black" : "",
                          color: id === 1 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        Contact
                      </div>
                      <div
                        onClick={id2}
                        style={{
                          borderBottom: id === 2 ? "1px solid black" : "",
                          color: id === 2 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        PAN
                      </div>
                      <div
                        onClick={id3}
                        style={{
                          borderBottom: id === 3 ? "1px solid black" : "",
                          color: id === 3 ? "black" : "",
                        }}
                        className="tab__item"
                      >
                        Bank
                      </div>
                    </div>

                    <div className="tab__content">
                      {id === 1 ? (
                        <div className="tab__content-item">
                          <div className="main_transi var_mobile">
                            <form action="">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Verify your mobile
                                  </label>
                                  <TextField
                                    fullWidth

                                    disabled="true"
                                    type="text"

                                    placeholder={
                                      totalBalanceResponse?.user?.phone
                                    }
                                    style={{ color: "black", marginTop: "10px", marginBottom: "10px" }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "green",
                                  textAlign: "center",
                                  paddingTop: "12px",
                                  cursor: "not-allowed"
                                }}
                                className="btn submit_btn"
                              >
                                Verified
                                <FontAwesomeIcon
                                  style={{ marginLeft: "5px" }}
                                  icon={faCheck}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}






                      <div className="tab__content-item">
                        {id === 2 ? (
                          <div >
                            <div className="inn_kyc">
                              <>

                                <p>Submit PAN Details</p>
                                <form onSubmit={handleSubmit(submitKYC)}>
                                  <div >
                                    <h5 style={{ fontSize: "14px", marginTop: "20px" }}>Name*  </h5> <span style={{ fontSize: "12px" }}>(as per PAN card)</span>

                                    <TextField

                                      required="true"
                                      value={fullName}
                                      onChange={(e) => setFullName(e.target.value)}
                                      type="text"
                                      fullWidth
                                      disabled={requestStatus?.kyc?.kyc_status == "Approved" || (requestStatus?.kyc?.kyc_status != "Rejected" && requestStatus?.kycDetailsRequested == 1)  ? true : false }
                                      placeholder="Enter your name"
                                      style={{ color: "black" }}
                                    />
                                  </div>

                                  <h5 style={{ fontSize: "14px", marginTop: "20px" }}>
                                    PAN Number*
                                  </h5>


                                  <TextField
                                    fullWidth
                                    required="true"
                                    value={panNumber}
                                    onChange={handleInputChange}
                                    type="text"
                                    disabled={requestStatus?.kyc?.kyc_status == "Approved" || (requestStatus?.kyc?.kyc_status != "Rejected" && requestStatus?.kycDetailsRequested == 1)  ? true : false }
                                    placeholder="Enter PAN number"
                                    style={{ border: "0px solid black" }}
                                  />
                                  <h5 style={{ color: "red" }} >{error} </h5>


                                  <div className="mb-3">
                                    <h5 style={{ fontSize: "14px", marginTop: "20px" }}>
                                      DOB*
                                    </h5>
                                    <span style={{ fontSize: "12px" }}></span>

                                    <NewDatePicker
                                      required="true"
                                      disable={requestStatus?.kyc?.kyc_status == "Approved" ||(requestStatus?.kyc?.kyc_status != "Rejected" && requestStatus?.kycDetailsRequested == 1) ? true : false }
                                      maxDate={new Date()}
                                      value={moment(new Date(totalBalanceResponse?.user?.dob)) || dobTime}
                                      name="dobTime"
                                      event={"(as per PAN card)"}
                                      OnChange={handleSetupDateChange}
                                    />
                                    {/* <input
                                    
                                    defaultValue={totalBalanceResponse?.user?.dob}
                                      {...register("dob", { required: true })}
                                      error={!!errors.phone}
                                      helperText={
                                        errors.dob && "DOB is required"
                                      }
                                      onChange={handleDateChange}
                                      fullWidth
                                      style={{
                                        width: "100%",
                                        marginBottom: "20px",
                                        marginTop: "10px",
                                        borderRadius:"4px",
                                        border:"1px solid lightgrey",
                                        height:"40px"
                                      }}
                                      type="date"
                                      
                                      placeholder={totalBalanceResponse?.user?.dob}
                               
                                    /> */}


                                  </div>



                                  <div className="mb-3">
                                    <h5 style={{ fontSize: "14px", marginTop: "20px" }}>
                                      Upload PAN photo*
                                    </h5>
                                    <br />
                                    {totalBalanceResponse?.kyc?.pan_pic == null || undefined || "" ? <></> : <>
                                      <img style={{ height: "70px", width: "130px" }} src={produc(totalBalanceResponse?.kyc?.pan_pic)} alt="" />
                                    </>}
                                    <TextField
                                      fullWidth
                                      required="true"
                                      style={{
                                        width: "100%",
                                        marginBottom: "20px",
                                        marginTop: "0px",
                                      }}
                                      type="file"
                                      onChange={(e) =>
                                        setImg(e.target.files[0])
                                      }
                                      className="form-control"
                                      placeholder="Date of Birth"
                                      disabled={requestStatus?.kyc?.kyc_status == "Approved"|| (requestStatus?.kyc?.kyc_status != "Rejected" && requestStatus?.kycDetailsRequested == 1)  ? true : false }
                                    />
                                  </div>

                                  {/* button */}
                                  {totalBalanceResponse?.user
                                    ?.is_kyc_verified === "1" ? (
                                    <button
                                      type="submit"
                                      disabled="true"
                                      style={{ backgroundColor: "green", cursor: "not-allowed" }}
                                      className="btn submit_btn"
                                    >
                                      Verified{" "}
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                    </button>) :



                                    totalBalanceResponse?.user
                                      ?.is_kyc_verified === "2" ? (
                                      <>
                                        {requestStatus?.kycDetailsRequested == 0 ? <>
                                          <button
                                            type="submit"
                                            style={{ backgroundColor: "green" }}
                                            disabled="true"
                                            className="btn submit_btn"
                                          >
                                            Requested  <FontAwesomeIcon icon={faCheck} /> {" "}
                                          </button>
                                        </> : <>

                                          {reject === true ? <button
                                            type="submit"
                                            disabled={pLoad ? true : false}
                                            onSubmit={handleSubmit(submitKYC)}
                                            className="btn submit_btn"
                                          >
                                            {pLoad ? "Submitting..." : "Submit"}
                                          </button> : <div style={{ display: "flex" }}>
                                            <button
                                              type="submit"
                                              disabled="true"
                                              style={{ backgroundColor: "red", width: "320px", cursor: "not-allowed" }}
                                              className="btn submit_btn"
                                            >
                                              Rejected{" "}
                                              <FontAwesomeIcon style={{ height: "14px", paddingBottom: "1px" }} icon={faX} />{" "}
                                            </button>
                                            <div onClick={() => setReject(true)} style={{ border: "1px solid lightgrey", width: "50px", borderRadius: "5px", marginLeft: "10px", background: "lightgrey" }}>
                                              < FontAwesomeIcon color="#353535" style={{ height: "25px", marginLeft: "12px", marginTop: "9px" }} icon={faEdit} />
                                            </div>
                                          </div>}


                                        </>}

                                      </>

                                    ) :


                                      <>
                                        {requestStatus?.kycDetailsRequested == 1 ? <button
                                          type="submit"
                                          style={{ backgroundColor: "green" }}
                                          disabled="true"
                                          className="btn submit_btn"
                                        >
                                          Requested  <FontAwesomeIcon icon={faCheck} /> {" "}
                                        </button> : <button
                                          type="submit"
                                          disabled={pLoad ? true : false}
                                          onSubmit={handleSubmit(submitKYC)}
                                          className="btn submit_btn"
                                        >
                                          {pLoad ? "Submitting..." : "Submit"}
                                        </button>}

                                      </>


                                  }
                                </form>
                              </>
                              {/* </> } */}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="tab__content-item">
                      {id === 3 ? (








                        // Bank details


                        <div className="main_transi Withdraw">

                          <div className="inn_kyc">
                            <form
                              onSubmit={handleSubmit(addBankdetails)}
                            >
                              <div className="mb-3">
                                <label className="form-label">
                                  Bank name*
                                </label>
                                <br />
                                <select
                                  required="true"

                                  onChange={setbankname}
                                  value={bank}
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "20px",
                                    width: "100%",
                                    paddingLeft: "10px"
                                  }}
                                  name="state"
                                  id="state"
                                  className="form-control form-select"


                                >
                                  <option value="">
                                    {totalBalanceResponse?.user?.bank_name == (null || undefined || "") ? "Select bank name" : totalBalanceResponse?.user?.bank_name}

                                  </option>
                                  {bankNames.map((bankName, index) => (
                                    <option
                                      key={index}
                                      value={bankName}
                                    >
                                      {bankName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Account holder name*
                                </label>
                                <TextField
                                  fullWidth
                                  style={{ marginTop: "5px", marginBottom: "10px" }}
                                  required="true"
                                  value={accHolName}
                                  onChange={(e) => setaccHolName(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter holder name"
                                />
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Account number*
                                </label>
                                <TextField
                                  required="true"
                                  fullWidth
                                  style={{ marginTop: "5px", marginBottom: "10px" }}
                                  value={accNum}
                                  onChange={(e) => setaccNum(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter account number"
                                />
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  IFSC code*
                                </label>
                                <TextField
                                  fullWidth
                                  style={{ marginTop: "5px", marginBottom: "10px" }}
                                  required="true"
                                  value={accIFSC}
                                  onChange={(e) => setaccIFSC(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter IFSC code"
                                />
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Branch name*
                                </label>
                                <TextField
                                  fullWidth
                                  style={{ marginTop: "5px", marginBottom: "10px" }}
                                  required="true"
                                  value={accBrName}
                                  onChange={(e) => setaccBrName(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter branch name"
                                />
                              </div>

                              <div className="mb-3">
                                <label className="form-label">UPI ID*</label>
                                <TextField
                                  fullWidth
                                  style={{ marginTop: "5px", marginBottom: "40px" }}
                                  required="true"
                                  value={upiID}
                                  onChange={(e) => setupiID(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter UPI ID"
                                />
                              </div>




                              {/*button    */}
{/* 
                              {totalBalanceResponse?.user?.is_kyc_verified === "1" ? 
                              <>
                                <button
                                  type="submit"
                                  style={{ backgroundColor: "green", cursor: "not-allowed" }}
                                  disabled="true"
                                  className="btn submit_btn"
                                >
                                  Verified{" "}
                                  <FontAwesomeIcon icon={faCheck} />{" "}
                                </button>

                              </> :
                               totalBalanceResponse?.user?.is_kyc_verified === "2" ? 
                               <>
                                {requestStatus?.bankDetailsRequested == 1 ? <>
                                  <button
                                    type="submit"
                                    style={{ backgroundColor: "green" }}
                                    disabled="true"
                                    className="btn submit_btn"
                                  >
                                    Requested  <FontAwesomeIcon icon={faCheck} /> {" "}
                                  </button>
                                </> 
                                : 
                                <>

                                  {reject === true ? 
                                  <button
                                    type="submit"
                                    disabled={bLoad ? true : false}
                                    onSubmit={handleSubmit(addBankdetails)}
                                    className="btn submit_btn"
                                  >
                                    {bLoad ? "Submitting..." : "Submit"}
                                  </button> 
                                  : 
                                  <div style={{ display: "flex" }}>
                                    <button
                                      type="submit"
                                      disabled="true"
                                      style={{ backgroundColor: "red", width: "320px", cursor: "not-allowed" }}
                                      className="btn submit_btn"
                                    >
                                      Rejected{" "}
                                      <FontAwesomeIcon style={{ height: "14px", paddingBottom: "1px", }} icon={faX} />{" "}
                                    </button>
                                    <div onClick={() => setReject(true)} style={{ border: "1px solid lightgrey", width: "50px", borderRadius: "5px", marginLeft: "10px", background: "lightgrey" }}>
                                      < FontAwesomeIcon color="#353535" style={{ height: "25px", marginLeft: "12px", marginTop: "9px" }} icon={faEdit} />
                                    </div>
                                  </div>}
                                </>
                                }
                              </> 
                              : */}
                                {requestStatus?.bankDetailsRequested == 1 ? (
                                  <button
                                    type="submit"
                                    style={{ backgroundColor: "green" }}
                                    disabled={bLoad ? true : false}
                                    className="btn submit_btn"
                                    onSubmit={handleSubmit(addBankdetails)}
                                  >
                                    {bLoad ? "Updating...":"update"}
                                  </button>

                                ) : (
                                  <button
                                    onSubmit={handleSubmit(addBankdetails)}
                                    type="submit"
                                    disabled={bLoad ? true : false}
                                    className="btn submit_btn"
                                  >
                                    {bLoad ? "Submitting..." : "Submit"}
                                  </button>
                                )}
                            </form>


                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="Toastify"></div>
        </div>

        <div style={{ position: "sticky", bottom: "0" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}
