import React, { useEffect } from 'react';
import TopNav from './TopNav';
import Footer4 from '../Components/Footer4';
import chat from '../Assets/Images/custm.png';
import { Link } from 'react-router-dom';
import logo2 from "../Assets/Images/bellicon.png";
import { getContact } from '../Redux/GetSlice';
import { useDispatch, useSelector } from "react-redux";
import { produc } from '../Redux/Helper';
import banner from "../Assets/Images/hero_image.avif";
import SwipeableButton from './SwipeButton/SwipeButton';
import SharingOptions from './SharingOptions/ShareOptions';
import { Crisp } from "crisp-sdk-web";
import './CustomCrispStyles.css';

export default function LiveChat() {

    const dispatch = useDispatch();

    const redirecturl = (url) => {
        window.open(url, '_blank');
    }

    const { contactUs } = useSelector((state) => state?.Get);

    useEffect(() => {
        dispatch(getContact());
    }, [dispatch]);

    useEffect(() => {
        Crisp.configure("75008ad5-f78c-4225-aa49-58be1b983c5e");
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
           
        `;
        document.head.appendChild(styleElement);
    }, []);

    let urlToShare = "https://abcd.com";
    return (
        <div>
            <div className="mobile__layout main">
                <div className="container auth__container">
                    <div style={{ position: "sticky", top: "0" }} className="reg-back">
                        <div className="reg__back">
                            <Link to='/more'>
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                        </div>
                        <p>Live Chat</p>
                    </div>
                    <div className="mid_content">
                        <div className="terms_img">
                            {contactUs?.settings?.referral_banner == null || contactUs?.referral_banner === undefined || contactUs?.referral_banner === "" ? <img src={banner} alt="Banner" /> : <img src={produc(contactUs?.settings?.referral_banner)} alt="" />}
                        </div>
                        <div className="chat_content">
                            <div className="summary_chat">
                                <div className="chat_box">
                                    <p style={{ fontSize: "16px", fontWeight: "500", paddingTop: "20px", width: "85%", textAlign: "center" }}>You can chat live with our customer care executives, just click the button below </p>
                                    <button style={{ cursor: "pointer" }} onClick={() => redirecturl(contactUs?.settings?.live_chat_url)} >Live Chat<img src={chat} alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: "sticky", bottom: "0" }}>
                    <Footer4 />
                </div>
                <div className="Toastify">
                </div>
            </div>
        </div>
    );
}
