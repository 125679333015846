import "date-fns";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import "./NewDateTimePicker.css";
import "./datestyle.css"

const NewDatePicker = ({
  value,
  date,
  name,
  TextAlign,
  event,
  OnChange,
  maxDate,
  disable
}) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(date));

  useEffect(() => {
    // console.log(dayjs(value)?.$d,"value---------------");
    // console.log(dayjs(date),"date---------------");

     setSelectedDate(value?dayjs(value): dayjs(date));
  }, []);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    if (OnChange) {
      OnChange(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]}>
        <DatePicker
          className="date-picker"
          label={
            name === "Date of Birth (Optional)"
              ? "Date of Birth (Optional)"
              : event
              ? event
              : " Date and Time "
          }
          disabled={disable}
          value={selectedDate }
          format="DD-MM-YYYY"
          onChange={handleDateChange}
          maxDate={dayjs(maxDate)}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "14px",
              textAlign: TextAlign != null ? TextAlign : "center",
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default NewDatePicker;