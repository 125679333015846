import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import GetSlice from './GetSlice';
import PostSlice from './PostSlice';

export const Store = configureStore({
    reducer:{
        Auth:AuthSlice.reducer,
        Get:GetSlice.reducer,
        Post:PostSlice.reducer,
    }
});
export default Store;