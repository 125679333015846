import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faPercent, faPersonCircleQuestion, faPhone, faShieldHalved, faUserLock } from '@fortawesome/free-solid-svg-icons';
import TopNav from './TopNav';
import Footer from '../Components/Footer';
import Footer4 from '../Components/Footer4';
import SideNav from './SideNav';
import TopNavWOL from './TopNavWOL';



export default function More() {

    const [side,setSide] = useState(false);
    const openSideNav =()=>{
        setSide(true);
    //   console.log("Open");
    }
    const closeSideNav = ()=>{
       setSide(false)
    }

    const id = localStorage.getItem("user_id");

  return (
    <div>
         {side? <>
        <SideNav closesidenavbar={closeSideNav}/>
        {/* <SlideNavJS closesidenavbar={closeSideNav}/> */}
        </>:<></>}

<div className="mobile__layout main">
        <div style={{minHeight:"100vh"}} className="container auth__container">


        <div style={{position:"sticky",top:"0"}}>
                    {id!=="" && id!==undefined  && id!==null? <TopNav side={side} onOpen={openSideNav} onClose={closeSideNav} />:<TopNavWOL/>}
               

                </div>

          

            <div className="reg-back">
                <div className="reg__back">
                    <Link to='/'>
                    <i className="fa-solid fa-arrow-left"></i>
                    
                    </Link>
                </div>
                <p>More</p>
            </div>

            <div   className="mid_content">

                <div  className="conte_box">
                    <Link to='/livechat' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i className="fa-solid fa-comment"></i>
                        </div>
                        <p>Live chat</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    </Link>
                        


                </div>
                    

                <div  className="conte_box">
                    <Link to='/contact' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faPhone}/>

                            </i>
                        </div>
                        <p>Contact Us</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    </Link>
                      
                  
                </div>

                <div className="conte_box">
                    <Link to='/offer' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faPercent}/>

                            </i>
                        </div>
                        <p>Offers</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    </Link>
                         
                   
                </div>

                <div className="conte_box">
                    <Link to='/htp' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faCircleQuestion}/>

                            </i>
                        </div>
                        <p>How to Play Fantasy</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    </Link>
                        
                  
                </div>

                <div className="conte_box">
<Link to='/faq' style={{height:"50px"}}>
<div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faPersonCircleQuestion}/>

                            </i>
                        </div>
                        <p>Frequently Asked Questions</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>

</Link>                        
                        
                    
                </div>

                <div className="conte_box">
                    <Link to='/privacy' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faShieldHalved}/>

                            </i>
                        </div>
                        <p>Privacy Policy</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    
                    </Link>
                   
                    
                </div>

                <div className="conte_box">
                    <Link to='/TnC' style={{height:"50px"}}>
                    <div className="left-co">
                        <div className="icon_s">
                            <i >
                            <FontAwesomeIcon style={{color:"black"}} icon={faUserLock}/>

                            </i>
                        </div>
                        <p>Terms & Conditions</p>
                    </div>
                    <div className="right-co">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    </Link>
                    
                    
                    
                </div>

            </div>



        </div>

        <div style={{position:"sticky",bottom:"0"}}>
<Footer4/>

</div>
        <div className="Toastify">

        </div>
    </div>
    </div>
  )
}
