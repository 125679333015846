import React from 'react'
import logo2 from '../Assets/Images/logo-top-black.png';
import { Link, useParams } from 'react-router-dom'



export default function TopNavWOLLOG() {
  return (
    <div>
          <div className="main_header before-registration">
            <div className="top_logo">
              <img src={logo2} alt="" />
            </div>
            <div style={{ display: "flex" }}>
              <div className="registration__btn">
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/login"
                >
                  Login
                </Link>
              </div>
              <div style={{color:"white"}} className="login_btn">
                <Link  style={{ textDecoration: "none", color: "white" }} to="/register">Register</Link>
              </div>
            </div>
          </div>
    </div>
  )
}