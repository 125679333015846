import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { liveMatch, market, upcoming } from '../../Redux/GetSlice';

const CountdownTimer = ({ targetDate }) => {
  const dispatch = useDispatch();
  const calculateTimeLeft = () => {
    console.log('...targetDate...',targetDate);
    const difference = new Date(targetDate) - new Date()  ;
    let timeLeft = {};
    console.log('...difference...',difference);
    if(difference <= 0){
      setTimeout(() => { 
        dispatch(upcoming());
        dispatch(liveMatch());
        dispatch(market());
      }, 1000);
    }

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hr: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: Math.floor((difference / 1000 / 60) % 60),
        
      };
    }
    
    setTimeLeft(timeLeft)
    newTime(timeLeft)
    return timeLeft;
  };

  let newTime=(timeLeft)=>{
    
    let timeArray = [];

    if (timeLeft.d > 0) {
      timeArray.push(<span key="d">{timeLeft.d} {timeLeft.d === 1 ? 'day' : 'days'} </span>);
    }
    if (timeLeft.hr > 0 || timeLeft.d > 0) {
      timeArray.push(<span key="hr">{timeLeft.hr} {timeLeft.hr === 1 ? 'hr' : 'hrs'} </span>);
    }
    if (timeLeft.mins > 0 || timeLeft.hr > 0 || timeLeft.d > 0) {
      timeArray.push(<span key="mins">{timeLeft.mins} {timeLeft.mins === 1 ? 'min' : 'mins'} </span>);
    }
    if (timeLeft.secs > 0 || (timeLeft.d === 0 && timeLeft.hr === 0 && timeLeft.mins === 0)) {
      timeArray.push(<span key="secs">{timeLeft.secs} {timeLeft.secs === 1 ? 'sec' : 'secs'} </span>);
    }

    setTimerComponents(timeArray);
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [timerComponents, setTimerComponents] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTimeLeft()
    }, 1000);

    return () => clearTimeout(timer);
  },[timeLeft,timerComponents]);


  

  return (
    <div>
      
      {timerComponents ? timerComponents : <span>Loading...</span>}
    </div>
  );
};

export default CountdownTimer;
